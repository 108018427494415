const state = {
    activeRouter: []
}

const mutations = {
    setActiveRouter (state, data) {
        state.activeRouter = data
    }
}

const actions = {

}

const gettres = {

}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    gettres
}
