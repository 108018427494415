<template>
    <div class="page-container">
        <div class="logo-box">
          <img src="../../../../assets/image/kxb_log.png" class="logo" alt="">
          <span v-if="showTitle" class="name">安当家管理后台</span>
        </div>
        <el-menu
            :collapse="isOpen"
            router
            mode="vertical"
            :collapse-transition="false"
            :unique-opened="true"
        >
            <el-menu-item @click="removeRouteHandle" index="/home">
                <i class="el-icon-s-home"></i>
                <span slot="title">首页</span>
            </el-menu-item>
            <el-submenu
                :index="item.id + ''"
                v-for="(item, index) in menuList"
                :key="index"
            >
                <template slot="title">
                    <i :class="item.icon" class="fa subitem-left-icon"></i>
                    <span slot="name">{{ item.title }}</span>
                </template>
                <el-menu-item-group
                    v-for="(subItem, index) in item.children"
                    :key="index"
                >
                    <el-menu-item
                        :index="'/' + item.path + subItem.path"
                        @click="getRouteInfoHandle(item, subItem)"
                        >{{ subItem.title }}</el-menu-item
                    >
                </el-menu-item-group>
            </el-submenu>
        </el-menu>
    </div>
</template>

<script>
import { getMenu } from '@/api/login'
import { mapState, mapMutations } from 'vuex'

export default {
    data () {
        return {
            menuList: [],
            showTitle: true
        }
    },
    computed: {
        ...mapState('header', {
            isOpen: state => state.isOpen
        })
    },
    watch: {
      isOpen (data) {
        if (!data) {
          setTimeout(() => {
            this.showTitle = true
        }, 200)
        } else {
          this.showTitle = false
        }
      }
    },
    created () {
        getMenu().then(res => {
            this.menuList = res.data
            console.log(res.data)
        })
    },
    methods: {
        ...mapMutations('activeRouter', ['setActiveRouter']),
        /* 获取当前路由信息 */
        getRouteInfoHandle (parentRouteInfo, childrenRouteInfo) {
            const activeRouter = [
                {
                    name: parentRouteInfo.title,
                    path: `/${parentRouteInfo.path}/${parentRouteInfo.children[0].path}`
                },
                {
                    name: childrenRouteInfo.title
                }
            ]
            this.setActiveRouter(activeRouter)
        },
        /* 清空当前路由信息 */
        removeRouteHandle () {
            this.setActiveRouter([])
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container {
  .logo-box {
    color: $color-info-light;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 56px;
    cursor: pointer;
    .name {
      font-size: 20px;
      font-weight: bold;
      padding-left: 10px;
      color: black;
    }
    .logo {
      width: 25px;
      height: 25px;
      vertical-align: middle;
      border-radius: 2px;
    }
  }
  .el-menu {
    .el-menu-item-group {
        /deep/ .el-menu-item-group__title {
            padding: 0;
        }
    }
    .subitem-left-icon {
        margin-right: 5px;
        width: 24px;
        font-size: 18px;
        text-align: center;
        vertical-align: middle;
    }
}
}
</style>
