<template>
  <div class="sendKeyDialog-coantainer">
    <base-dialog
      :model="editData.isOpen"
      title="发送钥匙"
      @close="handleClose"
    >
      <template #body>
         <el-form ref="lockForm" :model="formData" label-width="100px">
          <el-form-item label="用户手机号" prop="phone">
            <el-input v-model="formData.phone" placeholder="输入绑定的用户手机"></el-input>
          </el-form-item>
          <el-form-item label="钥匙到期时间" prop="expirationDate">
            <el-date-picker
              v-model="formData.expirationDate"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="allocationOrganization">确 定</el-button>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import { sendKeyLocksForOneUser } from '@/api/lock'

export default {
  props: {
    editData: {
      type: Object
    }
  },
  data () {
    return {
      formData: {
        lockIdList: [],
        phone: '',
        expirationDate: ''
      }
    }
  },
  watch: {
    'editData.isOpen': function (isOpen) {
      if (isOpen) {
         console.log('id:', this.editData.lockIdList)
        this.formData.lockIdList = this.editData.lockIdList
      }
    }
  },
  methods: {
    /* 成功分发锁匙 */
    allocationOrganization () {
      console.log('时间', this.formData)
  //     const myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
  // // 校验个人电话
  //     if (!myreg.test(this.formData.phone)) {
  //       return this.$message({
  //               message: '用户手机错误',
  //               type: 'error'
  //               })
  //     }
      this.$confirm('是否将锁分配给该用户 ?', '操作提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs.lockForm.validate((valid) => {
            console.log('valid', valid)
            if (valid) {
              console.log('data:', this.formData)
              sendKeyLocksForOneUser(this.formData).then(() => {
                  this.$message({
                  message: '分配成功',
                  type: 'success'
                  })
                  this.$emit('successHandle')
                  this.handleClose()
                })
              }
          })
        })
        .catch(() => {
          this.$message({
            type: 'warning',
            message: '已取消'
          })
        })
    },
    /* 关闭弹窗 */
    handleClose () {
      this.formData = {
        lockIdList: [],
        phone: '',
        expirationDate: ''
      }
      this.editData.isOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
