<template>
  <div class="setMealDialog-coantainer">
    <base-dialog
      :model="mealData.isOpen"
      title="设置套餐"
      @close="handleClose"
    >
      <template #body>
         <el-form ref="form" :model="formData" :rules="rules" label-width="150px">
          <el-form-item label="设备号:" prop="deviceNo">
            <el-input disabled v-model.trim="formData.deviceNo"></el-input>
          </el-form-item>

          <el-form-item label="套餐设置" prop="quota">
            <el-select v-model="formData.quota" placeholder="请选择">
              <el-option
                v-for="item in mealList"
                :key="item.id"
                :label="item.packageName"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" >确 定</el-button>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import { simBasePackagePageRoot } from '@/api/wifi'
export default {
  props: {
    mealData: {
      type: Object
    }
  },
  data () {
    return {
      mealList: [],
      formData: {
        deviceNo: '',
        quota: ''
      },
      rules: {}
    }
  },
  watch: {
    'mealData.isOpen': function () {
      this.$nextTick(() => {
        console.log('套餐设置：', this.mealData)
        if (this.mealData.deviceNo) {
          this.formData.deviceNo = this.mealData.deviceNo
        } else {
          this.$refs.form.resetFields()
        }
        this.simBasePackagePageRootFn()
      })
    }
  },
  methods: {
    // 获取流量套餐
    simBasePackagePageRootFn () {
      const data = {
          keyword: '',
          pageNum: 1,
          pageSize: 99
        }
      simBasePackagePageRoot(data).then(res => {
        this.mealList = res.data.list
      })
    },
    // 确定
    submitFn () {
        this.saveResetDisconnectFn()
    },
    /* 关闭弹窗 */
    handleClose () {
      this.mealData.isOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
