import axios from 'axios'
import { Loading } from 'element-ui'

let loading

function startLoading () {
  loading = Loading.service({
    lock: true,
    text: '正在加载',
    background: 'rgba(0, 0, 0, 0)'
  })
}

function endLoading () {
  loading.close()
}

/* 请求导出excel */
const exportExcel = (host, path, method, params, filename) => {
    startLoading()
    return new Promise((resolve, reject) => {
        axios({
            url: `${host}${path}`,
            method: method,
            responseType: 'blob',
            data: params,
            headers: {
              'Content-Type': 'application/json;charset=UTF-8',
              Authorization: window.localStorage.getItem('token')
            }
          }).then((res) => {
            endLoading()
            const blob = new Blob([res.data], {
              type: 'application/vnd.ms-excel;charset=utf-8'
            })
            const url = URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.style.display = 'none'
            a.href = url
            a.download = `${filename}.xlsx`
            document.body.appendChild(a)
            a.click()
            resolve(res)
          }).catch(err => {
              endLoading()
              reject(err)
          })
    })
}

/* 请求导入excel */
const importExcel = (host, path, method, params) => {
    startLoading()
    return new Promise((resolve, reject) => {
        axios({
            url: `${host}${path}`,
            method: method,
            data: params,
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: window.localStorage.getItem('token')
            }
          }).then((res) => {
            endLoading()
            resolve(res)
          }).catch(err => {
            endLoading()
            reject(err)
          })
    })
}

export {
    exportExcel,
    importExcel
}
