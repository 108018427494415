<template>
  <div class="page-coantainer">
    <base-dialog :model="editData.isOpen" :title="formData.title" @close="handleClose">
      <template #body>
         <el-form ref="form" :model="formData" :rules="rules" label-width="100px">
          <el-form-item label="菜单名" prop="title">
            <el-input v-model.trim="formData.title"></el-input>
          </el-form-item>
          <el-form-item label="菜单类型" prop="menuType">
            <el-select v-model="formData.menuType" placeholder="请选择菜单类型" >
                <span v-for="(item, index) in menuType" :key="index">
                    <el-option :label="item" :value="index + 1" :key="index"></el-option>
                </span>
            </el-select>
          </el-form-item>
          <el-form-item label="前端路径" v-if="formData.menuType === 1" prop="path">
            <el-input v-model.trim="formData.path"></el-input>
          </el-form-item>
          <el-form-item label="权限标识" v-if="formData.menuType === 2" prop="para">
            <el-input v-model.trim="formData.para"></el-input>
          </el-form-item>
          <el-form-item label="前端图标">
            <el-input v-model.trim="formData.icon"></el-input>
          </el-form-item>
          <el-form-item label="排序">
            <el-input type="number" v-model.trim="formData.sort"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="addMenu">确 定</el-button>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import { addMenu, getMenuInfoById } from '@/api/system'

export default {
  props: {
    editData: {
      type: Object
    }
  },
  data () {
    return {
      menuType: ['菜单', '权限'],
      formData: {
        id: '',
        title: '',
        path: '',
        parentId: '',
        sort: '',
        icon: '',
        menuType: '',
        para: ''
      },
      pathOrPer: false,
      rules: {
        title: [
          { required: true, message: '请输入菜单名', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        ],
        path: [
          { required: true, message: '请输入前端路径', trigger: 'blur' }
        ],
        para: [
          { required: true, message: '请输入权限路径', trigger: 'blur' }
        ],
        menuType: [
          { required: true, message: '请选择菜单类型', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    'editData.isOpen': function (isOpen) {
      if (isOpen) {
        this.getMenuInfoById()
      }
    }
  },
  methods: {
    // 根据id获取菜单信息
    getMenuInfoById () {
      getMenuInfoById(this.editData.id).then(res => {
        Object.keys(this.formData).forEach((item) => {
          this.formData[item] = res.data[item]
        })
      })
    },
    /* 添加菜单 */
    addMenu () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formData.menuType === 1) {
            this.formData.para = ''
          }
          if (this.formData.menuType === 2) {
            this.formData.path = ''
          }
          addMenu(this.formData).then(res => {
            this.$refs.form.resetFields()
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            this.$emit('successHandle')
            this.handleClose()
          })
        }
      })
    },
    /* 选择菜单类型 */
    changeHandlder (val) {
      if (val === 1) {
        this.pathOrPer = false
      } else {
        this.pathOrPer = true
      }
    },
    /* 关闭弹窗 */
    handleClose () {
      this.editData.isOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
