<template>
  <div class="adOrEdHandBook-dialog">
    <el-dialog :modal="false" :visible="showImg" @close="showImg = false">
      <img width="100%" height="620px" :src="dialogImageUrl" alt="">
    </el-dialog>
    <base-dialog
      width="70%"
      :model="setData.isOpen"
      @close="closeDialogHandle"
      :title="setData.id ? '编辑操作手册' : '添加操作手册'"
    >
      <template #body>
        <div style="padding: 20px;">
          <el-form :model="formData" :rules="rules" ref="form" label-width="120px">
            <el-form-item label="标题" prop="title">
              <el-input v-model.trim="formData.title"></el-input>
            </el-form-item>
            <el-form-item label="内容">
              <el-input  :rows="2" type="textarea" v-model="formData.content"></el-input>
            </el-form-item>
            <el-form-item label="图片">
              <el-upload
                :action="action"
                :headers="headers"
                :before-upload="beforeVideoUpload"
                :on-preview="handlePictureCardPreview"
                :on-success="successUpFn"
                :show-file-list="false"
                :file-list="fileList"
                >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </el-form-item>
            <el-form-item >
              <div>
                <div v-for="(i,index) in formData.picUrlList" :key="index" class="upImg mg-sm" >
                  <img :src="$fileUrl+i" alt=""  @click="openBigImg(i)">
                  <i class="el-icon-delete floatDeleImg" @click="delteImgList(index)"></i>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="备注">
              <el-input v-model.trim="formData.remarks"></el-input>
            </el-form-item>
          </el-form>
        </div>
      </template>
      <template #footer>
        <el-button @click="setData.isOpen = false">取 消</el-button>
        <el-button  type="primary" :loading="saveLoding" @click="saveHandBookFn()">确 定</el-button>
      </template>
    </base-dialog>

    <el-dialog
        title="预览"
        :visible.sync="uploadVersion.isOpen"
        width="40%">
          <img :src="$fileUrl+uploadVersion.url" alt=""  class="popImg">
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="uploadVersion.isOpen = false">确 定</el-button>
        </span>
      </el-dialog>
  </div>
</template>

<script>
import { operatingManualSave, operatingManualByid } from '@/api/appClass'
// import vueTinymce from '@/components/common/components/vueTinymce'
export default {
  props: {
    setData: {
      type: Object
    }
  },
  // components: {
  //   vueTinymce
  // },
  data () {
    return {
      saveLoding: false,
      uploadVersion: {
        isOpen: false,
        url: ''
      },
      fileList: [],
      showImg: false,
      headers: {
        Authorization: window.localStorage.getItem('token')
      },
      action: `${process.env.VUE_APP_API_URL}/sys/minio/upload/image`,
      title: '上传图片',
      dialogImageUrl: '',
      formData: {
        id: '',
        title: '',
        content: '',
        picUrlList: []
      },
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ]
      },
      queryInfo: {
        roleType: '',
        pageNum: 1,
        pageSize: 1000,
        keyword: ''
      }
    }
  },
  watch: {
    'setData.isOpen': function (isOpen) {
      if (isOpen) {
        if (!this.setData.id) {
          this.resetData()
        } else {
          this.getHandBookById()
        }
      }
    }
  },
  methods: {
    // 删除图片
    delteImgList (index) {
      this.$confirm('是否删除该图片', '操作提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.formData.picUrlList.splice(index, 1)
        })
        .catch(() => {
          this.$message({
            type: 'warning',
            message: '已取消'
          })
        })
    },
    // 大窗
    openBigImg (url) {
      this.uploadVersion.url = url
      this.uploadVersion.isOpen = true
    },
    // 根据id 获取操作手册数据
    getHandBookById () {
      operatingManualByid({ id: this.setData.id }).then(res => {
        Object.keys(this.formData).forEach((item) => {
            this.formData[item] = res.data[item]
          })
          this.fileList = this.formData.picUrlList
          this.formData.id = this.setData.id
      })
    },
    // 上传成功的钩子
    successUpFn (response, file, fileList) {
      console.log('成功了---', file)
      // const arr = []
      // fileList.forEach((item) => {
      //   arr.push(item.response.data)
      // })
      this.formData.picUrlList.push(file.response.data)
    },
    // 保存操作手册
    saveHandBookFn () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saveLoding = true
          const data = JSON.parse(JSON.stringify(this.formData))
          console.log('---data', data)
          // this.fileList.forEach((item) => {
          //   data.picUrlList.push(item.response.data)
          // })
          operatingManualSave(data).then(res => {
            this.saveLoding = false
            this.setData.isOpen = false
            this.$emit('success')
          })
        }
      })
    },
      // handleRemove (file, fileList) {
      //   console.log('删除---', file, fileList)
      //   this.fileList = fileList
      // },
      handlePictureCardPreview (file) {
        this.showImg = true
        this.dialogImageUrl = file.url
        console.log(this.dialogImageUrl)
      },
      /* 上传控制 */
      beforeVideoUpload (file) {
        console.log(file)
        const isImage = file.type === 'image/png' || file.type === 'image/jpeg'
        const fileSize = file.size / 1024 / 1024 < 50
        if (!isImage) {
          this.$message.error('上传图片只能是只能是jpeg/png格式!')
        }
        if (!fileSize) {
          this.$message.error('上传图片不能超过 50MB!')
        }
        this.title = '上传中，请不要关闭窗口'
        return isImage && fileSize
      },
    // 关闭弹窗
    closeDialogHandle () {
      this.setData.isOpen = false
    },
    // 重设数据
    resetData () {
      for (const item in this.formData) {
      switch (item) {
        case 'id':
        this.formData[item] = ''
          break
        case 'picUrlList':
        this.formData[item] = []
        break
        default:
        this.formData[item] = ''
          break
      }
    }
    }
  }
}
</script>
<style scoped lang="scss">
.adOrEdHandBook-dialog {
}
</style>
