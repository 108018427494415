import request from '@/service/request'

/* 分页查询京东订单信息 */
const adJdOrderPage = (params) => {
  return request.post('/ad/adJdOrder/page', params)
}

// 分页查询提现审核申请
const withdrawalPage = (params) => {
  return request.post('/fund/withdrawal/page', params)
}

// 提现审核通过
const successProcess = (params) => {
  return request.post('/fund/withdrawal/process/' + params.id, params)
}

// 提现审核失败
const withdrawalReject = (params) => {
  return request.post('/fund/withdrawal/reject', params)
}

export {
  adJdOrderPage,
  withdrawalPage,
  successProcess,
  withdrawalReject
}
