import request from '@/service/request'

// 获取所有wifi设备列表
const getAllWifiList = (params) => {
  return request.post('/wifi/device/pageRoot', params)
}

// 获取设备销售列表
const deviceStoragePageRoot = (params) => {
  return request.post('/wifi/deviceStorage/pageRoot', params)
}

// 根据设备号获取设备信息
const getByDeviceNo = (params) => {
  return request.post('/wifi/device/getByDeviceNo/' + params.deviceNo, params)
}

// 取消设备达量断网
const cancelDisconnectQuota = (params) => {
  return request.post('/wifi/simPackage/cancelDisconnectQuota/' + params.deviceNo, params)
}

// 修改断网阈值
const alterDisconnectQuota = (params) => {
  return request.post('/wifi/simPackage/alterDisconnectQuota', params)
}

// 平台出库给一级代理商
const checkoutBatchForFirst = (params) => {
  return request.post('/wifi/deviceStorage/checkoutBatchForFirst', params)
}

// 保存流量套餐
const simBasePackageSave = (params) => {
  return request.post('/wifi/simBasePackage/save', params)
}

// 获取流量套餐
const simBasePackagePageRoot = (params) => {
  return request.post('/wifi/simBasePackage/pageRoot', params)
}

// 根据id获取流量套餐
const simBasePackageGetById = (params) => {
  return request.post('/wifi/simBasePackage/getById/' + params.id, params)
}

export { getAllWifiList, deviceStoragePageRoot, cancelDisconnectQuota, alterDisconnectQuota, getByDeviceNo, checkoutBatchForFirst, simBasePackageSave, simBasePackagePageRoot, simBasePackageGetById }
