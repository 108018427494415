<template>
  <div class="page-container">
    <!-- header -->
    <el-card>
      <el-row class="search_line" type="flex" >
        <el-col :span="4" >
          <el-select v-model="queryInfo.status" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-col>
         <el-col :span="6">
            <el-input
              placeholder="请输入提现人真实姓名/订单号"
              class="input-with-select"
              clearable
              v-model="queryInfo.keyword"
              @clear="pageWithdrawalPageFn"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="pageWithdrawalPageFn"
              ></el-button>
            </el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" style="text-align: right">
        </el-col>
      </el-row>
    </el-card>
    <!-- body -->
    <el-card>
     <vxe-table
        resizable
        ref="xTable"
        align="center"
        :data="keyList"
        :loading="loading"
      >
        <vxe-column field="orderNo" title="订单号" width="220"></vxe-column>
        <vxe-column field="orderDesc" title="审核名称" width="120">
        </vxe-column>
        <vxe-column field="status" title="订单状态" width="120">
          <template v-slot="{ row }">
            <span v-if="row.status == 0"></span>
            <span v-if="row.status == 1">审核通过</span>
            <span v-if="row.status == 2">审核失败</span>
            <span v-if="row.status == 3">申请驳回</span>
            <span v-if="row.status == 4">关闭申请</span>
          </template>
        </vxe-column>
        <vxe-column field="realName" title="申请人" width="160"></vxe-column>
        <vxe-column field="phone" title="手机号" width="160"></vxe-column>
        <vxe-column field="amount" title="申请金额" width="180">
          <template v-slot="{ row }">
            <span class="extractClash">{{ row.amount }}</span>
          </template>
        </vxe-column>
        <vxe-column field="balance" title="账户余额" width="140"></vxe-column>
        <vxe-column field="createTime" title="发起申请时间"  width="220"></vxe-column>
        <vxe-column field="updateTime" title="最近操作时间"  width="220"></vxe-column>
         <vxe-column title="操作"  width="220">
          <template #default="{ row }">
                  <div style="line-height: 35px;">
                    <el-button
                    v-if="row.status === 0"
                      type="success"
                      size="mini"
                      @click="passWithdrawCash(row)"
                      >审核通过</el-button>
                      <el-button
                    v-if="row.status === 0"
                      type="info"
                      size="mini"
                      @click="rejectShowDialog(row)"
                      >审核驳回</el-button>
                    </div>
            </template>
         </vxe-column>
      </vxe-table>
    </el-card>
    <base-pagination
      :total="total"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></base-pagination>

    <base-dialog
     width="400px"
      :model="dialogCheckVisible"
      title="提示"
      @close="closereJectReason()"
    >
      <template #body>
         <el-form ref="reasonForm" label-width="80px">
          <el-form-item label="驳回理由" >
            <el-input v-model="onData.reason" placeholder="输入驳回理由"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <el-button @click="closereJectReason()">取 消</el-button>
        <el-button type="primary" @click="rejectWithdrawCash()">确 定</el-button>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import { withdrawalPage, successProcess, withdrawalReject } from '@/api/jd'
import { mapState } from 'vuex'
export default {
  computed: {
...mapState('login', ['userInfo'])
},
  data () {
    return {
      options: [
        { value: 0, label: '未处理' },
        { value: 1, label: '审核通过' },
        { value: 2, label: '提现失败' },
        { value: 3, label: '申请驳回' },
        { value: 4, label: '关闭申请' }
      ],
      dialogCheckVisible: false,
      onData: {
        id: '',
        reason: ''
      },
      startTime: '',
      endTime: '',
      queryInfo: {
        status: 0,
        pageNum: 1,
        pageSize: 10,
        keyword: ''
      },
      keyList: [],
      total: 0,
      loading: false
    }
  },
  created () {
    this.pageWithdrawalPageFn()
  },
  methods: {
    // 取消驳回
    closereJectReason () {
      this.onData.reason = ''
      this.dialogCheckVisible = false
    },
    // 提现驳回
    rejectWithdrawCash () {
      withdrawalReject(this.onData).then(() => {
        this.dialogCheckVisible = false
        this.onData.reason = ''
        this.onData.id = ''
        this.$message({
            type: 'success',
            message: '驳回成功'
          })
          this.pageWithdrawalPageFn()
      })
    },
    //  显示驳回弹窗
    rejectShowDialog (row) {
      this.onData.id = row.id
      this.dialogCheckVisible = true
    },
    // 提现通过
    passWithdrawCash (row) {
      this.$confirm('是否通过该提现审核 ?', '操作提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        successProcess({ id: row.id }).then(res => {
          this.pageWithdrawalPageFn()
          this.$message({
              type: 'success',
              message: '提现成功'
            })
            this.loading = false
        })
      }).catch(() => {})
    },
    // 获取提现审核列表
    pageWithdrawalPageFn (type) {
      if (type) {
        this.queryInfo.pageNum = 1
        this.queryInfo.pageSize = 10
      }
      this.loading = true
      const data = JSON.parse(JSON.stringify(this.queryInfo))
      withdrawalPage(data).then((res) => {
        this.loading = false
        this.keyList = res.data.list
        this.total = res.data.total
      })
    },
    // 改变分页条目
    handleSizeChange (size) {
      this.queryInfo.pageSize = size
      this.pageWithdrawalPageFn()
    },
    // 改变页数
    handleCurrentChange (page) {
      this.queryInfo.pageNum = page
      this.pageWithdrawalPageFn()
    }
  }
}
</script>

<style lang="scss" scoped>
.search_line {
    display: flex;
    align-items: center;
  }
.extractClash {
  font-size: 18px;
  font-weight: bold;
  color: #19be6b;
}
.row_render {
  padding: 20px 40px;
}
</style>
