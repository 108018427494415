<template>
  <div class="batchGoOutDialog-coantainer">
    <base-dialog
      :model="batchGoOutData.isOpen"
      title="批量出库"
      @close="handleClose"
    >
      <template #body>
         <el-form ref="form" :model="formData" :rules="rules" label-width="150px">
          <el-form-item label="收货人手机号码" prop="phone">
            <el-input  v-model.trim="formData.phone"></el-input>
          </el-form-item>

          <el-form-item label="设备列表" prop="quota">
            <el-tag
            style="margin: 5px;"
              v-for="(tag,index) in formData.tags"
              :key="index"
              closable
              @close="deleteOutfacility(tag)"
              >
              {{tag}}
            </el-tag>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" >确 定</el-button>
      </template>
    </base-dialog>
  </div>
</template>

<script>

export default {
  props: {
    batchGoOutData: {
      type: Object
    }
  },
  data () {
    return {
      codeWatch: '',
      formData: {
        phone: '',
        tags: []
      },
      rules: {}
    }
  },
  watch: {
    'batchGoOutData.isOpen': function () {
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    }
  },
  mounted () {
    this.listenerScanCodeFn()
  },
  beforeDestroy () {
    console.log('我已经被销毁')
    document.onkeypress = null
  },
  methods: {
    // 剔除设备
    deleteOutfacility (tag) {
      this.formData.tags.splice(this.formData.tags.indexOf(tag), 1)
    },
    // 监听扫码枪扫码的方法
    listenerScanCodeFn () {
      // 监听按键
    var code = ''
    var lastTime, nextTime // 上次时间、最新时间
    var lastCode, nextCode // 上次按键、最新按键
    document.onkeypress = (e) => {
      console.log('我被监听到啦！！')
      // 获取按键
      if (window.event) { // IE
        nextCode = e.keyCode
      } else if (e.which) { // Netscape/Firefox/Opera
        nextCode = e.which
      }
      // 如果触发了回车事件(扫码结束时间)
      if (nextCode === 13) {
        if (code.length < 3) return // 手动输入的时间不会让code的长度大于2，所以这里只会对扫码枪有用

        this.codeFind(code) // 获取到扫码枪输入的内容，做别的操作

        code = ''
        lastCode = ''
        lastTime = ''
        return
      }
      nextTime = new Date().getTime() // 记录最新时间
      if (!lastTime && !lastCode) { // 如果上次时间和上次按键为空
        code += e.key // 执行叠加操作
      }
      // 如果有上次时间及上次按键
      if (lastCode && lastTime && nextTime - lastTime > 30) { // 当扫码前有keypress事件时,防止首字缺失
        code = e.key
      } else if (lastCode && lastTime) {
        code += e.key
      }
      lastCode = nextCode
      lastTime = nextTime
    }
    },
    // 监听返回参数的处理
    codeFind (code) {
      if (this.formData.tags.indexOf(code) === -1) {
        this.formData.tags.push(code)
      }
    },
    // 确定
    submitFn () {
        this.saveResetDisconnectFn()
    },
    /* 修改设备断网阈值 */
    saveResetDisconnectFn () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // saveOrgaApartment(this.formData).then(res => {
          //   this.$message({
          //     message: '添加成功',
          //     type: 'success'
          //   })
          //   this.$emit('successHandle')
          //   this.handleClose()
          // })
        }
      })
    },
    /* 关闭弹窗 */
    handleClose () {
      this.batchGoOutData.isOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
