import request from '@/service/request'

// 获取版本列表
const getVersionList = (params) => {
  return request.post('/sys/sysVersion/page', params)
}

// 添加版本
const addVersion = (params) => {
  return request.post('/sys/sysVersion/save', params)
}

// 删除版本
const deleteVersion = (params) => {
  return request.post('/sys/sysVersion/del/' + params)
}

// 根据id获取版本信息
const getVersionById = (params) => {
  return request.post('/sys/sysVersion/getById/' + params)
}

export {
  getVersionList,
  addVersion,
  deleteVersion,
  getVersionById
}
