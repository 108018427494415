<template>
  <el-container class="home-container">
    <el-aside :width="isOpen ? '64px' : '200px'">
      <menus></menus>
    </el-aside>
    <el-main>
      <right-main></right-main>
    </el-main>
  </el-container>
</template>

<script>
import menus from './layout/left/index.vue'
import rightMain from './layout/right/index.vue'
import { mapState } from 'vuex'

export default {
  components: {
    menus,
    rightMain
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapState('header', {
      isOpen: state => state.isOpen
    })
  }
}
</script>

<style lang="scss" scoped>
.home-container {
  height: 100vh;
  .el-aside {
    background-color:#ffffff;
    transition: 0.3s ease-out;
    border-right: 1px solid #8181817d;
    /deep/ .el-menu {
      border: none;
    }
  }
  .el-main {
    background-color: $page-background-color;
    padding: 0;
  }
}
</style>
