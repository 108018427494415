<template>
  <div style="height:100%;" id="main">
  </div>
</template>

<script>
  import * as echarts from 'echarts'
  export default {
    name: 'echarts',
    data () {
      return {}
    },
    props: {
      xData: {
        type: Array
      },
      yData: {
        type: Array
      }
  },
    mounted () {
      this.echartsInit()
    },
    methods: {
      echartsInit () {
        // 使用时只需要把setOption里的对象换成echarts中的options或者自己的参数即可
        echarts.init(document.getElementById('main')).setOption({
          tooltip: {
            trigger: 'axis'
          },
          title: {
            textStyle: {
              color: '#000000'
            }
          },
          xAxis: {
            // type: 'category',
            data: this.xData,
            splitLine: {
              lineStyle: {
                color: '#000000'
              }
            },
            axisLabel: {
              color: '#000000'
            }
          },
          yAxis: {
              type: 'value',
              // axisLabel: {
              //   color: '#000000'
              // },
              splitLine: {
                lineStyle: {
                  color: '#000000'
                }
              },
              axisLine: {
                lineStyle: {
                  color: '#000000'
                }
              },
              nameTextStyle: {
                color: ['#000000']
              }
            },
            // {
            //   type: 'value',
            //   splitLine: {
            //     lineStyle: {
            //       color: '#000000'
            //     }
            //   }
            // }

          series: [
            {
            data: this.yData,
            type: 'bar',
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#83bff6' },
                { offset: 0.5, color: '#188df0' },
                { offset: 1, color: '#188df0' }
              ])
            },
            emphasis: {
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#2378f7' },
                  { offset: 0.7, color: '#2378f7' },
                  { offset: 1, color: '#83bff6' }
                ])
              }
            }
          },
          {
            type: 'line',
            yAxisIndex: 0,
            data: this.yData
          }
        ]
        })
      }
    }
  }
</script>
