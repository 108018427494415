<template>
  <div class="updateAppVersion-dialog">
    <base-dialog
      :model="addData.isOpen"
      @open="open"
      @close="closeDialogHandle"
      :title="addData.title"
    >
      <template #body>
        <el-form
          ref="form"
          :model="formData"
          :rules="rules"
          label-width="150px"
        >
          <el-form-item label="应用名称" prop="appName">
            <el-input v-model.trim="formData.packageName"></el-input>
          </el-form-item>
          <el-form-item label="应用手机系统类型" prop="appName">
            <el-radio-group v-model="formData.version_type" class="ml-4">
              <el-radio :label="item.id" size="large" v-for="(item, index) in appType" :key="index">{{item.name}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="应用版本" prop="version">
            <el-input v-model.trim="formData.version"></el-input>
          </el-form-item>
          <el-form-item label="版本号" prop="edition_number">
            <el-input type="Number" v-model.trim="formData.edition_number"></el-input>
          </el-form-item>
          <el-form-item label="安装包类型" prop="package_type">
            <el-radio-group v-model="formData.package_type" class="ml-4">
              <el-radio :label="item.id" size="large" v-for="(item, index) in packageTypes" :key="index">{{item.name}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="安装包">
            <el-button type="primary" @click="uploadVersion.isOpen = true">
              上传<i class="el-icon-upload el-icon--right"></i>
            </el-button>
          </el-form-item>
          <el-form-item label="下载链接" prop="url">
            <el-input v-model.trim="formData.url"></el-input>
          </el-form-item>
          <el-form-item label="静默更新" prop="edition_silence">
            <el-radio-group v-model="formData.edition_silence" class="ml-4">
              <el-radio :label="item.id" size="large" v-for="(item, index) in becomeUp" :key="index">{{item.name}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="更新日志" prop="describe">
            <el-input type="textarea"  :rows="2" v-model.trim="formData.describe"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remarks">
            <el-input v-model.trim="formData.remarks"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <el-button @click="addData.isOpen = false">取 消</el-button>
        <el-button type="primary" @click="addAppVersionHandle">确 定</el-button>
      </template>
    </base-dialog>
    <base-upload-file
      :uploadData="uploadVersion"
      @success="uploadSuccessVersion"
      @close="closeUpload"
    ></base-upload-file>
  </div>
</template>

<script>
import { addVersion, getVersionById } from '@/api/version'

export default {
  props: {
    addData: {
      type: Object
    }
  },
  data () {
    return {
      formData: {
        appName: '',
        describe: '',
        remarks: '',
        edition_silence: 0,
        package_type: 0,
        status: 0,
        version_type: 0,
        updateTime: '',
        url: '',
        version: ''
      },
      packageTypes: [
        {
          id: 0,
          name: '整包更新(apk)'
        },
        {
          id: 1,
          name: 'wgt热更新'
        }
      ],
      becomeUp: [
        {
          id: 0,
          name: '否'
        },
        {
          id: 1,
          name: '是'
        }
      ],
      appType: [
        {
          id: 0,
          name: 'Android'
        },
        {
          id: 1,
          name: 'IOS'
        }
      ],
      uploadVersion: {
        isOpen: false,
        title: '上传应用版本',
        type: ['application/vnd.android.package-archive', ''],
        size: 300,
        action: '/sys/sysVersion/upload/installer'
      },
      rules: {
        appName: [
          {
            required: true,
            message: '请输入应用名称',
            trigger: 'blur'
          }
        ],
        version: [
          {
            required: true,
            message: '请输入版本信息',
            trigger: 'blur'
          }
        ],
        edition_number: [
        {
            required: true,
            message: '请输入版本号(如:v1.0.1 = 101)',
            trigger: 'blur'
          }
        ],
        describe: [{
          required: true,
          message: '请输入更新日志',
          trigger: 'blur'
        }],
        url: [{
          required: true,
          message: '请输入下载链接',
          trigger: 'blur'
        }]
      }
    }
  },
  watch: {
    'addData.isOpen': function (isOpen) {
      if (isOpen) {
      }
      if (isOpen && this.addData.type === 'edit') {
      }
      if (!isOpen) {
        this.resetFormData()
      }
    }
  },
  methods: {
    // 根据id获取版本信息
    open () {
      if (this.addData.id !== '') {
        getVersionById(this.addData.id).then((res) => {
          Object.keys(this.formData).forEach((item) => {
            this.formData[item] = res.data[item]
          })
          this.formData.id = this.addData.id
        })
      }
    },
    // 上传成功
    uploadSuccessVersion (data) {
      console.log(data)
      this.formData.url = data.data
      this.uploadVersion.isOpen = false
    },
    // 添加应用版本
    addAppVersionHandle () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          addVersion(this.formData).then((res) => {
            this.$refs.form.resetFields()
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            this.$emit('successHandle')
            this.closeDialogHandle()
          })
        }
      })
    },
    // 重置表单
    resetFormData () {
      this.formData = {
          packageName: '',
          describe: '',
          edition_url: 'https://file-static.cx-online.net/package/c27610a234e9445783f601bca00dd50a.wgt',
          edition_force: 0, // 是否强制更新 0代表否 1代表是
          package_type: 1, // 0是整包升级（apk或者appstore或者安卓应用市场） 1是wgt升级
          edition_issue: 1, // 是否发行  0否 1是 为了控制上架应用市场审核时不能弹出热更新框
          edition_number: '', // 版本号 最重要的manifest里的版本号 （检查更新主要以服务器返回的edition_number版本号是否大于当前app的版本号来实现是否更新）
          edition_name: '1.0.2', // 版本名称 manifest里的版本名称
          edition_silence: 0 // 是否静默更新 0代表否 1代表是
      }
    },
    // 关闭上传窗口
    closeUpload () {
      this.uploadVersion.isOpen = false
    },
    // 关闭弹窗
    closeDialogHandle () {
      this.addData.isOpen = false
    }
  }
}
</script>
<style lang="scss" scoped>
.updateAppVersion-dialog {
  /deep/ .el-dialog {
      margin-top: 10vh !important;
    }
}

</style>
