import request from '@/service/request'

/*
  组织接口
*/
/* 查询全部组织 */
const getOrgaInfoListAll = () => {
  return request.post('/orga/orgaInfo/listAll')
}

/* 根据id获取组织信息 */
const getOrgaInfoById = (id) => {
  return request.post(`/orga/orgaInfo/get/${id}`)
}

/* 分页查询(平台管理员) */
const getOrgaInfoPage = (params) => {
  return request.post('/orga/orgaInfo/pageRoot', params)
}

/* 保存组织 */
const saveOrgaInfo = (params) => {
  return request.post('/orga/orgaInfo/save', params)
}

// 添加/修改组织管理员
const saveOrgaManager = (params) => {
  return request.post('/orga/orgaManager/save', params)
}

// 删除管理员
const deleteUserById = (id) => {
  return request.post(`/orgaManager/del/${id}`)
}

// 保存管理员与楼栋的关联
const saveBuildUser = (params) => {
  return request.post('/orga/orgaApartmentUser/saveBuildUser', params)
}

// 根据id查询管理员信息
const getManagerUserId = (id) => {
  return request.post(`/orga/orgaManager/getManagerUser/${id}`)
}

// 分页查询管理员列表(组织管理员使用)
const orgaManagerPageList = (params) => {
  return request.post('/orga/orgaManager/page', params)
}

// 删除管理员
const deleteOrgaManager = (id) => {
  return request.post(`/orga/orgaManager/del/${id}`)
}

// 分页查询审核申请
const orgaApplyPageList = (params) => {
  return request.post('/orga/orgaApply/page', params)
}

// 保存审核状态
const saveApplyStatus = (params) => {
  return request.post('/orga/orgaApply/saveApplyStatus', params)
}

// 删除组织
const orgaInfoDel = (id) => {
  return request.post(`/orga/orgaInfo/del/${id}`)
}

/*
  楼栋接口
*/
/* 楼栋房间树 */
const getOrgaBuildListTree = () => {
  return request.post('/orga/orgaBuild/listTree')
}

// 根据组织id获取楼栋
const getAllOrgaBuildByOrgaId = (id) => {
  return request.post(`/orga/orgaBuild/getAllOrgaBuildByOrgaId/${id}`)
}

/* 根据id获取楼栋信息 */
const getOrgaBuildById = (id) => {
  return request.post(`/orga/orgaBuild/get/${id}`)
}

/* 分页查询 */
const getOrgaBuildPage = (params) => {
  return request.post('/orga/orgaBuild/page', params)
}

/* 保存楼栋 */
const saveOrgaBuild = (params) => {
  return request.post('/orga/orgaBuild/save', params)
}

/* 删除楼栋 */
const delOrgaBuild = (id) => {
  return request.post(`/orga/orgaBuild/del/${id}`)
}

/*
  房间接口
*/
/* 分页查询 */
const getOrgaApartmentPage = (params) => {
  return request.post('/orga/orgaApartment/page', params)
}

/* 根据id获取房间信息 */
const getOrgaApartmentById = (id) => {
  return request.post(`/orga/orgaApartment/get/${id}`)
}

/* 保存房间 */
const saveOrgaApartment = (params) => {
  return request.post('/orga/orgaApartment/save', params)
}

/* 删除房间 */
const delOrgaApartment = (id) => {
  return request.post(`/orga/orgaApartment/delete/${id}`)
}

export {
  /* 组织 */
  getOrgaInfoListAll,
  getOrgaInfoPage,
  saveOrgaInfo,
  saveOrgaManager,
  deleteUserById,
  saveBuildUser,
  getManagerUserId,
  orgaManagerPageList,
  deleteOrgaManager,
  orgaApplyPageList,
  saveApplyStatus,
  orgaInfoDel,
  getOrgaInfoById,
  /* 楼栋 */
  getOrgaBuildListTree,
  getAllOrgaBuildByOrgaId,
  getOrgaBuildPage,
  saveOrgaBuild,
  delOrgaBuild,
  getOrgaBuildById,
  /* 房间 */
  getOrgaApartmentPage,
  saveOrgaApartment,
  delOrgaApartment,
  getOrgaApartmentById
}
