import request from '@/service/request'

// 重置aalock锁
const resetLock = (params) => {
  return request.post('/lock/aalock/resetLock/' + params.aalockId, params)
}

// 获取动态临时密码
const trendPassUnlockOrga = (params) => {
  return request.post('/rent/aalock/trendPassUnlockOrga', params)
}

export {
  resetLock,
  trendPassUnlockOrga
}
