import request from '@/service/request'

/**
 * 用户接口
 */
// 获取平台、管理员用户列表
const getUserList = (params) => {
  return request.post('/sys/user/page', params)
}

// 添加用户
const addUser = (params) => {
  return request.post('/sys/user/save', params)
}

// 添加/编辑组织总管
const saveOrgaMain = (params) => {
  return request.post('/sys/user/saveOrgaMain', params)
}

// 根据id获取用户信息
const getUserInfoById = (params) => {
  return request.post('/sys/user/getById/' + params)
}

// 根据id删除用户
const deleteUserById = (params) => {
  return request.post('/sys/user/del/' + params)
}

// 根据id获取角色列表
const getRoleListById = (params) => {
  return request.post('/sys/user/listRoleIdByUserId/' + params)
}

// 分配用户角色
const setUserRole = (params) => {
  return request.post('/sys/user/saveUserRole', params)
}

// 获取所有用户
const getPageConsumer = (params) => {
  return request.post('/sys/user/pageConsumer', params)
}

/**
 * 角色接口
 */
// 获取角色列表
const getRoleList = (params) => {
  return request.post('/sys/role/page', params)
}

// 添加角色
const addRole = (params) => {
  return request.post('/sys/role/save', params)
}

// 根据id获取角色信息
const getRoleInfoById = (params) => {
  return request.post('/sys/role/getById/' + params)
}

// 获取角色树结构
const getListRoleTree = (params) => {
  return request.post('/sys/role/listRoleTree', params)
}

// 根据id删除角色
const deleteRoleById = (params) => {
  return request.post('/sys/role/del/' + params)
}

// 根据id获取角色菜单
const getRoleMenuById = (params) => {
  return request.post('/sys/role/listMenuIdByRoleId/' + params)
}

// 获取个人用户角色类型列表
const getAllPersonalRoleType = () => {
  return request.post('/sys/role/getAllPersonalRoleType')
}

// 分配角色菜单
const setRoleMenu = (params) => {
  return request.post('/sys/role/saveRoleMenu', params)
}

// 获取角色类型
const getAllManagerRoleType = (params) => {
  return request.post('/sys/role/getAllManagerRoleType', params)
}

/**
 * 菜单接口
 */
// 获取菜单列表
const getMenuList = (params) => {
  return request.post('/sys/menu/listTree', params)
}

// 添加菜单
const addMenu = (params) => {
  return request.post('/sys/menu/save', params)
}

// 根据id获取菜单信息
const getMenuInfoById = (params) => {
  return request.post('/sys/menu/getById/' + params)
}

// 根据id删除菜单
const deleteMenuById = (params) => {
  return request.post('/sys/menu/del/' + params)
}

export {
  getUserList,
  addUser,
  saveOrgaMain,
  getUserInfoById,
  deleteUserById,
  setUserRole,
  getRoleList,
  getRoleListById,
  getAllPersonalRoleType,
  getListRoleTree,
  getAllManagerRoleType,
  addRole,
  getRoleInfoById,
  deleteRoleById,
  getRoleMenuById,
  setRoleMenu,
  getMenuList,
  addMenu,
  getMenuInfoById,
  deleteMenuById,
  getPageConsumer
}
