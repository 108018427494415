<template>
  <div class="resetDisconnect-coantainer">
    <base-dialog
      :model="setQuotaData.isOpen"
      title="修改断网阈值"
      @close="handleClose"
    >
      <template #body>
         <el-form ref="form" :model="formData" :rules="rules" label-width="100px">
          <el-form-item label="设备号" prop="deviceNo">
            <el-input disabled  v-model.trim="formData.deviceNo"></el-input>
          </el-form-item>

          <el-form-item label="断网阈值" prop="quota">
            <el-input type="number" v-model.trim="formData.quota"></el-input>
          </el-form-item>

        </el-form>
      </template>
      <template #footer>
        <el-button @click="handleClose()">取 消</el-button>
        <el-button type="primary" @click="setDisconnectFn()">确 定</el-button>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import { alterDisconnectQuota } from '@/api/wifi'
export default {
  props: {
    setQuotaData: {
      type: Object
    }
  },
  data () {
    return {
      formData: {
        deviceNo: '',
        quota: ''
      },
      rules: {}
    }
  },
  watch: {
    'setQuotaData.isOpen': function () {
      this.$nextTick(() => {
        this.$refs.form.resetFields()
        this.formData.deviceNo = this.setQuotaData.deviceNo
      })
    }
  },
  methods: {
    // 确定设置断网阈值
    setDisconnectFn () {
      this.$confirm('是否确定设置该阈值?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          alterDisconnectQuota(this.formData).then(res => {
              this.$message({
                  type: 'success',
                  message: '设置成功'
                })
                this.setQuotaData.isOpen = false
                this.$emit('successHandle')
            })
        })
    },
    /* 关闭弹窗 */
    handleClose () {
      this.setQuotaData.isOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
