<template>
  <div class="page-container">
    <!-- header -->
    <el-card>
      <el-row>
        <el-col :span="6">
          <el-input
            placeholder="请输入关键字搜索"
            class="input-with-select"
            clearable
            v-model="queryInfo.keyword"
            @clear="getRoleList"
          >
            <el-button slot="append" icon="el-icon-search" @click="getRoleList"></el-button>
          </el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" style="text-align: right;">
          <el-button type="primary" @click="createRole">添加角色</el-button>
        </el-col>
      </el-row>
    </el-card>
    <!-- body -->
    <el-card>
      <el-table :data="roleList" style="width: 100%" border stripe>
          <el-table-column type="index" label="ID" width="50">
          </el-table-column>
          <el-table-column prop="roleName" label="角色" width="300"> </el-table-column>
          <el-table-column prop="remarks" label="备注"> </el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="200"> </el-table-column>
          <el-table-column label="操作" width="240" fixed="right">
            <template v-slot="scoped">
              <el-button type="primary" size="mini" @click="openSetRoleMenu(scoped.row.id)">分配菜单</el-button>
              <el-button type="warning" size="mini" @click="createRole(scoped.row.id)">编辑</el-button>
              <el-button type="danger" size="mini" @click="deleteUserHandle(scoped.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
    </el-card>
    <base-pagination :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" :currentPage="queryInfo.pageNum"></base-pagination>
    <add-role :addData="addData" @successHandle="successHandle"></add-role>
    <set-role-menu :setData="setData" @successHandle="successHandle"></set-role-menu>
  </div>
</template>

<script>
import { getRoleList, deleteRoleById } from '@/api/system'
import addRole from './dialog/addRole.vue'
import setRoleMenu from './dialog/setRoleMenu.vue'

export default {
  components: {
    addRole,
    setRoleMenu
  },
  data () {
    return {
      addData: {
        isOpen: false
      },
      setData: {
        isOpen: false,
        id: ''
      },
      queryInfo: {
        pageNum: 1,
        pageSize: 10,
        keyword: ''
      },
      roleList: [],
      total: 0
    }
  },
  created () {
    this.getRoleList()
  },
  methods: {
    // 获取角色列表
    getRoleList () {
      getRoleList(this.queryInfo).then(res => {
        this.roleList = res.data.list
        this.total = res.data.total
      })
    },
    // 添加或编辑角色
    createRole (id) {
      this.addData.isOpen = true
      if (id.length) {
        this.addData.id = id
        this.addData.title = '编辑角色'
      } else {
        this.addData.id = ''
        this.addData.title = '添加角色'
      }
    },
    // 打开分配角色菜单
    openSetRoleMenu (id) {
       this.setData = {
        isOpen: true,
        id
      }
    },
    // 改变分页条目
    handleSizeChange (size) {
      this.queryInfo.pageSize = size
      this.getRoleList()
    },
    // 改变页数
    handleCurrentChange (page) {
      this.queryInfo.pageNum = page
      this.getRoleList()
    },
    // 删除角色
    deleteUserHandle (id) {
      this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteRoleById(id).then(res => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.getRoleList()
        })
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: '已取消删除'
        })
      })
    },
    // 监听成功添加与编辑
    successHandle () {
      this.getRoleList()
    }
  }
}
</script>
