<template>
  <div class="addOrgaInfo-coantainer">
    <base-dialog
      :model="addData.isOpen"
      title="添加组织"
      @close="handleClose"
    >
      <template #body>
         <el-form ref="form" :model="formData" :rules="rules" label-width="100px">
          <el-form-item label="组织区域名" labelWidth="130px" prop="orgaName">
            <el-input v-model.trim="formData.orgaName"></el-input>
          </el-form-item>
           <el-form-item label="组织管理员账号" labelWidth="130px" prop="username">
            <el-input v-model.trim="formData.username"></el-input>
          </el-form-item>
          <el-form-item label="组织管理员姓名" labelWidth="130px" prop="realName">
            <el-input v-model.trim="formData.realName"></el-input>
          </el-form-item>
           <el-form-item label="组织管理员电话" labelWidth="130px" prop="phone">
            <el-input v-model.trim="formData.phone"></el-input>
          </el-form-item>
           <el-form-item label="组织管理员密码" labelWidth="130px" prop="password">
            <el-input v-model.trim="formData.password" type="password"></el-input>
          </el-form-item>
          <el-form-item label="现场照片" labelWidth="130px" prop="scenePictures">
            <el-upload
              class="avatar-uploader"
              :action="action"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload">
                <img v-if="formData.scenePictures" :src="$fileUrl + formData.scenePictures" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <!-- <el-form-item label="上级id" prop="parentId">
            <el-cascader
              v-model="formData.parentId"
              :options="orgaInfoTreeList"
              :show-all-levels="false"
              :props="{ checkStrictly: true, value: 'id', label: 'orgaName' }"
              filterable
              clearable
              @change="changeParentId"
            ></el-cascader>
          </el-form-item> -->
          <el-form-item label="备注" labelWidth="130px" prop="remarks">
            <el-input v-model.trim="formData.remarks"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="saveOrgaInfo">确 定</el-button>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import { saveOrgaInfo } from '@/api/orga'

export default {
  props: {
    addData: {
      type: Object
    }
  },
  data () {
    return {
      action: `${process.env.VUE_APP_API_URL}/orga/minio/upload/image`,
      formData: {
        orgaName: '',
        username: '',
        realName: '',
        phone: '',
        password: '',
        parentId: '',
        scenePictures: '',
        remarks: ''
      },
      orgaInfoTreeList: [],
      rules: {
        orgaName: [
          { required: true, message: '请输入组织区域名', trigger: 'blur' }
        ],
        username: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        realName: [
          { required: true, message: '请输入真实姓名', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入电话', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    'addData.isOpen': function () {
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    }
  },
  methods: {
    /* 添加组织 */
    saveOrgaInfo () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formData.password.length < 20) {
              this.formData.password = this.$md5(this.$md5(this.formData.password)).toUpperCase()
          }
          saveOrgaInfo(this.formData).then(res => {
            this.$refs.form.resetFields()
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            this.$emit('successHandle')
            this.handleClose()
          })
        }
      })
    },
    /* 改变父级角色id */
    changeParentId (val) {
      this.formData.parentId = val[val.length - 1]
    },
    // 添加现场图片
    handleAvatarSuccess (res, file) {
        this.formData.scenePictures = file.response.data
        console.log('回传的url', this.formData.scenePictures)
    },
    // 上传前操作
    beforeAvatarUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 10
       if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过10MB!')
        }
        return isLt2M
    },
    /* 关闭弹窗 */
    handleClose () {
      this.addData.isOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    border: 1px solid #bebcbc ;
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
