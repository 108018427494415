<template>
  <div class="page-container">
    <!-- header -->
    <el-card>
      <el-row class="top_row">
          <el-col :span="4" style="text-align:right;">
            <el-input
              placeholder="请输入一级代理手机号码"
              v-model="queryInfo.firstPhone"
            >
          </el-input>
        </el-col>
        <el-col :span="1" ></el-col>
        <el-col :span="4" style="text-align:right;">
            <el-input
              placeholder="请输入二级代理手机号码"
              v-model="queryInfo.secondPhone"
            >
          </el-input>
        </el-col>
        <el-col :span="1" ></el-col>
        <el-col :span="6">
          <el-input
            placeholder="请输入设备名称/标识搜索"
            class="input-with-select"
            clearable
            v-model="queryInfo.keyword"
            @clear="deviceStoragePageRootFn"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="deviceStoragePageRootFn"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="3" ></el-col>
      </el-row>
      <el-row>
      </el-row>
    </el-card>
    <!-- body -->
    <el-card>
      <vxe-table
        resizable
        ref="lockTable"
        align="center"
        :max-height="$wHight - 280"
        :data="lockInfoList"
        :loading="loading"
      >
      <vxe-column type="checkbox" width="60"></vxe-column>
        <vxe-column type="seq" width="50" title="序号"></vxe-column>
        <vxe-column field="iedModel" width="150" title="设备型号"></vxe-column>
        <vxe-column field="iccid" width="150" title="设备iccid"></vxe-column>
        <vxe-column field="baseRate" width="150" title="平台设备抽佣"></vxe-column>
        <vxe-column field="firstPhone" width="150" title="一级代理"></vxe-column>
        <vxe-column field="rate1" width="150" title="一级抽佣率"></vxe-column>
        <vxe-column field="secondPhone" width="150" title="二级代理"></vxe-column>
         <vxe-column field="rate2" width="150" title="二级抽佣率"></vxe-column>
        <vxe-column field="featureCode" width="150" title="设备标识"></vxe-column>
        <vxe-column field="isRealName" width="150" title="是否实名">
          <template #default="{ row }">
            {{ row.isRealName == 1 ? '已实名' : '未实名' }}
          </template>
        </vxe-column>
        <vxe-column field="remarks" width="150" title="备注"></vxe-column>
        <vxe-column field="createTime" width="150" title="创建时间"></vxe-column>
         <template v-if="userInfo.userType === 1">
            <vxe-column fixed="right" title="操作" width="300" >
                <template #default="{ }">
                  <div style="line-height: 40px;">
                    <el-button
                      type="warning"
                      size="mini"
                      >断网</el-button
                    >
                    <el-button
                      type="info"
                      size="mini"
                      >设置达量断网</el-button
                    >
                    <el-button
                      type="info"
                      size="mini"
                      >设置套餐</el-button
                    >
                  </div>
                </template>
            </vxe-column>
         </template>
      </vxe-table>
    </el-card>
    <!-- <base-pagination
      :total="total"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></base-pagination> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { deviceStoragePageRoot } from '@/api/wifi'
export default {
  data () {
    return {
      loading: false,
      queryInfo: {
        pageNum: 1,
        pageSize: 10,
        keyword: '',
        firstPhone: '',
        secondPhone: ''
      },
      lockInfoList: [],
      total: 0
    }
  },
  computed: {
    ...mapState('login', {
      userInfo: (state) => state.userInfo
    })
  },
  mounted () {
    this.queryInfo = {
        pageNum: 1,
        pageSize: 10,
        keyword: '',
        firstPhone: '',
        secondPhone: ''
      }
      this.deviceStoragePageRootFn()
  },
  created () {
  },
  methods: {
     // 获取门锁设备列表
     deviceStoragePageRootFn () {
      this.loading = true
      deviceStoragePageRoot(this.queryInfo).then((res) => {
        this.loading = false
        this.lockInfoList = res.data.list
        this.total = res.data.total
      })
    }
    // /* 获取全部组织 */
    // getOrgaInfoListAll () {
    //   getOrgaInfoListAll().then(res => {
    //     this.orgaInfoList = res.data
    //   })
    // },
    // // 改变分页条目
    // handleSizeChange (size) {
    //   this.queryInfo.pageSize = size
    //   this.deviceStoragePageRootFn()
    // },
    // // 改变页数
    // handleCurrentChange (page) {
    //   this.queryInfo.pageNum = page
    //   this.deviceStoragePageRootFn()
    // },
    // // 选中所有
    // selectAllEvent ({ checked }) {
    //   const records = this.$refs.lockTable.getCheckboxRecords()
    //   console.log(checked ? '所有勾选事件' : '所有取消事件', records)
    // },
    // // 监听成功添加与编辑
    // successHandle () {
    //   this.$refs.lockTable.clearCheckboxRow()
    //   this.deviceStoragePageRootFn()
    // }
  }
}
</script>

<style lang="scss" scoped>
.top_row {
  display: flex;
  align-items: center;
}
</style>
