<template>
  <div class="editOrgaBuild-coantainer">
    <base-dialog
      :model="editData.isOpen"
      title="编辑楼栋"
      @close="handleClose"
    >
      <template #body>
         <el-form ref="form" :model="formData" :rules="rules" label-width="100px">
          <el-form-item label="楼名" prop="buildName">
            <el-input v-model.trim="formData.buildName"></el-input>
          </el-form-item>
          <el-form-item label="总楼层" prop="floorCount">
            <el-input type="number" v-model.trim="formData.floorCount"></el-input>
          </el-form-item>
          <el-form-item label="位置" prop="place">
            <el-input v-model.trim="formData.place"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remarks">
            <el-input v-model.trim="formData.remarks"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="saveOrgaInfo">确 定</el-button>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import { saveOrgaBuild, getOrgaInfoListAll, getOrgaBuildById } from '@/api/orga'

export default {
  props: {
    editData: {
      type: Object
    }
  },
  data () {
    return {
      formData: {
        id: '',
        buildName: '',
        floorCount: null,
        orgaId: null,
        place: '',
        remarks: ''
      },
      orgaInfoList: [],
      rules: {
        orgaName: [
          { required: true, message: '请输入组织区域名', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    'editData.isOpen': function (isOpen) {
      if (isOpen) {
        this.getOrgaInfoListAll()
        this.getOrgaInfoById()
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    }
  },
  methods: {
    // 根据id获取楼栋信息
    getOrgaInfoById () {
      getOrgaBuildById(this.editData.id).then(res => {
        Object.keys(this.formData).forEach((item) => {
          this.formData[item] = res.data[item]
        })
      })
    },
    /* 添加楼栋 */
    saveOrgaInfo () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          saveOrgaBuild(this.formData).then(res => {
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            this.$emit('successHandle')
            this.handleClose()
          })
        }
      })
    },
    /* 获取全部组织 */
    getOrgaInfoListAll () {
      getOrgaInfoListAll().then(res => {
        this.orgaInfoList = res.data
      })
    },
    /* 关闭弹窗 */
    handleClose () {
      this.editData.isOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
