<template>
  <div class="header-container">
    <div class="left-box">
      <div class="icon-top">
        <i class="el-icon-s-fold" @click="toggleCollapse"></i>
      </div>
      <el-breadcrumb replace separator="/">
        <el-breadcrumb-item
          @click.native="removeRouteHandle"
          :to="{ path: '/home' }"
          >首页</el-breadcrumb-item
        >
        <el-breadcrumb-item
          v-for="(item, index) in activeRouter"
          :key="index"
          :to="{ path: item.path }"
        >
          {{ item.name }}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="right-box">
      <i class="el-icon-rank" id="fullScreen" title="全屏" @click="fullScreen"></i>
      <span class="username">{{userInfo.username}}</span>
      <el-dropdown @command="logoutHandle">
        <el-avatar shape="square" src="https://cdn.seovx.com/ha/?mom=302">
        </el-avatar>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="logout">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState('header', {
      isOpen: (state) => state.isOpen
    }),
    ...mapState('activeRouter', {
      activeRouter: (state) => state.activeRouter
    }),
    ...mapState('login', {
      userInfo: (state) => state.userInfo
    })
  },
  methods: {
    /* 展开与隐藏菜单 */
    ...mapMutations('header', ['setOpen']),
    ...mapMutations('activeRouter', ['setActiveRouter']),
    ...mapActions('login', ['logout']),
    toggleCollapse () {
      this.setOpen(!this.isOpen)
    },
    /* 退出登录 */
    logoutHandle (command) {
      if (command === 'logout') {
        this.logout()
        this.$router.push('/login')
      }
    },
    /* 清空当前路由信息 */
    removeRouteHandle () {
      this.setActiveRouter([])
    },
    /* 浏览器全屏 */
    fullScreen () {
      const element = document.documentElement
      if (this.fullscreen) {
        this.$message.success('退出全屏')
        this.fullscreenTitle = '进入全屏'
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      } else {
        this.$message.success('进入全屏')
        this.fullscreenTitle = '退出全屏'
        if (element.requestFullscreen) {
          element.requestFullscreen()
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen()
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen()
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen()
        }
      }
      this.fullscreen = !this.fullscreen
    }
  }
}
</script>

<style lang="scss" scoped>
.header-container {
  display: flex;
  border-bottom: 1px solid #ccc;
  background-color: $color-white;
  .left-box {
    display: flex;
    flex: 1;
    .icon-top {
      padding-left: 20px;
      display: flex;
      align-items: center;
      i {
        cursor: pointer;
        font-size: 24px;
        &:hover {
          color: #409eff;
        }
      }
    }
    .el-breadcrumb {
      height: 56px;
      line-height: 56px;
      padding: 0 20px;
    }
  }
  .right-box {
    display: flex;
    align-items: center;
    margin-right: 20px;
    .el-icon-rank {
      font-size: 26px;
      padding: 0 20px;
      transform: rotate(45deg);
      cursor: pointer;
      &:hover {
        color: #409eff;
      }
    }
    .username {
      padding-right: 20px;
    }
    .el-avatar {
      cursor: pointer;
    }
  }
}
</style>
