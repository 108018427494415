<template>
  <div class="setRoleMenu-dialog">
    <base-dialog :model="setData.isOpen" title="角色菜单" @close="handleClose">
      <template #body>
        <el-tree
        :data="menuList"
        show-checkbox
        default-expand-all
        node-key="id"
        ref="menuTree"
        check-strictly
        :default-expanded-keys="[1, 2, 3, 4]"
        :default-checked-keys="roleMenuList"
        :props="defaultProps"
      >
      </el-tree>
      </template>
      <template #footer>
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="addRoleMenu">确 定</el-button>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import { getMenuList, getRoleMenuById, setRoleMenu } from '@/api/system'

export default {
  props: {
    setData: {
      type: Object
    }
  },
  data () {
    return {
      menuList: [],
      roleMenuList: [],
      defaultProps: {
        children: 'children',
        label: 'title'
      }
    }
  },
  watch: {
    'setData.isOpen' (isOpen) {
      if (isOpen) {
        this.getMenus()
      }
    }
  },
  methods: {
    /* 获取菜单树 */
    getMenus () {
      getMenuList().then(res => {
        this.addAttr(res.data)
        this.menuList = res.data
        this.getRoleMenu()
      })
    },
    /* 递归添加禁用属性 */
    addAttr (data) {
      data.forEach(item => {
        if (item.isAvailable !== 1) {
          item.disabled = true
        }
        if (item.children && item.children.length !== 0) {
          this.addAttr(item.children)
        }
      })
    },
    /* 获取当前角色菜单 */
    getRoleMenu () {
      getRoleMenuById(this.setData.id).then(res => {
        this.roleMenuList = res.data
        this.$nextTick(() => {
          this.$refs.menuTree.setCheckedKeys(this.roleMenuList)
        })
      })
    },
    /* 添加角色菜单 */
    addRoleMenu () {
      const checkData = [...this.$refs.menuTree.getCheckedKeys(), ...this.$refs.menuTree.getHalfCheckedKeys()]
      const menuIds = []
      if (checkData.length > 0) {
        Object.keys(checkData).forEach((key) => {
          menuIds.push(checkData[key])
        })
      }
      const roleMenuInfo = { menuIds, roleId: this.setData.id }
      setRoleMenu(roleMenuInfo).then(res => {
        this.$message({
          message: '添加成功',
          type: 'success'
        })
        this.handleClose()
        this.$emit('successHandle')
      })
    },
    resetChecked () {
      this.$refs.menuTree.setCheckedKeys([])
    },
    /* 关闭弹窗 */
    handleClose () {
     this.setData.isOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>
.setRoleMenu-dialog {

}
</style>
