<template>
  <div class="addAppVersion-dialog">
    <base-dialog
      :model="addData.isOpen"
      @open="open"
      @close="closeDialogHandle"
      :title="addData.title"
    >
      <template #body>
        <el-form
          ref="form"
          :model="formData"
          :rules="rules"
          label-width="100px"
        >
          <el-form-item label="应用名称" prop="appName">
            <el-input v-model.trim="formData.appName"></el-input>
          </el-form-item>
          <el-form-item label="应用类型" prop="appName">
            <el-select v-model="formData.type" placeholder="请选择类型">
              <span v-for="(item, index) in appType" :key="index">
                <el-option
                  :label="item.name"
                  :value="item.id"
                  :key="index"
                ></el-option>
              </span>
            </el-select>
          </el-form-item>
          <el-form-item label="应用版本" prop="version">
            <el-input v-model.trim="formData.version"></el-input>
          </el-form-item>
          <el-form-item label="更新日志" prop="note">
            <el-input v-model.trim="formData.note"></el-input>
          </el-form-item>
          <el-form-item label="安装包">
            <el-button type="primary" @click="uploadVersion.isOpen = true">
              上传<i class="el-icon-upload el-icon--right"></i>
            </el-button>
          </el-form-item>
          <el-form-item label="下载链接" prop="url">
            <el-input v-model.trim="formData.url"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remarks">
            <el-input v-model.trim="formData.remarks"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <el-button @click="addData.isOpen = false">取 消</el-button>
        <el-button type="primary" @click="addAppVersionHandle">确 定</el-button>
      </template>
    </base-dialog>
    <base-upload-file
      :uploadData="uploadVersion"
      @success="uploadSuccessVersion"
      @close="closeUpload"
    ></base-upload-file>
  </div>
</template>

<script>
import { addVersion, getVersionById } from '@/api/version'

export default {
  props: {
    addData: {
      type: Object
    }
  },
  data () {
    return {
      formData: {
        appName: '',
        note: '',
        remarks: '',
        status: 0,
        type: 0,
        updateTime: '',
        url: '',
        version: ''
      },
      appType: [
        {
          id: 0,
          name: 'Android'
        },
        {
          id: 1,
          name: 'IOS'
        }
      ],
      uploadVersion: {
        isOpen: false,
        title: '上传应用版本',
        type: ['application/vnd.android.package-archive', ''],
        size: 300,
        action: '/sys/sysVersion/upload/installer'
      },
      rules: {
        appName: [
          {
            required: true,
            message: '请输入应用名称',
            trigger: 'blur'
          }
        ],
        version: [
          {
            required: true,
            message: '请输入版本信息',
            trigger: 'blur'
          }
        ],
        note: [{
          required: true,
          message: '请输入更新日志',
          trigger: 'blur'
        }],
        url: [{
          required: true,
          message: '请输入下载链接',
          trigger: 'blur'
        }]
      }
    }
  },
  watch: {
    'addData.isOpen': function (isOpen) {
      if (isOpen) {
      }
      if (isOpen && this.addData.type === 'edit') {
      }
      if (!isOpen) {
        this.resetFormData()
      }
    }
  },
  methods: {
    // 根据id获取版本信息
    open () {
      if (this.addData.id !== '') {
        getVersionById(this.addData.id).then((res) => {
          Object.keys(this.formData).forEach((item) => {
            this.formData[item] = res.data[item]
          })
          this.formData.id = this.addData.id
        })
      }
    },
    // 上传成功
    uploadSuccessVersion (data) {
      console.log(data)
      this.formData.url = data.data
      this.uploadVersion.isOpen = false
    },
    // 添加应用版本
    addAppVersionHandle () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          addVersion(this.formData).then((res) => {
            this.$refs.form.resetFields()
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            this.$emit('successHandle')
            this.closeDialogHandle()
          })
        }
      })
    },
    // 重置表单
    resetFormData () {
      this.formData = {
        appName: '',
        note: '',
        remarks: '',
        status: 1, // 1 需要更新，0 无需更新
        type: 0,
        updateTime: '',
        url: '',
        version: ''
      }
    },
    // 关闭上传窗口
    closeUpload () {
      this.uploadVersion.isOpen = false
    },
    // 关闭弹窗
    closeDialogHandle () {
      this.addData.isOpen = false
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
