<template>
  <div class="page-container">
    <!-- header -->
    <el-card>
      <el-row class="setMeal">
        <el-col :span="6">
          <el-input
            placeholder="请输入套餐名称"
            class="input-with-select"
            clearable
            v-model="queryInfo.keyword"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="simBasePackagePageRootFn()"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="1" ></el-col>
         <el-col :span="6">
          <el-button type="primary" @click="setMealFn()">新增套餐</el-button>
        </el-col>
      </el-row>
      <el-row>
      </el-row>
    </el-card>
    <!-- body -->
    <el-card>
      <vxe-table
        resizable
        ref="lockTable"
        align="center"
        :max-height="$wHight - 280"
        :data="lockInfoList"
        :loading="loading"
      >
      <vxe-column type="checkbox" width="60"></vxe-column>
        <vxe-column type="seq" width="50" title="序号"></vxe-column>
        <vxe-column field="packageName" width="250" title="套餐名称"></vxe-column>
        <vxe-column field="packageType" width="150" title="套餐类型">
          <template #default="{ row }">
              <span>{{ row.packageType == 1 ? "主套餐" : "叠加包" }}</span>
          </template>
        </vxe-column>
        <vxe-column field="price" width="150" title="套餐价钱"></vxe-column>
        <vxe-column field="period" width="150" title="套餐时效(月)"></vxe-column>
        <vxe-column field="volume" width="150" title="套餐流量(M)"></vxe-column>
        <vxe-column field="serialNumber" width="150" title="套餐编号"></vxe-column>
        <vxe-column field="isUsed" width="150" title="是否启用">
          <template #default="{ row }">
            <el-switch
              v-model="row.isUsed"
              @change="cutStartStatus($event,row)"
              :active-value="1"
              :inactive-value="0">
            </el-switch>
          </template>
        </vxe-column>
        <vxe-column field="createTime" width="150" title="创建时间"></vxe-column>
         <template v-if="userInfo.userType === 1">
            <vxe-column fixed="right" title="操作" width="300" >
                <template #default="{ row }">
                  <el-button
                      type="success"
                      size="mini"
                      @click="setMealFn(row.id)"
                      >编辑</el-button
                    >
                </template>
            </vxe-column>
         </template>
      </vxe-table>
    </el-card>
    <dialog-meal :mealEditData="mealEditData" @successHandle="successHandle"></dialog-meal>
    <!-- <base-pagination
      :total="total"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></base-pagination> -->
  </div>
</template>

<script>
import { simBasePackagePageRoot, simBasePackageSave } from '@/api/wifi'
import dialogMeal from './dialog/dialogMeal.vue'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      loading: false,
      mealEditData: {
        isOpen: false,
        meadlId: ''
      },
      queryInfo: {
        pageNum: 1,
        pageSize: 10,
        keyword: ''
      },
      lockInfoList: [],
      total: 0
    }
  },
  computed: {
    ...mapState('login', {
      userInfo: (state) => state.userInfo
    })
  },
  components: {
    dialogMeal
  },
  mounted () {
    this.queryInfo = {
        pageNum: 1,
        pageSize: 10,
        keyword: ''
      }
      this.simBasePackagePageRootFn()
  },
  methods: {
    // 启用/停用套餐
    cutStartStatus (status, row) {
      const data = { ...row }
      data.isUsed = status
      simBasePackageSave(data).then(() => {
        this.simBasePackagePageRootFn()
      })
    },
    // 打开设置套餐
    setMealFn (id) {
      if (id) {
        this.mealEditData.isOpen = true
        this.mealEditData.meadlId = id
        console.log('2222---', id)
      } else {
        this.mealEditData.meadlId = ''
        this.mealEditData.isOpen = true
      }
    },
     // 获取流量套餐列表
    simBasePackagePageRootFn () {
      this.loading = true
      simBasePackagePageRoot(this.queryInfo).then((res) => {
        this.loading = false
        this.lockInfoList = res.data.list
        this.total = res.data.total
      })
    },
    // /* 获取全部组织 */
    // getOrgaInfoListAll () {
    //   getOrgaInfoListAll().then(res => {
    //     this.orgaInfoList = res.data
    //   })
    // },
    // // 改变分页条目
    // handleSizeChange (size) {
    //   this.queryInfo.pageSize = size
    //   this.simBasePackagePageRootFn()
    // },
    // // 改变页数
    // handleCurrentChange (page) {
    //   this.queryInfo.pageNum = page
    //   this.simBasePackagePageRootFn()
    // },
    // // 选中所有
    // selectAllEvent ({ checked }) {
    //   const records = this.$refs.lockTable.getCheckboxRecords()
    //   console.log(checked ? '所有勾选事件' : '所有取消事件', records)
    // },
    // 监听成功添加与编辑
    successHandle () {
      this.$refs.lockTable.clearCheckboxRow()
      this.simBasePackagePageRootFn()
    }
  }
}
</script>

<style lang="scss" scoped>
.setMeal {
  display: flex;
  align-items: center;
}
</style>
