import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import md5 from 'js-md5'
import component from '@/components/common'
import './assets/scss/index.scss'
import './assets/iconfont/iconfont/iconfont.css'
import './assets/iconfont/fontAwesome/font-awesome.css'
import './plugins/element.js'
import './router/beforeEach'
import './api/common'
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import dataV from '@jiaminghi/data-view'
import _ from 'lodash'
import permission from '@/directive/permission/index.js' // 自定义指令
Vue.use(permission)

Vue.use(VXETable)
Vue.use(dataV)
Vue.prototype.$_ = _
Vue.prototype.$md5 = md5
Vue.prototype.$wHight = window.document.documentElement.clientHeight
Vue.prototype.$fileUrl = process.env.VUE_APP_FILE_URL // 'http://192.168.31.231'
Vue.config.productionTip = false
Vue.use(component)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
