<template>
  <div class="editOrgaBuild-coantainer">
    <base-dialog
      :model="lockRecodeData.isOpen"
      title="门锁开门记录"
      width="60%"
      @close="handleClose"
    >
      <template #body>
        <vxe-table
        resizable
        ref="lockTable"
        align="center"
        :max-height="$wHight - 580"
        :data="formData"
        :loading="loading"
      >
      <vxe-column field="createTime" title="开锁时间"  width="220"> </vxe-column>
      <vxe-column field="userId" title="用户id" width="220"></vxe-column>
      <vxe-column field="realName" title="真实姓名" width="220">
        <template #default="{ row }">
               <span v-if="row.username">{{ row.username }}</span>
               <span v-else style="color: red;">未实名</span>
        </template>
      </vxe-column>
      <vxe-column field="phone" title="手机号码" width="220"></vxe-column>
        <vxe-column field="unlockWayDetail" title="开锁方式" width="220"></vxe-column>
        <vxe-column field="lockOwner" title="所属组织/人" width="220"></vxe-column>
      </vxe-table>
      <base-pagination
        :total="total"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      ></base-pagination>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import { unlockLogPage } from '@/api/lock'

export default {
  props: {
    lockRecodeData: {
      type: Object
    }
  },
  data () {
    return {
      total: 0,
      queryInfo: {
        pageNum: 1,
        pageSize: 10,
        lockId: ''
      },
      loading: false,
      formData: []
    }
  },
  watch: {
    'lockRecodeData.isOpen': function (isOpen) {
      if (isOpen) {
         console.log('id:', this.lockRecodeData.lockId)
        this.queryInfo.lockId = this.lockRecodeData.lockId
        this.getLockOpenRecodeList()
      }
    }
  },
  methods: {
    // 根据id获取门锁开门记录
    getLockOpenRecodeList () {
      this.loading = true
      unlockLogPage(this.queryInfo).then(res => {
        this.loading = false
        this.total = res.data.total
        this.formData = res.data.list
      })
    },
    // 改变分页条目
    handleSizeChange (size) {
      this.queryInfo.pageSize = size
      this.getLockOpenRecodeList()
    },
    // 改变页数
    handleCurrentChange (page) {
      this.queryInfo.pageNum = page
      this.getLockOpenRecodeList()
    },
    /* 关闭弹窗 */
    handleClose () {
      this.lockRecodeData.isOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
