<template>
  <div class="addOrgaBuild-coantainer">
    <base-dialog
      :model="addData.isOpen"
      title="添加楼栋"
      @close="handleClose"
    >
      <template #body>
         <el-form ref="form" :model="formData" :rules="rules" label-width="100px">
          <el-form-item label="楼名" prop="buildName">
            <el-input v-model.trim="formData.buildName"></el-input>
          </el-form-item>
          <el-form-item label="总楼层" prop="floorCount">
            <el-input type="number" v-model.trim="formData.floorCount"></el-input>
          </el-form-item>
          <el-form-item label="归属组织" prop="orgaId" v-if="addData.userType === 1">
           <el-select v-model="formData.orgaId" placeholder="请选择">
              <el-option
                v-for="item in orgaInfoList"
                :key="item.id"
                :label="item.orgaName"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="位置" prop="place">
            <el-input v-model.trim="formData.place"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remarks">
            <el-input v-model.trim="formData.remarks"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="saveOrgaInfo">确 定</el-button>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import { saveOrgaBuild, getOrgaInfoListAll } from '@/api/orga'

export default {
  props: {
    addData: {
      type: Object
    }
  },
  data () {
    return {
      formData: {
        buildName: '',
        floorCount: null,
        orgaId: null,
        place: '',
        remarks: ''
      },
      orgaInfoList: [],
      rules: {
        orgaName: [
          { required: true, message: '请输入组织区域名', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    'addData.isOpen': function () {
      this.getOrgaInfoListAll()
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    }
  },
  methods: {
    /* 添加楼栋 */
    saveOrgaInfo () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.addData.userType !== 1) {
            this.formData.orgaId = this.$store.state.login.userInfo.orgaId
          }
          saveOrgaBuild(this.formData).then(res => {
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            this.$emit('successHandle')
            this.handleClose()
          })
        }
      })
    },
    /* 获取全部组织 */
    getOrgaInfoListAll () {
      getOrgaInfoListAll().then(res => {
        this.orgaInfoList = res.data
      })
    },
    /* 关闭弹窗 */
    handleClose () {
      this.addData.isOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
