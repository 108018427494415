<template>
  <div class="login-container">
    <div class="login_body">
      <div class="logo">
        </div>
        <el-form ref="loginFormRules" :rules="loginFormRules" :model="formInfo" class="login-form" @keyup.enter.native="loginHandle">
          <div class="title">
            <h3>安当家管理系统</h3>
          </div>
        <el-form-item prop="username">
          <el-input v-model="formInfo.username">
            <template slot="prepend">
              <i slot="prefix" class="el-input__icon fa fa-user"></i>
              <span>账号</span>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input @keyup.enter="loginHandle"  v-model="formInfo.password" type="password">
            <template slot="prepend">
              <i slot="prefix" class="el-input__icon fa fa-key"></i>
              <span>密码</span>
            </template>
          </el-input>
        </el-form-item>
        <!-- <el-form-item style="background: none;border: 0px;">
          <div style="text-align: center;">
            <el-checkbox v-model="formInfo.isChecked" label="是否记住密码" size="large" />
          </div>

        </el-form-item> -->
        <el-button type="primary" size="" @click="loginHandle">登录</el-button>
      </el-form>
    </div>
    <div class="bubble x1"></div>
    <div class="bubble x2"></div>
    <div class="bubble x3"></div>
    <div class="bubble x4"></div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import md5 from 'js-md5'
export default {
  data () {
    return {
      storageData: '',
      formInfo: {
        username: '',
        password: '',
        isChecked: false
      },
      formData: {
        username: '',
        password: ''
      },
      loginFormRules: {
        username: [
          { required: true, message: '请输入登录账号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入登录密码', trigger: 'blur' }
        ]
      }
    }
  },
  mounted () {
    // console.log('初始化----')
    // this.storageData = JSON.parse(window.localStorage.getItem('userLogin'))
    // if (this.storageData) {
    //   this.formInfo = { ...this.storageData }
    //   console.log('得到了数据:', this.formInfo)
    // }
  },
  methods: {
    ...mapActions('login', ['login']),
    /* 用户登录 */
    loginHandle () {
      // if (this.storageData && this.formInfo.isChecked) {
      //   this.rememberLogin()
      // }
      this.$refs.loginFormRules.validate(valid => {
        if (!valid) return false
        /* 请求登录接口 */
        this.formData.username = this.formInfo.username
        // if (this.storageData) {
        //   this.formData.password = this.storageData.password
        // } else {
        //   this.formData.password = md5(md5(this.formInfo.password)).toUpperCase()
        // }
        this.formData.password = md5(md5(this.formInfo.password)).toUpperCase()
        this.login(this.formData).then(res => {
          // if (this.formInfo.isChecked) {
          //   const dt = this.formInfo
          //   dt.password = md5(md5(dt.password)).toUpperCase()
          //   window.localStorage.setItem('userLogin', JSON.stringify(dt))
          //   console.log('我是进来了---', window.localStorage.getItem('userLogin'))
          // } else {
          //   console.log('清除！！！')
          //   window.localStorage.setItem('userLogin', '')
          // }
          window.localStorage.setItem('token', res)
          this.$router.push('/home')
        })
      })
    },
    // 记录密码登录
    rememberLogin () {
      console.log('记录密码登录---')
      const data = {
        username: this.formInfo.username,
        password: this.formInfo.password
      }
      this.login(data).then(res => {
          window.localStorage.setItem('token', res)
          this.$router.push('/home')
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@-webkit-keyframes animateBubble {
    0% {
        margin-top: 1000px;
    }
    100% {
        margin-top: -100%;
    }
}

@-moz-keyframes animateBubble {
    0% {
        margin-top: 1000px;
    }
    100% {
        margin-top: -100%;
    }
}

@keyframes animateBubble {
    0% {
        margin-top: 1000px;
    }
    100% {
        margin-top: -100%;
    }
}

@-webkit-keyframes sideWays {
    0% {
        margin-left:0px;
    }
    100% {
        margin-left:50px;
    }
}

@-moz-keyframes sideWays {
    0% {
        margin-left:0px;
    }
    100% {
        margin-left:50px;
    }
}

@keyframes sideWays {
    0% {
        margin-left:0px;
    }
    100% {
        margin-left:50px;
    }
}
.bubble {
    -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
    -webkit-box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px rgba(255, 255, 255, 1);
  -moz-box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px rgba(255, 255, 255, 1);
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px rgba(255, 255, 255, 1);
    height: 200px;
  position: absolute;
  width: 200px;
  z-index: -1;
}

.bubble:after {
    background: -moz-radial-gradient(center, ellipse cover,  rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 70%); /* FF3.6+ */
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,rgba(255,255,255,0.5)), color-stop(70%,rgba(255,255,255,0))); /* Chrome,Safari4+ */
    background: -webkit-radial-gradient(center, ellipse cover,  rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 70%); /* Chrome10+,Safari5.1+ */
    background: -o-radial-gradient(center, ellipse cover,  rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 70%); /* Opera 12+ */
    background: -ms-radial-gradient(center, ellipse cover,  rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 70%); /* IE10+ */
    background: radial-gradient(ellipse at center,  rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 70%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: inset 0 20px 30px rgba(255, 255, 255, 0.3);
  -moz-box-shadow: inset 0 20px 30px rgba(255, 255, 255, 0.3);
  box-shadow: inset 0 20px 30px rgba(255, 255, 255, 0.3);
  content: "";
    height: 180px;
  left: 10px;
  position: absolute;
  width: 180px;
}
.x1 {
    -webkit-animation: animateBubble 10s linear infinite, sideWays 4s ease-in-out infinite alternate;
  -moz-animation: animateBubble 10s linear infinite, sideWays 4s ease-in-out infinite alternate;
  animation: animateBubble 10s linear infinite, sideWays 4s ease-in-out infinite alternate;

  left: 20%;
  top: 5%;

  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  transform: scale(0.6);
}

.x2 {
    -webkit-animation: animateBubble 6s linear infinite, sideWays 4s ease-in-out infinite alternate;
  -moz-animation: animateBubble 6s linear infinite, sideWays 4s ease-in-out infinite alternate;
  animation: animateBubble 6s linear infinite, sideWays 4s ease-in-out infinite alternate;
  left: 80%;
  top: 80%;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  transform: scale(0.5);
}
.x3 {
    -webkit-animation: animateBubble 7s linear infinite, sideWays 4s ease-in-out infinite alternate;
  -moz-animation: animateBubble 7s linear infinite, sideWays 4s ease-in-out infinite alternate;
  animation: animateBubble 7s linear infinite, sideWays 4s ease-in-out infinite alternate;
  left: 10%;
  top: 10%;
  -webkit-transform: scale(0.7);
  -moz-transform: scale(0.7);
  transform: scale(0.7);
}
.x4 {
    -webkit-animation: animateBubble 8s linear infinite, sideWays 4s ease-in-out infinite alternate;
  -moz-animation: animateBubble 8s linear infinite, sideWays 4s ease-in-out infinite alternate;
  animation: animateBubble 8s linear infinite, sideWays 4s ease-in-out infinite alternate;
  left: 30%;
  top: 30%;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  transform: scale(0.8);
}
.logo {
      overflow: hidden;
      user-select: none;
      background: url(../../assets/image/logo2.png) no-repeat center;
      background-size: contain;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0.6;
      width: 100%;
    }
.login_body {
  background: #ffffff50;
  position: absolute;
  width: 60vw;
  min-width: 700px;
  height: 70vh;
  top: 50%;
  left:50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 50px;
  backdrop-filter: blur(5px);
  box-shadow: 0 16px 23px #587dff1a, inset 0 0 80px #ffffff4d;
}
.login-container {
  width: 100%;
  min-height: 100vh;
  background-image: linear-gradient(to right bottom , #18cc, #18cc5488);
  // background-image: url('../../assets/image/login_bg2.png');
  // background-size: 100% 100%;
  // background-color: $color-aside-bg;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  .login-form {
    width: 450px;
    max-width: 100%;
    /deep/ .el-button {
      width: 100%;
      height: 56px;
      font-size: 20px;
      background-color: var(--color-primary);
      margin-top: 2.2vh;
      margin-bottom: 2.2vh;
      border-radius: 10px;
    }
    /deep/ .el-input__icon {
      line-height: 20px;
    }
    .title h3 {
      text-align: center;
      margin-bottom: 40px;
      font-size: 36px;
      color: $color-info-light;
      font-weight: 700;
      letter-spacing: 10px;
    }
    .el-button {
      width: 100%;
      background-color: $color-primary;
      margin-top: 20px;
      &:hover {
        background-color: $color-primary-dark;
      }
    }
    .el-form-item {
      background: rgba(0,0,0,.1);
      border: 1px solid hsla(0,0%,100%,.1);
      border-radius: 5px;
      .el-input {
        /deep/ .el-input__inner {
          background: transparent;
          color: $color-white;
          border: 2px solid #FFFFFF;
          // border-radius: 12px;
        }
        /deep/ .el-input__prefix {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
</style>
