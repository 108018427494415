<template>
  <div class="page-container">
    <c-header></c-header>
    <c-main class="main-body"></c-main>
  </div>
</template>

<script>
import cHeader from './top/index.vue'
import cMain from './main/index.vue'

export default {
  components: {
    cHeader,
    cMain
  }
}
</script>

<style lang="scss" scoped>
  .page-container {

    .main-body {
      height: calc(100vh - 57px);
      overflow: auto;
      /deep/ .el-card {
      &__body {
        padding:10px 15px ;
      }
    }
    }
  }
</style>
