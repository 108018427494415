import request from '@/service/request'

  /* 分页查询操作手册 */
const operatingManualPage = (params) => {
  return request.post('/sys/operatingManual/page', params)
}

// 根据id获取详情
const operatingManualByid = (params) => {
  return request.post('/sys/operatingManual/getById/' + params.id, params)
}

/* 保存/修改操作手册列 */
const operatingManualSave = (params) => {
  return request.post('/sys/operatingManual/save', params)
}

// 删除操作手册列
const operatingManualDel = (params) => {
  return request.post('/sys/operatingManual/del/' + params.id, params)
}

export {
  operatingManualPage,
  operatingManualByid,
  operatingManualSave,
  operatingManualDel
}
