<template>
  <div class="addOrgaBuild-coantainer">
    <base-dialog
      :model="addData.isOpen"
      title="添加房间"
      @close="handleClose"
    >
      <template #body>
         <el-form ref="form" :model="formData" :rules="rules" label-width="100px">
          <el-form-item label="楼层" prop="floorNum">
            <el-input type="number" v-model.trim="formData.floorNum"></el-input>
          </el-form-item>
          <el-form-item label="楼层房间号" prop="roomNum">
            <el-input v-model.trim="formData.roomNum"></el-input>
          </el-form-item>
          <el-form-item label="楼栋" prop="buildId">
            <el-select
              v-model="formData.buildId"
              clearable
            >
            <el-option
                v-for="item in orgaBuildTreeList"
                :key="item.id"
                :label="item.buildName"
                :value="item.id">
              </el-option>
          </el-select>
          </el-form-item>
          <el-form-item label="备注" prop="remarks">
            <el-input v-model.trim="formData.remarks"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="saveOrgaApartment">确 定</el-button>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import { saveOrgaApartment, getOrgaInfoListAll, getOrgaBuildListTree } from '@/api/orga'

export default {
  props: {
    addData: {
      type: Object
    }
  },
  data () {
    return {
      formData: {
        floorNum: '',
        roomNum: '',
        orgaId: null,
        buildId: null,
        remarks: ''
      },
      orgaInfoList: [],
      orgaBuildTreeList: [],
      rules: {
        orgaName: [
          { required: true, message: '请输入组织区域名', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    'addData.isOpen': function () {
      this.getOrgaInfoListAll()
      this.getOrgaBuildListTree()
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    }
  },
  methods: {
    /* 添加房间 */
    saveOrgaApartment () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          saveOrgaApartment(this.formData).then(res => {
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            this.$emit('successHandle')
            this.handleClose()
          })
        }
      })
    },
    /* 获取楼栋房间树 */
    getOrgaBuildListTree () {
      getOrgaBuildListTree().then(res => {
        this.orgaBuildTreeList = res.data
      })
    },
    /* 获取全部组织 */
    getOrgaInfoListAll () {
      getOrgaInfoListAll().then(res => {
        this.orgaInfoList = res.data
      })
    },
    /* 关闭弹窗 */
    handleClose () {
      this.addData.isOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
