import request from '@/service/request'

/* 分页查询门锁设备 */
const lockInfoPage = (params) => {
  return request.post('/lock/lockInfo/page', params)
}

// 初始化门锁
const resetLockInfo = (params) => {
  return request.post('/lock/lockInfo/resetLockInfo', params)
}

// 门锁分配组织[可批量]
const bindLockOrga = (params) => {
  return request.post('/lock/lockInfo/bindLockOrga', params)
}

// 主动请求同步锁厂数据
const initZkLockList = (params) => {
  return request.post('/lock/lockInfo/initZkLockList', params)
}

// 平台管理员- 多个门锁分配给绑定用户
const bindLocksToOneUser = (params) => {
  return request.post('/lock/lockInfo/bindLocksToOneUser', params)
}

// 平台/组织批量发送锁匙
const sendKeyLocksForOneUser = (params) => {
  return request.post('/lock/lockUser/sendKeyLocksForOneUser', params)
}

// 首页统计数据
const homePageData = (params) => {
  return request.post('/lock/statistics/homePageData', params)
}

// 首页用户增长统计
const getUserNumGroupData = (params) => {
  return request.post('/lock/statistics/getUserNumGroupData', params)
}

// 钥匙管理(组织)
const pageOrgaLockKey = (params) => {
  return request.post('/lock/lockUser/pageOrgaLockKey', params)
}

// 批量删除钥匙
const delBatchLockKey = (params) => {
  return request.post('/lock/lockUser/delBatchLockKey', params)
}

// 分页查询总开锁记录
const unlockLogPage = (params) => {
  return request.post('/lock/unlockLog/page', params)
}

// 根据id查询门锁开门记录(分页)
const pageUnlockLogByLockId = (params) => {
  return request.post('/lock/unlockLog/pageUnlockLogByLockId', params)
}

// 管理员,用户获取发送的钥匙（分页）
const pageLockUser = (params) => {
  return request.post('/lock/lockUser/pageLockUser', params)
}

// 获取动态密码
const getDynamicPassword = (params) => {
  return request.post('/lock/lockOperate/getDynamicPassword/' + params.lockId)
}

// 获取固定密码列表
const listLockZkPw = (params) => {
  return request.post('/lock/lockOperate/listLockZkPwRoot/' + params.lockId)
}

// 重置当前组织下的锁当中的授权码
const syncAuthCode = (params) => {
  return request.post('/rent/lock/syncAuthCode', params)
}

export {
  lockInfoPage,
  resetLockInfo,
  bindLockOrga,
  initZkLockList,
  bindLocksToOneUser,
  sendKeyLocksForOneUser,
  homePageData,
  getUserNumGroupData,
  pageOrgaLockKey,
  delBatchLockKey,
  unlockLogPage,
  pageUnlockLogByLockId,
  pageLockUser,
  getDynamicPassword,
  listLockZkPw,
  syncAuthCode
}
