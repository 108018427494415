<template>
  <div class="addRole-dialog">
    <base-dialog
      :model="addData.isOpen"
      @close="closeDialogHandle"
      :title="addData.title"
    >
      <template #body>
        <el-form ref="form" :model="formData" :rules="rules" label-width="140px">
          <el-form-item label="角色名称" prop="roleName">
            <el-input v-model.trim="formData.roleName"></el-input>
          </el-form-item>
          <el-form-item label="角色类型" prop="roleType">
            <el-select v-model="formData.roleType" placeholder="请选择">
              <el-option
                v-for="(item, index) in roleTypeList"
                :key="index"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="具体角色类型" prop="roleType">
            <el-select v-model="formData.managerRoleType" placeholder="请选择">
              <el-option
                v-for="(item, index) in managerRoleList"
                :key="index"
                :label="item.name"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" prop="remarks">
            <el-input v-model.trim="formData.remarks"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <el-button @click="addData.isOpen = false">取 消</el-button>
        <el-button type="primary" @click="addRoleHandle">确 定</el-button>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import {
  addRole,
  getRoleInfoById,
  getAllManagerRoleType
} from '@/api/system'
import { getOrgaInfoListAll } from '@/api/orga'
export default {
  props: {
    addData: {
      type: Object
    }
  },
  data () {
    return {
      formData: {
        roleName: '',
        remarks: '',
        roleType: null
      },
      orgaList: [],
      managerRoleList: [],
      roleTypeList: [
        {
          name: '管理员',
          id: 1
        }, {
          name: '组织机构管理',
          id: 2
        }
      ],
      rules: {
        roleName: [
          { required: true, message: '请输入角色名', trigger: 'blur' },
          {
            min: 2,
            max: 15,
            message: '长度在 2 到 15 个字符',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  watch: {
    'addData.isOpen': function (isOpen) {
      if (isOpen && this.addData.id) {
        this.getRoleInfoById()
      }
      if (!isOpen) {
        delete this.formData.id
      }
      this.getOrgaInfoListAll()
      this.getAllManagerRoleTypeFn()
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    }
  },
  methods: {
    // 根据id获取角色信息
    getRoleInfoById () {
      getRoleInfoById(this.addData.id).then(res => {
        this.formData.id = this.addData.id
        Object.keys(this.formData).forEach((item) => {
          this.formData[item] = res.data[item]
        })
      })
    },
    /* 获取全部组织 */
    getOrgaInfoListAll () {
      getOrgaInfoListAll().then(res => {
        this.orgaList = res.data
      })
    },
    /* 获取具体角色树 */
    getAllManagerRoleTypeFn () {
      getAllManagerRoleType().then(res => {
        this.managerRoleList = res.data
      })
    },
    // 添加角色
    addRoleHandle () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          addRole(this.formData).then(res => {
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            this.$emit('successHandle')
            this.closeDialogHandle()
          })
        }
      })
    },
    // 关闭弹窗
    closeDialogHandle () {
      this.addData.isOpen = false
    }
  }
}
</script>
