<template>
  <div class="addUser-dialog">
    <base-dialog
      :model="addData.isOpen"
      @close="closeDialogHandle"
      :title="addData.title"
    >
      <template #body>
        <el-form
          ref="form"
          :model="formData"
          :rules="rules"
          label-width="100px"
        >
          <el-form-item label="用户名" prop="username">
            <el-input v-model.trim="formData.username" :disabled="addData.id !== ''"></el-input>
          </el-form-item>
          <el-form-item label="真实姓名" prop="realName">
            <el-input v-model.trim="formData.realName"></el-input>
          </el-form-item>
          <el-form-item label="组织类型" prop="orgaIdList">
            <el-select v-model="formData.orgaIdList" multiple  placeholder="请选择分配组织">
              <span v-for="(item, index) in orgaList" :key="index">
                <el-option
                  :label="item.orgaName"
                  :value="item.id+''"
                  :key="index"
                ></el-option>
              </span>
            </el-select>
          </el-form-item>
          <el-form-item label="身份证号码" prop="cardNum">
            <el-input v-model.trim="formData.cardNum"></el-input>
          </el-form-item>
          <el-form-item label="手机号码" prop="phone">
            <el-input v-model.trim="formData.phone"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remarks">
            <el-input v-model.trim="formData.remarks"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <el-button @click="addData.isOpen = false">取 消</el-button>
        <el-button type="primary" @click="addUserHandle">确 定</el-button>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import { getOrgaInfoPage } from '@/api/orga'
import { saveOrgaMain, getUserInfoById } from '@/api/system'
export default {
  props: {
    addData: {
      type: Object
    }
  },
  data () {
    return {
      formData: {
        username: '',
        password: '',
        realName: '',
        orgaIdList: [],
        phone: '',
        cardNum: '',
        remarks: ''
      },
      orgaList: [],
      rules: {
        // username: [
        //   { required: true, message: '请输入用户名', trigger: 'blur' },
        //   {
        //     min: 2,
        //     max: 10,
        //     message: '长度在 2 到 10 个字符',
        //     trigger: 'blur'
        //   }
        // ]
      }
    }
  },
  watch: {
    'addData.isOpen': function (isOpen) {
      this.getOrgaListFn()
      if (isOpen && this.addData.id) {
        this.getUserInfoById()
      }
      if (!isOpen) {
        delete this.formData.id
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    }
  },
  methods: {
    // 获取组织列表
    getOrgaListFn () {
      const data = {
        pageNum: 1,
        pageSize: 99
      }
      getOrgaInfoPage(data).then(res => {
        this.orgaList = res.data.list
      })
    },
    // 根据id获取用户信息
    getUserInfoById () {
      getUserInfoById(this.addData.id).then((res) => {
        delete this.formData.password
        this.formData.id = this.addData.id
        Object.keys(this.formData).forEach((item) => {
          this.formData[item] = res.data[item]
        })
      })
    },
    // 添加用户
    addUserHandle () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          saveOrgaMain(this.formData).then((res) => {
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            this.$emit('successHandle')
            this.closeDialogHandle()
          })
        }
      })
    },
    // 关闭弹窗
    closeDialogHandle () {
      this.addData.isOpen = false
    }
  }
}
</script>
