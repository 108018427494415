import router from '@/router/index'
import store from '@/store/index'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

/* 全局路由导航守卫 */
router.beforeEach((to, from, next) => {
  NProgress.start()
  if (!window.localStorage.getItem('token')) {
    if (to.path === '/login') {
      next()
    } else {
      next({ path: '/login' })
    }
  } else {
    if (to.path === '/login' && window.localStorage.getItem('token')) {
      next({ path: '/home' })
    } else {
      if (store.getters['login/getRoles'].length === 0) {
        store.dispatch('login/getUserInfo').then(roles => {
          store.dispatch('promission/getUserRole', roles).then((res) => {
            router.addRoutes(store.getters['promission/getRouteTable'])
            next({ ...to, replace: true })
          })
        })
      } else {
        next()
      }
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
