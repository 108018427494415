<template>
  <div class="page-container">
    <!-- header -->
    <el-card>
      <el-row class="search_line" type="flex" >
        <el-col :span="4">
          <el-date-picker
            v-model="startTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="开始日期">
          </el-date-picker>
        </el-col>
        <el-col :span="4">
          <el-date-picker
            v-model="endTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="结束时间">
          </el-date-picker>
        </el-col>
        <el-col :span="4" >
          <el-radio v-model="queryInfo.isSettled" :label="0">未结算</el-radio>
          <el-radio v-model="queryInfo.isSettled" :label="2">已完成</el-radio>
          <el-radio v-model="queryInfo.isSettled" :label="1">已结算</el-radio>
        </el-col>
         <el-col :span="6">
            <el-input
              placeholder="请输入推广人手机号码"
              class="input-with-select"
              clearable
              v-model="queryInfo.keyword"
              @clear="pageAdJdOrderPageFn"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="pageAdJdOrderPageFn"
              ></el-button>
            </el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" style="text-align: right">
        </el-col>
      </el-row>
    </el-card>
    <!-- body -->
    <el-card>
     <vxe-table
        resizable
        ref="xTable"
        align="center"
        :data="keyList"
        :loading="loading"
      >
        <vxe-column field="skuName" title="订单名称" width="420"></vxe-column>
        <vxe-column field="jdOrderId" title="订单号" width="220"></vxe-column>
        <vxe-column field="expressStatus" title="发货状态" width="120">
          <template v-slot="{ row }">
            <span>{{ row.expressStatus === 10 ? '未发货' : '已发货'}}</span>
          </template>
        </vxe-column>
        <vxe-column field="expressStatus" title="订单状态" width="120">
          <template v-slot="{ row }">
            <span>{{ row.isSettled == 0 ? '未结算' : row.isSettled == 1 ? '已结算' : '已完成'  }}</span>
          </template>
        </vxe-column>
        <vxe-column field="seoPhone" title="推荐人" width="160"></vxe-column>
        <vxe-column field="subUnionId" title="推荐码" width="180"></vxe-column>
        <vxe-column field="estimateCosPrice" title="下单佣金额" width="200"></vxe-column>
        <vxe-column field="estimateFee" title="预计佣金" width="180"></vxe-column>
        <vxe-column field="commissionRate" title="佣金比例" width="200">
          <template v-slot="{ row }">
            <span>{{ row.commissionRate + '%'}}</span>
          </template>
        </vxe-column>
        <vxe-column field="finalRate" title="佣金分成比例" width="180">
          <template v-slot="{ row }">
            <span>{{ row.finalRate + '%'}}</span>
          </template>
        </vxe-column>
        <vxe-column field="orderTime" title="下单时间"  width="220"></vxe-column>
        <vxe-column field="finishTime" title="完结时间"  width="220"></vxe-column>
         <vxe-column title="操作"  width="220">
         </vxe-column>
      </vxe-table>
    </el-card>
    <base-pagination
      :total="total"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></base-pagination>
  </div>
</template>

<script>
import { adJdOrderPage } from '@/api/jd'
import { mapState } from 'vuex'
export default {
  computed: {
...mapState('login', ['userInfo'])
},
  data () {
    return {
      detailData: {
        isOpen: false,
        detail: ''
      },
      startTime: '',
      endTime: '',
      queryInfo: {
        isSettled: 0,
        pageNum: 1,
        pageSize: 10,
        keyword: ''
      },
      keyList: [],
      total: 0,
      loading: false
    }
  },
  created () {
    this.pageAdJdOrderPageFn()
  },
  methods: {
    // 获取京东推广单列表
    pageAdJdOrderPageFn () {
      this.loading = true
      const data = JSON.parse(JSON.stringify(this.queryInfo))
      switch (data.isSettled) {
        case 0: // 下单
          data.orderTimeEnd = this.endTime
          data.orderTimeStart = this.startTime
        break
        case 1: // 结算
          data.settledTimeEnd = this.endTime
          data.settledTimeStart = this.startTime
        break
        case 2: // 完成
          data.finishTimeEnd = this.endTime
          data.finishTimeStart = this.startTime
        break
        default:
          break
      }
      adJdOrderPage(data).then((res) => {
        this.loading = false
        this.keyList = res.data.list
        this.total = res.data.total
      })
    },
    // 改变分页条目
    handleSizeChange (size) {
      this.queryInfo.pageSize = size
      this.pageAdJdOrderPageFn()
    },
    // 改变页数
    handleCurrentChange (page) {
      this.queryInfo.pageNum = page
      this.pageAdJdOrderPageFn()
    }
  }
}
</script>

<style lang="scss" scoped>
.search_line {
    display: flex;
    align-items: center;
  }

.row_render {
  padding: 20px 40px;
}
</style>
