<template>
  <div class="page-container">
    <!-- header -->
    <el-card>
      <el-row>
        <el-col :span="6">
          <el-input
            placeholder="请输入关键字搜索"
            class="input-with-select"
            clearable
            v-model="queryInfo.keyword"
            @clear="getOrgaBuildPage"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getOrgaBuildPage"
            ></el-button>
          </el-input>
        </el-col>
      </el-row>
      <el-row type="flex" justify="end" :style="{'margin-top': '10px'}">
        <el-col :span="2" style="text-align: center">
          <el-button type="primary" @click="createBuild"
            >添加楼栋</el-button
          >
        </el-col>
        <el-col :span="3" style="text-align: center">
          <el-button type="success"
          @click="$http.exportExcelHttp.downloadBuildApartment()"
            >exe导入楼房模板</el-button
          >
        </el-col>
        <el-col :span="3" style="text-align: center">
          <el-upload
            action=""
            :on-change="importBuildAndHouse"
            :auto-upload="false"
            :show-file-list="false"
            class="uploadFile"
          >
            <el-button type="warning" >exe批量导入楼房</el-button>
          </el-upload>
        </el-col>
      </el-row>
    </el-card>
    <!-- body -->
    <el-card>
      <vxe-table
        resizable
        ref="build-table"
        align="center"
        :data="orgaBuildList"
        :loading="loading"
      >
        <vxe-column type="seq" width="50" title="序号">
        </vxe-column>
        <vxe-column field="buildName" title="楼栋名称"></vxe-column>
        <vxe-column field="orgaName" title="组织名称"></vxe-column>
        <vxe-column field="floorCount" title="总楼层"></vxe-column>
         <vxe-column field="place" title="位置"></vxe-column>
          <vxe-column field="remarks" title="备注"></vxe-column>
        <vxe-column field="createTime" title="创建时间"></vxe-column>
         <vxe-column title="操作" >
            <template #default="{ row }">
                <el-button
                  type="warning"
                  size="mini"
                  @click="createBuild(row.id)"
                  >编辑</el-button
                >
                <el-button
                  type="danger"
                  size="mini"
                  @click="deleteUserHandle(row.id)"
                  >删除</el-button
                >
            </template>
         </vxe-column>
      </vxe-table>
    </el-card>
    <base-pagination
      :total="total"
      :currentPage="queryInfo.pageNum"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></base-pagination>
    <add-orga-build :addData="addData" @successHandle="successHandle"></add-orga-build>
    <edit-orga-build :editData="editData" @successHandle="successHandle"></edit-orga-build>
  </div>
</template>

<script>
import { getOrgaBuildPage, delOrgaBuild } from '@/api/orga'
import addOrgaBuild from './dialog/addOrgaBuild.vue'
import editOrgaBuild from './dialog/editOrgaBuild.vue'

export default {
  components: {
    addOrgaBuild,
    editOrgaBuild
  },
  data () {
    return {
      loading: false,
      addData: {
        isOpen: false,
        userType: this.$store.state.login.userInfo.userType
      },
      editData: {
        isOpen: false,
        id: '',
        userType: this.$store.state.login.userInfo.userType
      },
      queryInfo: {
        pageNum: 1,
        pageSize: 10,
        keyword: ''
      },
      orgaBuildList: [],
      total: 0
    }
  },
  created () {
    this.getOrgaBuildPage()
  },
  methods: {
    // 获取楼栋列表
    getOrgaBuildPage () {
      this.loading = true
      getOrgaBuildPage(this.queryInfo).then((res) => {
        this.loading = false
        this.orgaBuildList = res.data.list
        this.total = res.data.total
      })
    },
    // 添加与编辑用户
    createBuild (id) {
      if (id.length) {
        this.editData.isOpen = true
        this.editData.id = id
      } else {
        this.addData.isOpen = true
      }
    },
    // 改变分页条目
    handleSizeChange (size) {
      this.queryInfo.pageNum = 1
      this.queryInfo.pageSize = size
      this.getOrgaBuildPage()
    },
    // 改变页数
    handleCurrentChange (page) {
      this.queryInfo.pageNum = page
      this.getOrgaBuildPage()
    },
    // 导入楼栋与房间
    importBuildAndHouse (file) {
      this.loading = true
      const data = new FormData()
      data.append('file', file.raw)
      this.$http.importExcelHttp.importBuildApartment(data).then((res) => {
        this.loading = false
        if (res.data.success) {
          this.$message({
            type: 'success',
            message: res.data.msg || '导入成功'
          })
          this.getOrgaBuildPage()
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg || '导入失败，请重新导入'
          })
        }
      })
    },
    // 删除组织
    deleteUserHandle (id) {
      this.$confirm('此操作将永久删除该楼栋, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delOrgaBuild(id).then((res) => {
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            this.getOrgaBuildPage()
          })
        })
        .catch(() => {
          this.$message({
            type: 'warning',
            message: '已取消删除'
          })
        })
    },
    // 监听成功添加与编辑
    successHandle () {
      this.getOrgaBuildPage()
    }
  }
}
</script>

<style lang="scss" scoped></style>
