<template>
  <div class="page-container">
    <!-- header -->
    <el-card>
      <el-row class="search_line">
        <el-col :span="6">
          <el-input
            placeholder="请输入关键字搜索"
            class="input-with-select"
            clearable
            v-model="queryInfo.keyword"
            @clear="orgaApplyPageListFn"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="orgaApplyPageListFn"
            ></el-button>
          </el-input>
        </el-col>
         <el-col :span="1"></el-col>
         <el-col :span="6">
          <el-radio-group v-model="queryInfo.applyStatus" @change="orgaApplyPageListFn">
            <el-radio :label="1">未审核</el-radio>
            <el-radio :label="2">审核通过</el-radio>
            <el-radio :label="3">审核失败</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" style="text-align: right">
        </el-col>
      </el-row>
    </el-card>
    <!-- body -->
    <el-card>
     <vxe-table
        resizable
        ref="xTable"
        align="center"
        :data="aduitList"
        :loading="loading"
      >
        <vxe-column type="seq" width="50" title="序号">
        </vxe-column>
        <vxe-column field="orgaName" title="申请组织名称"></vxe-column>
        <vxe-column field="phone" title="申请人手机号"></vxe-column>
        <vxe-column field="address" title="组织地址"></vxe-column>
        <vxe-column field="remarks" title="备注"></vxe-column>
        <vxe-column field="createTime" title="申请时间"></vxe-column>
         <vxe-column title="操作" width="420">
            <template #default="{ row }">
            <el-button
                type="info"
                size="mini"
                @click="seeDetail(row)"
                >查看详情</el-button>
              <el-button
              v-if="row.applyStatus === 1"
                type="warning"
                size="mini"
                @click="auditFn(row,2)"
                >审核通过</el-button>
              <el-button
                v-if="row.applyStatus === 1"
                type="danger"
                size="mini"
                @click="auditFn(row,3)"
                >审核驳回</el-button>
            </template>
         </vxe-column>
      </vxe-table>
    </el-card>
    <base-pagination
      :total="total"
      :currentPage="queryInfo.pageNum"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></base-pagination>
   <detail-dialog v-if="detailData.isOpen" :detailData="detailData"></detail-dialog>
  </div>
</template>

<script>
import { deleteUserById, orgaApplyPageList, saveApplyStatus } from '@/api/orga'
import { mapState } from 'vuex'
import detailDialog from './dialog/detailDialog.vue'
export default {
   components: {
    detailDialog
  },
  computed: {
...mapState('login', ['userInfo'])
},
  data () {
    return {
      detailData: {
        isOpen: false,
        detail: ''
      },
      queryInfo: {
        applyStatus: 1,
        pageNum: 1,
        pageSize: 10,
        keyword: ''
      },
      aduitList: [],
      total: 0,
      loading: false
    }
  },
  created () {
    this.orgaApplyPageListFn()
  },
  methods: {
    // 获取审核列表
    orgaApplyPageListFn () {
      this.loading = true
      orgaApplyPageList(this.queryInfo).then((res) => {
        this.aduitList = res.data.list
        this.total = res.data.total
        this.loading = false
      })
    },
    // 查看详细信息
    seeDetail (val) {
       this.detailData.isOpen = true
      this.detailData.detail = val
    },
    // 审核方法
    auditFn (val, status) {
      this.$confirm('此操作将' + (status === 2 ? '通过' : '驳回') + '审批组织, 是否确认?', '操作提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const data = {
            applyStatus: status,
            id: val.id
          }
          saveApplyStatus(data).then(() => {
            this.$message({
                type: 'success',
                message: (status === 2 ? '通过' : '驳回') + '成功'
              })
            this.orgaApplyPageListFn()
          })
        })
        .catch(() => {
          this.$message({
            type: 'warning',
            message: '已取消'
          })
        })
    },
    // 改变分页条目
    handleSizeChange (size) {
      this.queryInfo.pageNum = 1
      this.queryInfo.pageSize = size
      this.orgaApplyPageListFn()
    },
    // 改变页数
    handleCurrentChange (page) {
      this.queryInfo.pageNum = page
      this.orgaApplyPageListFn()
    },
    // 删除组织
    deleteUserHandle (id) {
      this.$confirm('此操作将永久删除该组织, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteUserById(id).then((res) => {
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            this.orgaApplyPageListFn()
          })
        })
        .catch(() => {
          this.$message({
            type: 'warning',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  .search_line {
    display: flex;
    align-items: center;
  }
}

.row_render {
  padding: 20px 40px;
}
</style>
