<template>
  <div class="editOrgaBuild-coantainer">
    <base-dialog
      :model="lockData.isOpen"
      title="钥匙列表"
      width="60%"
      maxHeight="80vh"
      @close="handleClose"
    >
      <template #body>
        <el-row class="pd-tb-lg ">
          <el-col :span="6" style="text-align:right;">
            <el-input
              placeholder="请输入手机号码"
              class="input-with-select"
              clearable
              v-model="queryInfo.phone"
              @clear="getLockKeyList('search')"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getLockKeyList('search')"
              ></el-button>
            </el-input>
          </el-col>
        </el-row>
        <vxe-table
        resizable
        ref="lockTable"
        align="center"
        :max-height="$wHight - 580"
        :data="formData"
        :loading="loading"
      >
      <vxe-column field="keyName" title="钥匙名称"  width="220"> </vxe-column>
      <vxe-column field="roomNum" title="房间名称"  width="220"> </vxe-column>
      <vxe-column field="phone" title="手机号码" width="220"></vxe-column>
      <vxe-column field="expirationDate" title="到期时间" width="220">
        <template #default="{ row }">
               <span v-if="row.expirationDate">{{ row.expirationDate }}</span>
               <span v-else >无限期</span>
        </template>
      </vxe-column>
      <vxe-column field="remainTimes" title="剩余次数" width="220">
      </vxe-column>
      <vxe-column field="realName" title="真实姓名" width="220">
        <template #default="{ row }">
               <span v-if="row.realName">{{ row.realName }}</span>
               <span v-else style="color: red;">未实名</span>
        </template>
      </vxe-column>
      <vxe-column fixed="right" title="操作" width="200" >
        <template #default="{ row }">
          <el-button
              type="danger"
              size="mini"
              @click="deleKeyFn(row)"
              >删除钥匙</el-button
            >
        </template>
      </vxe-column>
      </vxe-table>
      <base-pagination
        :total="total"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      ></base-pagination>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import { pageLockUser, delBatchLockKey } from '@/api/lock'

export default {
  props: {
    lockData: {
      type: Object
    }
  },
  data () {
    return {
      total: 0,
      queryInfo: {
        pageNum: 1,
        pageSize: 10,
        featureCode: '',
        phone: ''
      },
      loading: false,
      formData: []
    }
  },
  watch: {
    'lockData.isOpen': function (isOpen) {
      if (isOpen) {
         console.log('featureCode:', this.lockData.featureCode)
        this.queryInfo.featureCode = this.lockData.featureCode
        this.getLockKeyList('search')
      }
    }
  },
  methods: {
    // 根据id获取门锁开门记录
    getLockKeyList (type) {
      if (type === 'search') {
        this.queryInfo.pageNum = 1
        this.queryInfo.pageSize = 10
      }
      this.loading = true
      pageLockUser(this.queryInfo).then(res => {
        this.loading = false
        this.total = res.data.total
        this.formData = res.data.list
      })
    },
    // 删除钥匙
    deleKeyFn (item) {
      this.$confirm('此操作将永久删除该钥匙, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const data = []
          data.push(item.id)
          delBatchLockKey({ lockUserIdList: data }).then(res => {
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            this.getLockKeyList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'warning',
            message: '已取消删除'
          })
        })
    },
    // 改变分页条目
    handleSizeChange (size) {
      this.queryInfo.pageSize = size
      this.getLockKeyList()
    },
    // 改变页数
    handleCurrentChange (page) {
      this.queryInfo.pageNum = page
      this.getLockKeyList()
    },
    /* 关闭弹窗 */
    handleClose () {
      this.lockData.isOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
