<template>
  <div class="addDialogMeal-coantainer">
    <base-dialog
      :model="mealEditData.isOpen"
      :title="mealEditData.meadlId ? '编辑套餐' : '新增套餐' "
      @close="handleClose"
    >
      <template #body>
         <el-form ref="form" :model="formData" :rules="rules" label-width="150px">
          <el-form-item label="套餐名称" prop="packageName">
            <el-input v-model.trim="formData.packageName"></el-input>
          </el-form-item>

          <el-form-item label="套餐类型" prop="packageType">
            <el-radio-group v-model="formData.packageType" size="small">
              <el-radio :label="1" border>主套餐</el-radio>
              <el-radio :label="2" border>叠加包</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="套餐价格" prop="price">
            <el-input v-model.trim="formData.price"></el-input>
          </el-form-item>

           <el-form-item label="流量值(M)" prop="volume">
            <el-input v-model.trim="formData.volume"></el-input>
          </el-form-item>

          <el-form-item label="有效时间(月)" prop="period">
            <el-input v-model.trim="formData.period"></el-input>
          </el-form-item>

          <el-form-item label="套餐编号" prop="serialNumber">
            <el-input v-model.trim="formData.serialNumber"></el-input>
          </el-form-item>

          <el-form-item label="备注" prop="remarks">
            <el-input v-model.trim="formData.remarks"></el-input>
          </el-form-item>

          <el-form-item label="是否启用" prop="isUsed">
            <el-radio-group v-model="formData.isUsed" size="small">
              <el-radio :label="1" border>是</el-radio>
              <el-radio :label="0" border>否</el-radio>
            </el-radio-group>
          </el-form-item>

        </el-form>
      </template>
      <template #footer>
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="saveSetMealFn">确 定</el-button>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import { simBasePackageSave, simBasePackageGetById } from '@/api/wifi'
export default {
  props: {
    mealEditData: {
      type: Object
    }
  },
  data () {
    return {
      formData: {
        packageType: '',
        packageName: '',
        price: '',
        period: '',
        volume: '',
        serialNumber: '',
        isUsed: 0,
        id: ''
      },
      rules: {
        packageName: [
          { required: true, message: '请输入套餐名称', trigger: 'blur' }
        ],
         price: [
          { required: true, message: '请输入套餐价格', trigger: 'blur' }
        ],
         period: [
          { required: true, message: '请选择套餐有效时间', trigger: 'blur' }
        ],
         volume: [
          { required: true, message: '请输入流量值', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    'mealEditData.isOpen': function () {
      this.$nextTick(() => {
        this.$refs.form.resetFields()
        console.log('弹窗---', this.mealEditData)
        if (this.mealEditData.meadlId) {
          this.formData.id = this.mealEditData.meadlId
          this.simBasePackageGetByIdFn()
        } else {
          this.resetDataFn()
        }
      })
    }
  },
  methods: {
    resetDataFn () {
      this.formData = {
        packageType: '',
        packageName: '',
        price: '',
        period: '',
        volume: '',
        serialNumber: '',
        isUsed: 0,
        id: ''
      }
    },
    // 确定
    submitFn () {
      if (this.formData.id) {
        this.saveSetMealFn()
      } else {
        this.saveSetMealFn()
      }
    },
    // 根据id 获取套餐详情
    simBasePackageGetByIdFn () {
      simBasePackageGetById({ id: this.mealEditData.meadlId }).then(res => {
        this.formData = res.data
      })
    },
    /* 新增/编辑套餐 */
    saveSetMealFn () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          simBasePackageSave(this.formData).then(res => {
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            this.$emit('successHandle')
            this.handleClose()
          })
        }
      })
    },
    /* 关闭弹窗 */
    handleClose () {
      this.mealEditData.isOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
