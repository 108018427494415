<template>
  <div class="page-container">
    <!-- header -->
    <el-card>
      <el-row>
        <el-col :span="6">
          <el-input
            placeholder="请输入关键字搜索"
            class="input-with-select"
            clearable
            v-model="queryInfo.keyword"
            @clear="getPageConsumer"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getPageConsumer"
            ></el-button>
          </el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" style="text-align: right">
        </el-col>
      </el-row>
    </el-card>
    <!-- body -->
    <el-card>
     <vxe-table
        resizable
        ref="xTable"
        align="center"
        :data="orgaInfoList"
        :loading="loading"
      >
        <vxe-column type="seq" width="50" title="序号">
        </vxe-column>
        <vxe-column field="username" title="用户账号"></vxe-column>
        <vxe-column field="realName" title="用户姓名"></vxe-column>
        <vxe-column field="phone" title="手机号码"></vxe-column>
        <vxe-column field="remarks" title="备注"></vxe-column>
        <vxe-column field="createTime" title="创建时间"></vxe-column>
         <vxe-column title="操作" >
         </vxe-column>
      </vxe-table>
    </el-card>
    <base-pagination
      :total="total"
      :currentPage="queryInfo.pageNum"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></base-pagination>
    <add-orga-manager :addManagerData="addManagerData" @successHandle="successHandle"></add-orga-Manager>
    <edit-orga-manager :editManagerData="editManagerData" @successHandle="successHandle"></edit-orga-manager>
  </div>
</template>

<script>
import { getPageConsumer } from '@/api/system'
import { mapState } from 'vuex'
import addOrgaManager from './dialog/addOrgaManager.vue'
import editOrgaManager from './dialog/editOrgaManager.vue'

export default {
  components: {
    addOrgaManager,
    editOrgaManager
  },
  computed: {
...mapState('login', ['userInfo'])
},
  data () {
    return {
      addManagerData: {
        isOpen: false
      },
      editManagerData: {
        isOpen: false,
        id: ''
      },
      queryInfo: {
        pageNum: 1,
        pageSize: 10,
        keyword: ''
      },
      orgaInfoList: [],
      total: 0,
      loading: false
    }
  },
  created () {
    this.getPageConsumer()
  },
  methods: {
    // 获取用户列表
    getPageConsumer () {
      this.loading = true
     this.queryInfo.orgaId = this.userInfo.orgaId
      getPageConsumer(this.queryInfo).then((res) => {
        this.orgaInfoList = res.data.list
        this.total = res.data.total
        this.loading = false
      })
    },

    // 改变分页条目
    handleSizeChange (size) {
      this.queryInfo.pageNum = 1
      this.queryInfo.pageSize = size
      this.getPageConsumer()
    },
    // 改变页数
    handleCurrentChange (page) {
      this.queryInfo.pageNum = page
      this.getPageConsumer()
    },
    // 监听成功添加与编辑
    successHandle () {
      this.getPageConsumer()
    }
  }
}
</script>

<style lang="scss" scoped>
.row_render {
  padding: 20px 40px;
}
</style>
