/* 动态路由表 */
/* role对应路由的角色权限 */
const asyncRouter = [
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/pages/index/index.vue'),
    meta: {
      title: '首页',
      role: ['超级管理员']
    }
  },
  {
    path: '/system/userList',
    name: 'userList',
    component: () => import('@/views/pages/system/userList/index.vue'),
    meta: {
      title: '用户列表',
      role: ['超级管理员']
    }
  },
  {
    path: '/system/roleList',
    name: 'roleList',
    component: () => import('@/views/pages/system/roleList/index.vue'),
    meta: {
      title: '角色列表',
      role: ['超级管理员']
    }
  },
  {
    path: '/system/menuList',
    name: 'menuList',
    component: () => import('@/views/pages/system/menuList/index.vue'),
    meta: {
      title: '菜单列表',
      role: ['超级管理员']
    }
  },
  {
    path: '/system/ordinaryUser',
    name: 'ordinaryUser',
    component: () => import('@/views/pages/system/ordinaryUser/index.vue'),
    meta: {
      title: '用户管理',
      role: ['超级管理员']
    }
  },
  {
    path: '/system/appManage',
    name: 'appManage',
    component: () => import('@/views/pages/system/appManage/version.vue'),
    meta: {
      title: '版本管理',
      role: ['超级管理员']
    }
  },
  {
    path: '/system/advertising',
    name: 'advertising',
    component: () => import('@/views/pages/system/advertising/index.vue'),
    meta: {
      title: '广告管理',
      role: ['超级管理员']
    }
  },
  {
    path: '/system/appOperationHandbook',
    name: 'appOperationHandbook',
    component: () => import('@/views/pages/system/appOperationHandbook/index.vue'),
    meta: {
      title: 'APP操作手册设置',
      role: ['超级管理员']
    }
  },
  {
    path: '/system/appAdBody',
    name: 'appAdBody',
    component: () => import('@/views/pages/system/appAdBody/appAdBody.vue'),
    meta: {
      title: '广告边框主题管理',
      role: ['超级管理员']
    }
  },
  {
    path: '/system/updateApp',
    name: 'updateApp',
    component: () => import('@/views/pages/system/updateApp/index.vue'),
    meta: {
      title: '软件更新管理',
      role: ['超级管理员']
    }
  },
  {
    path: '/system/orgaManagerAdmin',
    name: 'orgaManagerAdmin',
    component: () => import('@/views/pages/system/orgaManagerAdmin/orgaManagerAdmin.vue'),
    meta: {
      title: '组织总管管理',
      role: ['超级管理员']
    }
  },
  {
    path: '/orgaManagerList/orgaInfo',
    name: 'orgaInfoList',
    component: () => import('@/views/pages/orgaManagerList/orgaInfo/index.vue'),
    meta: {
      title: '组织管理',
      role: ['超级管理员']
    }
  },
  {
    path: '/orgaManagerList/conservator',
    name: 'conservator',
    component: () => import('@/views/pages/orgaManagerList/conservator/index.vue'),
    meta: {
      title: '管理员管理',
      role: ['超级管理员', '区域管理员']
    }
  },
  {
    path: '/orga/orgaBuild',
    name: 'orgaBuildList',
    component: () => import('@/views/pages/orga/orgaBuild/index.vue'),
    meta: {
      title: '楼栋列表',
      role: ['超级管理员']
    }
  },
  {
    path: '/orga/orgaApartment',
    name: 'orgaApartmentList',
    component: () => import('@/views/pages/orga/orgaApartment/index.vue'),
    meta: {
      title: '房间管理',
      role: ['超级管理员']
    }
  },
  {
    path: '/orga/orgaAudit',
    name: 'orgaAuditList',
    component: () => import('@/views/pages/orga/orgaAudit/index.vue'),
    meta: {
      title: '审核管理',
      role: ['超级管理员']
    }
  },
  {
    path: '/lockInfoList/lockSet',
    name: 'lockSetList',
    component: () => import('@/views/pages/lockInfoList/lockSet/index.vue'),
    meta: {
      title: '门锁管理',
      role: ['超级管理员']
    }
  },
  {
    path: '/lockInfoList/lockKeyAdmin',
    name: 'lockKeyAdmin',
    component: () => import('@/views/pages/lockInfoList/lockKeyAdmin/index.vue'),
    meta: {
      title: '钥匙管理',
      role: ['超级管理员']
    }
  },
  {
    path: '/lockInfoList/lockOpenRecode',
    name: 'lockOpenRecode',
    component: () => import('@/views/pages/lockInfoList/lockOpenRecode/lockOpenRecode.vue'),
    meta: {
      title: '门锁记录',
      role: ['超级管理员']
    }
  },
  {
    path: '/jdOrder/jdOrderList',
    name: 'jdOrderList',
    component: () => import('@/views/pages/jdOrder/jdOrderList/index.vue'),
    meta: {
      title: '京东推广列表',
      role: ['超级管理员']
    }
  },
  {
    path: '/jdOrder/clashAudit',
    name: 'clashAudit',
    component: () => import('@/views/pages/jdOrder/clashAudit/clashAudit.vue'),
    meta: {
      title: '提现审核',
      role: ['超级管理员']
    }
  },
  {
    path: '/wifiHotspot/facilityAdmin',
    name: 'facilityAdmin',
    component: () => import('@/views/pages/wifiHotspot/facilityAdmin/index.vue'),
    meta: {
      title: '移动wifi管理',
      role: ['超级管理员']
    }
  },
  {
    path: '/wifiHotspot/setMeal',
    name: 'setMeal',
    component: () => import('@/views/pages/wifiHotspot/setMeal/setMeal.vue'),
    meta: {
      title: '套餐设置',
      role: ['超级管理员']
    }
  },
  {
    path: '/wifiHotspot/marketFacility',
    name: 'marketFacility',
    component: () => import('@/views/pages/wifiHotspot/marketFacility/marketFacility.vue'),
    meta: {
      title: '设备销售管理',
      role: ['超级管理员']
    }
  },
  { path: '/:pathMatch(.*)*', component: () => import('@/components/404/index.vue') }
]

export default asyncRouter
