<template>
  <div class="page-container">
    <!-- header -->
    <el-card>
      <el-row class="pd-tb-lg top_row" gutter="20">
        <el-col :span="3" v-if="userInfo.userType === 1">
            <el-switch
              v-model="queryInfo.isNoOwnerOrga"
              inactive-text="是否过滤新设备"
              @change="isFiterLock">
            </el-switch>
          </el-col>
        <el-col :span="3" v-if="userInfo.userType === 1">
            <el-select clearable v-model="queryInfo.orgaId" placeholder="请选择组织"  @clear="lockInfoPage('search')" @change="selectOrga">
              <el-option
                v-for="item in orgaInfoList"
                :key="item.id"
                :label="item.orgaName"
                :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="3" >
          <el-select v-model="queryInfo.lockType" placeholder="请选择">
            <el-option
              v-for="item in lockTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-date-picker
          value-format="yyyy-MM-dd HH:mm:ss"
            v-model="queryInfo.startTime"
            type="date"
            placeholder="选择开始日期"
            >
          </el-date-picker>
        </el-col>
        <el-col :span="4">
          <el-date-picker
          value-format="yyyy-MM-dd HH:mm:ss"
            v-model="queryInfo.endTime"
            type="date"
            placeholder="选择结束日期">
          </el-date-picker>
        </el-col>
        <el-col :span="3"  v-if="userInfo.userType === 1">
            <el-button  type="danger" @click="resetDeviceAuthCode()">重置组织设备授权</el-button>
        </el-col>
        <el-col :span="3" v-if="userInfo.userType === 1">
            <el-button type="primary" @click="initZkLockListFn()">同步锁厂设备列表</el-button>
        </el-col>
        <el-col :span="3"  v-if="userInfo.userType === 1">
            <el-button type="primary" @click="batchOrgan()">批量分配组织</el-button>
        </el-col>
      </el-row>
      <el-row class="top_row" gutter="20">

          <el-col :span="3" class="pd-lr-lg" style="text-align:right;">
            <el-input
              placeholder="请输入手机号码"
              v-model="queryInfo.phone"
            >
          </el-input>
        </el-col>
        <el-col :span="3" class="pd-lr-lg">
          <el-input
              placeholder="请输入设备名称"
              v-model="queryInfo.lockName"
            >
          </el-input>
        </el-col>
        <el-col :span="4" class="pd-lr-lg">

          <el-input
            placeholder="标识搜索"
            class="input-with-select"
            clearable
            v-model="queryInfo.keyword"
            @clear="lockInfoPage('search')"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="lockInfoPage('search')"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="1" ></el-col>

      </el-row>

    </el-card>
    <!-- body -->
    <el-card>
      <vxe-table
        resizable
        ref="lockTable"
        align="center"
        :max-height="$wHight - 280"
        :data="lockInfoList"
        :loading="loading"
      >
      <vxe-column type="checkbox" width="60"></vxe-column>
        <vxe-column type="seq" width="50" title="序号"></vxe-column>
        <vxe-column field="lockName" width="150" title="设备名称"></vxe-column>
        <vxe-column field="authCode" width="150" title="授权码"></vxe-column>
        <vxe-column field="featureCode" width="150" title="设备标识"></vxe-column>
        <vxe-column field="deviceId" width="150" title="蓝牙MAC地址"></vxe-column>
        <vxe-column field="orgaName" width="150" title="绑定组织"></vxe-column>
        <vxe-column field="username" width="150" title="绑定个人"></vxe-column>
        <vxe-column field="phone" width="150" title="绑定手机号"></vxe-column>
        <vxe-column field="roomNum" width="150" title="房间名称"></vxe-column>
        <vxe-column field="remarks" width="150" title="备注"></vxe-column>
        <vxe-column field="createTime" width="150" title="创建时间"></vxe-column>
        <vxe-column title="二维码" width="150">
            <template #default="{ row }" >
              <div v-if="row.lockType === 0">
                 <vue-qr :correctLevel="1" :size="120" v-if="row.featureCode" :text="'http://smartdiy.suozhang.net/OAuth/Index/' + row.featureCode"></vue-qr>
              </div>
              <div v-if="row.lockType === 1">
                <vue-qr :correctLevel="1" :size="120" v-if="row.deviceId" :text="'http://admin.kaixiaobang.com/AALock?id=' + row.featureCode"></vue-qr>
              </div>
            </template>
         </vxe-column>
         <template >
            <vxe-column fixed="right" title="操作" width="300" >
                <template #default="{ row }">
                  <div style="line-height: 35px;">
                    <el-button
                    v-if="userInfo.userType === 1"
                      type="success"
                      size="mini"
                      @click="allocationUser(row)"
                      >分配用户</el-button
                    >
                    <el-button
                    v-if="userInfo.userType === 1"
                      type="warning"
                      size="mini"
                      @click="allocationOrgan(row)"
                      >分配组织</el-button
                    >
                    <el-button
                      v-permission="['lock:info:reset']"
                      type="danger"
                      size="mini"
                      @click="resetLock(row)"
                      >重置门锁</el-button
                    >
                    <el-button
                      type="info"
                      size="mini"
                      @click="lockRecode(row.id)"
                      >门锁记录</el-button
                    >
                    <el-button
                      type="primary"
                      size="mini"
                      @click="getKeyList(row)"
                      >钥匙列表</el-button
                    >
                    <el-button
                      v-if="row.lockType === 0"
                        class="org-button"
                        size="mini"
                        @click="senndKey(row)"
                        >发送锁匙</el-button
                      >
                      <el-button
                        v-if="row.lockType === 0"
                        class="cyan-button"
                        size="mini"
                        @click="getPassword(row)"
                        >获取临时密码</el-button
                      >
                      <el-button
                        class="fun-button2"
                        size="mini"
                        @click="getlongPasswordList(row)"
                        >固定密码列表</el-button
                      >
                      <el-button
                    v-if="row.orgaId && row.lockType === 1"
                     class="fun-button3"
                      size="mini"
                      @click="resetLockAuthCode(row)"
                      >重置授权码</el-button
                    >
                  </div>
                </template>
            </vxe-column>
         </template>
      </vxe-table>
    </el-card>
    <base-pagination
      :total="total"
      :currentPage="queryInfo.pageNum"
      :pageSize="queryInfo.pageSize"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></base-pagination>
    <edit-lock-info :editData="editData" @successHandle="successHandle"></edit-lock-info>
    <edit-bind-user :editUserData="editUserData" @successHandle="successHandle2"></edit-bind-user>
    <lock-recode-dialog :lockRecodeData="lockRecodeData"></lock-recode-dialog>
    <key-list-dialog :lockData="lockData"></key-list-dialog>
    <send-key-dialog :editData="sendKeyData" ></send-key-dialog>
    <lockpassword :lockPassWordData="lockPassWordData"></lockpassword>
  </div>
</template>

<script>
import { lockInfoPage, resetLockInfo, initZkLockList, getDynamicPassword, syncAuthCode } from '@/api/lock'
import { resetLock, trendPassUnlockOrga } from '@/api/aalock'
import { getOrgaInfoListAll } from '@/api/orga'
import editLockInfo from './dialog/editLockInfo.vue'
import editBindUser from './dialog/editBindUser.vue'
import lockRecodeDialog from './dialog/lockRecodeDialog.vue'
import keyListDialog from './dialog/keyListDialog.vue'
import lockpassword from './dialog/lockpassWord.vue'
import sendKeyDialog from '../../orga/orgaApartment/dialog/sendKeyDialog'
import vueQr from 'vue-qr'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      loading: false,
      lockData: {
        isOpen: false,
        featureCode: ''
      },
      lockRecodeData: {
        lockId: '',
        isOpen: false
      },
      editUserData: {
        isOpen: false,
        lockIdList: [],
        phone: ''
      },
      editData: {
        isOpen: false,
        lockIdList: [],
        orgaId: ''
      },
      sendKeyData: {
        isOpen: false,
        lockIdList: []
      },
      lockPassWordData: {
        lockId: '',
        isOpen: false
      },
      queryInfo: {
        lockType: '',
        isNoOwnerOrga: false,
        pageNum: 1,
        pageSize: 10,
        keyword: '',
        phone: '',
        lockName: '',
        orgaId: '',
        startTime: '',
        endTime: ''
      },
      lockInfoList: [],
      orgaInfoList: [],
      total: 0,
      lockTypeList: [
        {
          value: '',
          label: '全部'
        },
        {
          value: '0',
          label: '掌控'
        },
        {
          value: '1',
          label: 'aalock'
        }
      ]
    }
  },
  computed: {
    ...mapState('login', {
      userInfo: (state) => state.userInfo
    })
  },
  components: {
    editLockInfo,
    editBindUser,
    vueQr,
    lockRecodeDialog,
    keyListDialog,
    sendKeyDialog,
    lockpassword
  },
  created () {
    if (this.userInfo.userType === 1) {
      this.getOrgaInfoListAll()
    } else {
      this.queryInfo.orgaId = this.userInfo.orgaId
    }
    this.lockInfoPage()
  },
  methods: {
    // 重置该设备的授权码
    resetLockAuthCode (val) {
      this.$confirm('是否重置组织中该门锁的授权码?', '操作提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
          syncAuthCode({ orgaId: val.orgaId, featureCodeList: [val.featureCode] }).then(() => {
            this.$message({
              type: 'success',
              message: '重置成功'
            })
            this.lockInfoPage()
          })
        })
    },
    // 重置组织设备授权码
    resetDeviceAuthCode () {
      if (!this.queryInfo.orgaId) {
        return this.$message({
                  type: 'info',
                  message: '请选择组织'
                })
      }
      this.$confirm('此操作将组织下所有门锁授权码更改, 是否确认?', '操作提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          syncAuthCode({ orgaId: this.queryInfo.orgaId }).then(() => {
            this.$message({
              type: 'success',
              message: '重置成功'
            })
            this.lockInfoPage()
          })
        })
        .catch(() => {
          this.$message({
            type: 'warning',
            message: '已取消'
          })
        })
    },
    // 获取长期密码列表
    getlongPasswordList (row) {
      this.lockPassWordData.isOpen = true
      this.lockPassWordData.lockId = row.id
    },
    // 获取临时密码
    getPassword (row) {
      if (row.lockType === 0) {
        getDynamicPassword({ lockId: row.id }).then(res => {
            const h = this.$createElement
            this.$notify({
              title: row.lockName + ' 临时密码',
              message: h('i', { }, res.data)
            })
          })
      } else {
        trendPassUnlockOrga({ id: row.aalockId }).then(res => {
          const h = this.$createElement
            this.$notify({
              title: row.lockName + ' 临时密码',
              message: h('i', { }, res.data)
            })
        })
      }
    },
     // 打开发送锁匙
     senndKey (row) {
      this.sendKeyData.isOpen = true
      this.sendKeyData.lockIdList = [row.id]
    },
    // 获取该设备的列表
    getKeyList (row) {
      this.lockData.featureCode = row.lockType === 0 ? row.featureCode : row.deviceId
      this.lockData.isOpen = true
    },
    // 批量分配组织
    batchOrgan () {
     const records = this.$refs.lockTable.getCheckboxRecords()
     if (records.length === 0) {
      return this.$message({
                  type: 'info',
                  message: '请选择门锁'
                })
     }
     const idList = []
     records.forEach(item => {
        idList.push(item.id)
     })
     console.log(idList)
    this.editData.isOpen = true
    this.editData.lockIdList = JSON.parse(JSON.stringify(idList))
    },
    // 分配用户
    allocationUser (row) {
      this.editUserData.isOpen = true
      this.editUserData.phone = row.phone
      this.editUserData.lockIdList = [row.id]
    },
    // 分配组织
    allocationOrgan (row) {
      this.editData.isOpen = true
      this.editData.orgaId = row.orgaId
      this.editData.lockIdList = [row.id]
    },
    // 选择是否过滤组织
    isFiterLock () {
      this.queryInfo.orgaId = ''
      this.lockInfoPage('search')
    },
    // 选择组织
    selectOrga () {
      this.queryInfo.isNoOwnerOrga = false
      this.lockInfoPage('search')
    },
    // 打开门锁记录
    lockRecode (id) {
      this.lockRecodeData.lockId = id
      this.lockRecodeData.isOpen = true
    },
    // 重设门锁
    resetLock (val) {
        this.$confirm('此操作将初始化门锁, 是否确认?', '操作提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          if (val.lockType === 1) {
            resetLock({ aalockId: val.aalockId }).then(res => {
              this.$message({
                  type: 'success',
                  message: '初始化成功'
                })
                this.lockInfoPage()
            })
          } else {
            const data = [val.id]
            resetLockInfo({ lockIdList: data }).then(res => {
              this.$message({
                  type: 'success',
                  message: '初始化成功'
                })
                this.lockInfoPage()
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'warning',
            message: '已取消'
          })
        })
    },
    // 主动同步锁厂数据
    initZkLockListFn () {
      initZkLockList().then(() => {
        this.$message({
            type: 'success',
            message: '同步成功'
          })
        this.lockInfoPage()
      })
    },
     // 获取门锁设备列表
    lockInfoPage (type) {
      if (type === 'search') {
        this.total = 0
        this.queryInfo.pageNum = 1
        this.queryInfo.pageSize = 10
      }
      this.loading = true
      lockInfoPage(this.queryInfo).then((res) => {
        this.loading = false
        this.lockInfoList = res.data.list
        this.total = res.data.total
      })
    },
    /* 获取全部组织 */
    getOrgaInfoListAll () {
      getOrgaInfoListAll().then(res => {
        this.orgaInfoList = res.data
      })
    },
    // 改变分页条目
    handleSizeChange (size) {
      this.queryInfo.pageNum = 1
      this.queryInfo.pageSize = size
      this.lockInfoPage()
    },
    // 改变页数
    handleCurrentChange (page) {
      this.queryInfo.pageNum = page
      this.lockInfoPage()
    },
    // 选中所有
    selectAllEvent ({ checked }) {
      const records = this.$refs.lockTable.getCheckboxRecords()
      console.log(checked ? '所有勾选事件' : '所有取消事件', records)
    },
    // 监听成功添加与编辑
    successHandle () {
      this.$refs.lockTable.clearCheckboxRow()
      this.lockInfoPage()
    },
    // 监听成功添加与编辑分配用户
    successHandle2 () {
      this.$refs.lockTable.clearCheckboxRow()
      this.lockInfoPage()
    }
  }
}
</script>

<style lang="scss" scoped>
.top_row {
  display: flex;
  align-items: center;
}
</style>
