<template>
  <div class="addAppVersion-dialog">
    <base-dialog
      :model="addData.isOpen"
      @open="open"
      @close="closeDialogHandle"
      :title="addData.title"
    >
      <template #body>
        <el-form
        v-if="addData.isOpen"
          ref="form"
          :model="formData"
          :rules="rules"
          label-width="140px"
        >
          <el-form-item label="广告边框主题"  prop="theme">
            <el-input v-model.trim="formData.theme"></el-input>
          </el-form-item>
            <el-form-item label="方大图边框" prop="squareBorder" class="dis-sp-center">
              <el-image
                v-if="formData.squareBorder"
                style="width: 100px; height: 100px;margin-right: 20px;"
                :src="$fileUrl + '' + formData.squareBorder"
                :preview-src-list="[$fileUrl + '' + formData.squareBorder]"
                fit="cover"
              >
              </el-image>
              <el-button
                @click="upImg('squareBorder')"
                type="primary"
                size="mini"
                >上传<i class="el-icon-upload el-icon--right"></i
              ></el-button>
            </el-form-item>
            <el-form-item label="横幅边框" prop="bannerBorder" class="dis-sp-center">
              <el-image
                v-if="formData.bannerBorder"
                style="width: 100px; height: 100px;margin-right: 20px;"
                :src="$fileUrl + '' + formData.bannerBorder"
                :preview-src-list="[$fileUrl + '' + formData.bannerBorder]"
                fit="cover"
              >
              </el-image>
              <el-button
                @click="upImg('bannerBorder')"
                type="primary"
                size="mini"
                >上传<i class="el-icon-upload el-icon--right"></i
              ></el-button>
            </el-form-item>
            <el-form-item label="礼品图标" prop="giftIcon" class="dis-sp-center">
              <el-image
                v-if="formData.giftIcon"
                style="width: 100px; height: 100px;margin-right: 20px;"
                :src="$fileUrl + '' + formData.giftIcon"
                :preview-src-list="[$fileUrl + '' + formData.giftIcon]"
                fit="cover"
              >
              </el-image>
              <el-button
                @click="upImg('giftIcon')"
                type="primary"
                size="mini"
                >上传<i class="el-icon-upload el-icon--right"></i
              ></el-button>
            </el-form-item>
            <el-form-item label="商城横向边框" prop="shopBannerBorder" class="dis-sp-center">
              <el-image
                v-if="formData.shopBannerBorder"
                style="width: 100px; height: 100px;margin-right: 20px;"
                :src="$fileUrl + '' + formData.shopBannerBorder"
                :preview-src-list="[$fileUrl + '' + formData.shopBannerBorder]"
                fit="cover"
              >
              </el-image>
              <el-button
                @click="upImg('shopBannerBorder')"
                type="primary"
                size="mini"
                >上传<i class="el-icon-upload el-icon--right"></i
              ></el-button>
            </el-form-item>
          <el-form-item label="备注" prop="remarks">
            <el-input v-model.trim="formData.remarks"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <el-button @click="addData.isOpen = false">取 消</el-button>
        <el-button type="primary" @click="addAd">确 定</el-button>
      </template>
    </base-dialog>
    <base-upload-image
    v-if="uploadVersion.isOpen"
      :dialogData="uploadVersion"
      @success="uploadSuccessVersion"
      @close="closeUpload"
    ></base-upload-image>
  </div>
</template>

<script>
import { adBodyTemplateSave, templateGetById } from '@/api/adv'

export default {
  props: {
    addData: {
      type: Object
    }
  },
  data () {
    return {
      formData: {
        shopBannerBorder: '',
        bannerBorder: '',
        giftIcon: '',
        id: '',
        isUsed: 0,
        remarks: '',
        squareBorder: '',
        theme: ''
      },
      imgName: '',
      uploadVersion: {
        isOpen: false
      },
      rules: {
        theme: [
          {
            required: true,
            message: '请输入边框主题名称',
            trigger: 'blur'
          }
        ],
        bannerBorder: [
          {
            required: true,
            message: '请上传横向边框图片',
            trigger: 'blur'
          }
        ],
        giftIcon: [
          {
            required: true,
            message: '请上传礼物icon图',
            trigger: 'blur'
          }
        ],
        squareBorder: [
          {
            required: true,
            message: '请上传方形广告边框图',
            trigger: 'blur'
          }
        ],
        shopBannerBorder: [
          {
            required: true,
            message: '请上传商城广告边框图',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  watch: {
    'addData.isOpen': function (isOpen) {
      if (isOpen && this.addData.type === 'edit') {
        this.open()
      }
      if (!isOpen) {
        this.resetFormData()
      }
    }
  },
  methods: {
    // 打开上传图片
    upImg (val) {
      this.imgName = val
      this.uploadVersion.isOpen = true
    },
    // 根据id获取版本信息
    open () {
      if (this.addData.id !== '') {
        templateGetById(this.addData.id).then((res) => {
          Object.keys(this.formData).forEach((item) => {
            this.formData[item] = res.data[item]
          })
          this.formData.id = this.addData.id
        })
      }
    },
    // 上传成功
    uploadSuccessVersion (data) {
      console.log(data)
      this.formData[this.imgName] = data.data
      this.uploadVersion.isOpen = false
    },
    // 新增广告
    addAd () {
      if (this.formData.adType !== 1 && this.formData.path === '' && this.formData.adType !== 4) {
        return this.$message({
              message: '请输入路径',
              type: 'error'
            })
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          adBodyTemplateSave(this.formData).then((res) => {
            this.$refs.form.resetFields()
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            this.$emit('successHandle')
            this.closeDialogHandle()
          })
        }
      })
    },
    // 重置表单
    resetFormData () {
      this.formData = {
        shopBannerBorder: '',
        bannerBorder: '',
        giftIcon: '',
        id: '',
        isUsed: 0,
        remarks: '',
        squareBorder: '',
        theme: ''
      }
    },
    // 关闭上传窗口
    closeUpload () {
      this.uploadVersion.isOpen = false
    },
    // 关闭弹窗
    closeDialogHandle () {
      this.addData.isOpen = false
    }
  }
}
</script>
<style lang="scss" scoped>
  .dis-sp-center{
    display: flex;
    align-items: center;
    /deep/.el-form-item__content{
      display: flex;
      align-items: center;
      margin-left:0px !important;
    }
  }
</style>
