<template>
  <div class="page-container">
    <!-- header -->
    <el-card>
      <el-row class="search_line">
        <el-col :span="6">
          <el-input
            placeholder="请输入手机号码/房名"
            class="input-with-select"
            clearable
            v-model="queryInfo.keyword"
            @clear="pageOrgaLockKeyFn"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="pageOrgaLockKeyFn"
            ></el-button>
          </el-input>
        </el-col>
         <el-col :span="1"></el-col>
         <el-col :span="6">
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" style="text-align: right">
        </el-col>
      </el-row>
    </el-card>
    <!-- body -->
    <el-card>
     <vxe-table
        resizable
        ref="xTable"
        align="center"
        :data="keyList"
        :loading="loading"
      >
      <vxe-column type="checkbox" width="60"></vxe-column>
        <vxe-column type="seq" width="50" title="序号">
        </vxe-column>
        <vxe-column field="lockName" title="蓝牙设备初始名称"></vxe-column>
        <vxe-column field="sendRealName" title="发送人"></vxe-column>
        <vxe-column field="sendPhone" title="发送人手机" ></vxe-column>
        <vxe-column field="featureCode" title="设备标识"  ></vxe-column>
        <vxe-column field="phone" title="接收人手机"></vxe-column>
        <vxe-column field="roomNum" title="房间名称"></vxe-column>
        <vxe-column field="keyName" title="钥匙名称"></vxe-column>
        <vxe-column title="到期时间" >
            <template #default="{ row }">
              <span>{{row.expirationDate ? row.expirationDate : '无限期'}}</span>
            </template>
         </vxe-column>
        <vxe-column field="createTime" title="发送时间" ></vxe-column>
         <vxe-column title="操作">
            <template #default="{ row }">
              <el-button
              @click="delKeyFn(row)"
                  type="danger"
                  size="mini"
                  >删除钥匙</el-button>
            </template>
         </vxe-column>
      </vxe-table>
    </el-card>
    <base-pagination
      :total="total"
      :currentPage="queryInfo.pageNum"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></base-pagination>
  </div>
</template>

<script>
import { pageLockUser, delBatchLockKey } from '@/api/lock'
import { mapState } from 'vuex'
export default {
  computed: {
...mapState('login', ['userInfo'])
},
  data () {
    return {
      detailData: {
        isOpen: false,
        detail: ''
      },
      queryInfo: {
        pageNum: 1,
        pageSize: 10,
        keyword: ''
      },
      keyList: [],
      total: 0,
      loading: false
    }
  },
  created () {
    this.pageOrgaLockKeyFn()
  },
  methods: {
    // 批量删除门锁钥匙
    batchDelKeyFn () {
      const records = this.$refs.xTable.getCheckboxRecords()
      if (records.length === 0) {
          return this.$message({
              type: 'info',
              message: '请选择需要删除的钥匙'
            })
      }
      this.$confirm('是否确认批量删除钥匙', '操作提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const idList = []
          records.forEach(item => {
              idList.push(item.id)
          })
          const data = {
            lockUserIdList: idList
          }
          delBatchLockKey(data).then(res => {
            this.$message({
                type: 'success',
                message: '删除成功'
              })
              this.$refs.xTable.clearCheckboxRow()
              this.pageOrgaLockKeyFn()
          })
        })
        .catch(() => {
          this.$message({
            type: 'warning',
            message: '已取消'
          })
        })
    },
    // 删除门锁
    delKeyFn (row) {
      this.$confirm('是否确认删除该用户钥匙', '操作提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const data = {
            lockUserIdList: [row.id]
          }
          delBatchLockKey(data).then(res => {
            this.$message({
                type: 'success',
                message: '删除成功'
              })
              this.pageOrgaLockKeyFn()
          })
        })
        .catch(() => {
          this.$message({
            type: 'warning',
            message: '已取消'
          })
        })
    },
    // 获取钥匙列表
    pageOrgaLockKeyFn () {
      this.loading = true
      pageLockUser(this.queryInfo).then((res) => {
        this.loading = false
        this.keyList = res.data.list
        this.total = res.data.total
      })
    },
    // 查看详细信息
    seeDetail (val) {
       this.detailData.isOpen = true
      this.detailData.detail = val
    },
    // 审核方法
    auditFn () {},
    // 改变分页条目
    handleSizeChange (size) {
      this.queryInfo.pageNum = 1
      this.queryInfo.pageSize = size
      this.pageOrgaLockKeyFn()
    },
    // 改变页数
    handleCurrentChange (page) {
      this.queryInfo.pageNum = page
      this.pageOrgaLockKeyFn()
    },
    // 删除组织
    deleteUserHandle () {}
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  .search_line {
    display: flex;
    align-items: center;
  }
}

.row_render {
  padding: 20px 40px;
}
</style>
