<template>
  <div class="appAdBody">
    <!-- header -->
    <el-card>
      <el-row class="top_row" :gutter="20">
        <el-col :span="6">
          <el-input
            placeholder="请输入关键字搜索"
            class="input-with-select"
            clearable
            v-model="queryInfo.keyword"
            @clear="getOperatingManualPageFn()"
          >
            <el-button
            @click="getOperatingManualPageFn('search')"
              slot="append"
              icon="el-icon-search"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="8" >
          <el-button type="primary" size="mini" @click="openHandBookFn('')"
          >新增操作手册列
          </el-button
          >
        </el-col>
      </el-row>
      <el-row>
      </el-row>
    </el-card>
    <!-- body -->
    <el-card>
      <vxe-table
        resizable
        ref="lockTable"
        align="center"
        :data="manualList"
        :loading="loading"
      >
      <vxe-column type="checkbox" width="60"></vxe-column>
        <vxe-column type="seq" width="50" title="序号"></vxe-column>
        <vxe-column field="title" title="操作标题"></vxe-column>
        <vxe-column field="createTime" title="创建时间"></vxe-column>
        <vxe-column field="remarks" title="备注"></vxe-column>
        <vxe-column title="是否启用" width="150">
            <template #default="{ row }">
              <el-switch
              :value="row.isUsed"
              :active-value="1"
              :inactive-value="0"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="启用"
              inactive-text="禁用"
              @change="switchHBStatus(row)"
            >
            </el-switch>
            </template>
         </vxe-column>
         <vxe-column title="操作" width="300">
            <template #default="{ row }">
              <el-button
                  type="warning"
                  size="mini"
                  @click="openHandBookFn(row.id)"
                  >编辑</el-button
                >
                <el-button
                  type="danger"
                  size="mini"
                  @click="deleHandBookFn(row.id)"
                  >删除</el-button
                >
            </template>
         </vxe-column>
      </vxe-table>
    </el-card>
    <adOrEdHandBook :setData="addData" @success="getOperatingManualPageFn()"></adOrEdHandBook>
    <base-pagination
      :total="total"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></base-pagination>
  </div>
</template>

<script>
import { operatingManualPage, operatingManualSave, operatingManualDel } from '@/api/appClass'
import adOrEdHandBook from './dialog/adOrEdHandBook.vue'
export default {
  data () {
    return {
      loading: false,
      addData: {
        title: '',
        id: '',
        isOpen: false
      },
      queryInfo: {
        pageNum: 1,
        pageSize: 10,
        keyword: ''
      },
      manualList: [],
      total: 0
    }
  },
  components: {
    adOrEdHandBook
  },
  mounted () {
    this.getOperatingManualPageFn()
  },
  methods: {
    // 删除该APP操作手册
    deleHandBookFn (id) {
      this.$confirm('此操作将永久删除该操作手册列, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          operatingManualDel({ id: id }).then((res) => {
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            this.getOperatingManualPageFn('search')
          })
        })
        .catch(() => {
          this.$message({
            type: 'warning',
            message: '已取消删除'
          })
        })
    },
    // 打开弹窗
    openHandBookFn (id) {
      this.addData.id = id
      this.addData.isOpen = true
    },
    // 开启关闭手册列
    switchHBStatus (row) {
      this.$confirm(row.isUsed === 1 ? '是否禁用' : '是否启用', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const data = {
              id: row.id,
              isUsed: row.isUsed === 1 ? 0 : 1
            }
            operatingManualSave(data).then(res => {
              this.$message({
                  type: 'success',
                  message: '设置成功!'
                })
                this.getOperatingManualPageFn()
            })
        })
        .catch(() => {
          this.$message({
            type: 'warning',
            message: '已取消删除'
          })
        })
    },
    // 分页获取操作手册列
    getOperatingManualPageFn (type) {
      this.loading = true
      if (type) {
        this.queryInfo.pageNum = 1
        this.queryInfo.pageSize = 10
        this.total = 0
      }
      operatingManualPage(this.queryInfo).then((res) => {
        this.manualList = res.data.list
        this.total = res.data.total
        this.loading = false
      })
    },
    // 改变分页条目
    handleSizeChange (size) {
      this.queryInfo.pageSize = size
      this.getOperatingManualPageFn()
    },
    // 改变页数
    handleCurrentChange (page) {
      this.queryInfo.pageNum = page
      this.getOperatingManualPageFn()
    }
  }
}
</script>

<style lang="scss" scoped>
.top_row {
  display: flex;
  align-items: center;
}
</style>
