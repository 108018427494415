<template>
  <div class="page-container">
    <!-- header -->
    <el-card>
      <el-row class="top_row">
          <el-col :span="4" style="text-align:right;">
            <el-input
              placeholder="请输入手机号码"
              v-model="queryInfo.phone"
            >
          </el-input>
        </el-col>
        <el-col :span="1" ></el-col>
        <el-col :span="3" >
          <el-radio-group v-model="queryInfo.isCheckouted">
            <el-radio :label="0">未出库</el-radio>
            <el-radio :label="1">已出库</el-radio>
          </el-radio-group>
        </el-col>
        <el-col :span="6">
          <el-input
            placeholder="请输入设备名称/标识搜索"
            class="input-with-select"
            clearable
            v-model="queryInfo.keyword"
            @clear="getAllWifiListFn"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getAllWifiListFn"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="1" ></el-col>
        <!-- <el-col :span="3" >
            <el-button type="info" >下载导入模板</el-button>
        </el-col> -->
        <el-col :span="3" >
          <el-upload
            action=""
            :on-change="importFacility"
            :auto-upload="false"
            :show-file-list="false"
            class="uploadFile"
          >
            <el-button type="warning" >导入设备</el-button>
          </el-upload>
        </el-col>
        <el-col :span="3" >
            <el-button type="success" @click="batchGoOutData.isOpen = true">批量出库</el-button>
        </el-col>
      </el-row>
      <el-row>
      </el-row>
    </el-card>
    <!-- body -->
    <el-card>
      <vxe-table
        resizable
        ref="lockTable"
        align="center"
        :max-height="$wHight - 280"
        :data="lockInfoList"
        :loading="loading"
      >
      <vxe-column type="checkbox" width="60"></vxe-column>
        <vxe-column type="seq" width="50" title="序号"></vxe-column>
        <vxe-column field="deviceNo" width="150" title="设备号码"></vxe-column>
        <vxe-column field="iedName" width="150" title="设备名称"></vxe-column>
        <vxe-column field="iedModel" width="150" title="设备型号"></vxe-column>
        <vxe-column field="isCheckouted" width="150" title="是否已出库">
          <template #default="{ row }">
            <span>{{ row.isCheckouted == 1 ? '已出库' : '未出库' }}</span>
          </template>
        </vxe-column>
        <vxe-column field="accessNumber" width="150" title="接入号"></vxe-column>
        <vxe-column field="lockName" width="150" title="网络状态"></vxe-column>
        <vxe-column field="isOffNetAction" width="150" title="是否开启达量断网">
          <template #default="{ row }">
            <span>{{ row.isOffNetAction == 1 ? '开启' : '关闭' }}</span>
          </template>
        </vxe-column>
        <vxe-column field="iccid" width="150" title="iccid号"></vxe-column>
        <vxe-column field="imei" width="150" title="物联网终端IMEI号码"></vxe-column>
        <vxe-column field="imsi" width="150" title="IMSI号码"></vxe-column>
        <vxe-column field="isRealName" width="150" title="是否实名">
          <template #default="{ row }">
            <span>{{ row.isRealName == 1 ? '已实名' : '未实名' }}</span>
          </template>
        </vxe-column>
        <vxe-column field="sn" width="150" title="sn码"></vxe-column>
        <vxe-column field="mac" width="150" title="mac地址"></vxe-column>
        <vxe-column field="ssid" width="150" title="ssid"></vxe-column>
        <vxe-column field="remarks" width="150" title="备注"></vxe-column>
        <vxe-column field="createTime" width="150" title="创建时间"></vxe-column>
        <vxe-column title="二维码" width="150">
            <template #default="{ row }">
               <vue-qr :size="100" :text="row.deviceNo ? row.deviceNo : '123'"></vue-qr>
            </template>
         </vxe-column>
         <template v-if="userInfo.userType === 1">
            <vxe-column fixed="right" title="操作" width="300" >
                <template #default="{ row }">
                  <div style="line-height: 40px;">
                    <el-button
                      type="success"
                      size="mini"
                      @click="outFacility(row)"
                      >出库</el-button
                    >
                    <el-button
                      type="success"
                      size="mini"
                      @click="setSellFacilityMealFn(row)"
                      >设置设备套餐</el-button
                    >
                    <el-button
                      type="info"
                      size="mini"
                      @click="setQuotaFn(row)"
                      >设置达量断网</el-button
                    >
                    <el-button
                      type="danger"
                      size="mini"
                      @click="cancelCuttOff(row)"
                      >取消达量断网</el-button
                    >
                  </div>
                </template>
            </vxe-column>
         </template>
      </vxe-table>
    </el-card>
    <reDisconnect :setQuotaData="setQuotaData" @successHandle="successHandle"></reDisconnect>
    <batchGoOutDialog v-if="batchGoOutData.isOpen" :batchGoOutData="batchGoOutData"></batchGoOutDialog>
    <setMealDialog :mealData="mealData"></setMealDialog>

    <!-- 简易出库弹窗 -->
    <el-dialog
      title="出库操作"
      :destroy-on-close="true"
      :visible.sync="outShow"
      width="30%"
      >
      <div style="line-height: 40px;">
        <div>
          接收人号码：
          <el-input style="width:300px;" v-model="outPhone" placeholder="请输入接收人号码"></el-input>
        </div>
      <div>出库设备：{{ onDeviceNo }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="outShow = false">取 消</el-button>
        <el-button type="primary" @click="okOutFacility">确 定</el-button>
      </span>
    </el-dialog>
    <base-pagination
      :total="total"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></base-pagination>
  </div>
</template>

<script>
import { getAllWifiList, cancelDisconnectQuota, checkoutBatchForFirst } from '@/api/wifi'
import reDisconnect from './dialog/reDisconnect.vue'
import batchGoOutDialog from './dialog/batchGoOutDialog.vue'
import setMealDialog from './dialog/setMealDialog.vue'
import vueQr from 'vue-qr'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      onDeviceNo: '',
      outPhone: '',
      outShow: false,
      loading: false,
      mealData: {
        isOpen: false,
        deviceNo: ''
      },
      setQuotaData: {
        isOpen: false,
        quota: '',
        deviceNo: ''
      },
      batchGoOutData: {
        isOpen: false
      },
      queryInfo: {
        pageNum: 1,
        pageSize: 10,
        keyword: '',
        phone: '',
        isCheckouted: 0
      },
      lockInfoList: [],
      total: 0
    }
  },
  computed: {
    ...mapState('login', {
      userInfo: (state) => state.userInfo
    })
  },
  components: {
    reDisconnect,
    batchGoOutDialog,
    setMealDialog,
    vueQr
  },
  mounted () {
    this.resetFn()
  },
  methods: {
    // 打开设置套餐弹窗
    setSellFacilityMealFn (val) {
      this.mealData.isOpen = true
      this.mealData.deviceNo = val.deviceNo
    },
    // 打开出库弹窗
    outFacility (row) {
      this.outPhone = ''
      this.onDeviceNo = row.deviceNo
      this.outShow = true
    },
    // 确定出库
    okOutFacility () {
      const data = {
        phone: this.outPhone,
        deviceNoList: [this.onDeviceNo]
      }
      checkoutBatchForFirst(data).then(() => {
        this.$message({
            type: 'success',
            message: '出库成功'
          })
          this.outShow = false
          this.getAllWifiListFn()
      })
    },
    // 取消达量断网
    cancelCuttOff (row) {
      this.$confirm('此操作将解除该设备的达量断网, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          cancelDisconnectQuota({ deviceNo: row.deviceNo }).then(() => {
            this.$message({
                type: 'success',
                message: '取消成功'
              })
              this.getAllWifiListFn()
          })
        })
    },
    // 设置设备的套餐
    setMealFn () {
    },
    // 导入设备列表
    importFacility (file) {
      this.loading = true
      const data = new FormData()
      data.append('file', file.raw)
      this.$http.importExcelHttp.importWifiDevice(data).then((res) => {
        this.loading = false
        if (res.data.success) {
          this.$message({
            type: 'success',
            message: res.data.msg || '导入成功'
          })
          this.getAllWifiListFn()
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg || '导入失败，请重新导入'
          })
        }
      })
    },
    // 打开设备的达量断网弹窗
    setQuotaFn (row) {
      this.setQuotaData.isOpen = true
      this.setQuotaData.deviceNo = row.deviceNo
    },
    // 获取门锁设备列表
    getAllWifiListFn () {
      this.loading = true
      getAllWifiList(this.queryInfo).then((res) => {
        this.loading = false
        this.lockInfoList = res.data.list
        this.total = res.data.total
      })
    },
    // 改变分页条目
    handleSizeChange (size) {
      this.queryInfo.pageSize = size
      this.getAllWifiListFn()
    },
    // 改变页数
    handleCurrentChange (page) {
      this.queryInfo.pageNum = page
      this.getAllWifiListFn()
    },
    // 选中所有
    selectAllEvent ({ checked }) {
      const records = this.$refs.lockTable.getCheckboxRecords()
      console.log(checked ? '所有勾选事件' : '所有取消事件', records)
    },
    // 监听成功添加与编辑
    successHandle () {
      this.$refs.lockTable.clearCheckboxRow()
      this.getAllWifiListFn()
    },
    // 重置搜索设备
    resetFn () {
      this.queryInfo = {
        pageNum: 1,
        pageSize: 10,
        keyword: '',
        phone: ''
      }
      this.getAllWifiListFn()
    }
  }
}
</script>

<style lang="scss" scoped>
.top_row {
  display: flex;
  align-items: center;
}
</style>
