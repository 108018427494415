<template>
  <div class="page-container">
    <!-- header -->
    <el-card>
      <el-row>
        <el-col :span="6">
          <el-input
            placeholder="请输入关键字搜索"
            class="input-with-select"
            clearable
            v-model="queryInfo.keyword"
            @clear="getUserList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="searchFn"
            ></el-button>
          </el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" style="text-align: right">
          <el-button type="primary" @click="createUser"
            >添加管理员</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <!-- body -->
    <el-card>
      <el-table :data="userList" style="width: 100%" border stripe>
        <el-table-column type="index" label="ID" width="50"> </el-table-column>
        <el-table-column prop="username" label="用户名" width="150">
        </el-table-column>
        <el-table-column prop="phone" label="手机号码" width="150">
        </el-table-column>
        <el-table-column prop="roles" label="角色" min-width="300">
          <template v-slot="scoped">
            <el-tag
              style="marginleft: 10px"
              type="info"
              v-for="(item, index) in scoped.row.roleNameList"
              :key="index"
              >{{ item }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="remarks" label="备注" width="150">
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="200">
        </el-table-column>
        <el-table-column label="操作" width="240" fixed="right">
          <template v-slot="scoped">
            <el-button
              type="primary"
              size="mini"
              @click="openSetRole(scoped.row.id)"
              >分配角色</el-button
            >
            <el-button
              type="warning"
              size="mini"
              @click="createUser(scoped.row.id)"
              >编辑</el-button
            >
            <el-button
              type="danger"
              size="mini"
              @click="deleteUserHandle(scoped.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <base-pagination
      :total="total"
      :currentPage="queryInfo.pageNum"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></base-pagination>
    <add-user :addData="addData" @successHandle="successHandle"></add-user>
    <set-role :setData="setData" @successHandle="successHandle"></set-role>
  </div>
</template>

<script>
import { getUserList, deleteUserById } from '@/api/system'
import addUser from './dialog/addUser.vue'
import setRole from './dialog/setRole.vue'

export default {
  components: {
    addUser,
    setRole
  },
  data () {
    return {
      addData: {
        isOpen: false
      },
      setData: {
        isOpen: false,
        id: ''
      },
      queryInfo: {
        pageNum: 1,
        pageSize: 10,
        keyword: ''
      },
      userList: [],
      total: 0
    }
  },
  created () {
    this.getUserList()
  },
  methods: {
    // 搜索
    searchFn () {
      this.queryInfo.pageNum = 1
      this.queryInfo.pageSize = 10
      this.getUserList()
    },
    // 获取用户列表
    getUserList () {
      getUserList(this.queryInfo).then((res) => {
        this.userList = res.data.list
        this.total = res.data.total
      })
    },
    // 添加与编辑用户
    createUser (id) {
      this.addData.isOpen = true
      if (id.length) {
        this.addData.id = id
        this.addData.title = '编辑用户'
      } else {
        this.addData.id = ''
        this.addData.title = '添加用户'
      }
    },
    // 打开分配角色
    openSetRole (id) {
      this.setData = {
        isOpen: true,
        id
      }
    },
    // 改变分页条目
    handleSizeChange (size) {
      this.queryInfo.pageNum = 1
      this.queryInfo.pageSize = size
      this.getUserList()
    },
    // 改变页数
    handleCurrentChange (page) {
      this.queryInfo.pageNum = page
      this.getUserList()
    },
    // 删除用户
    deleteUserHandle (id) {
      this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteUserById(id).then((res) => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getUserList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'warning',
            message: '已取消删除'
          })
        })
    },
    // 监听成功添加与编辑
    successHandle () {
      this.getUserList()
    }
  }
}
</script>

<style lang="scss" scoped></style>
