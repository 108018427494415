import Vue from 'vue'
import { exportExcelHttp, importExcelHttp } from './excel'

/**
 * 全局挂载请求
 * {description} 一些共用请求建议全局挂载
 */
Vue.prototype.$http = {
  exportExcelHttp, // 导出excel
  importExcelHttp // 导入excel
}
