<template>
  <div class="page-container">
    <!-- header -->
    <el-card>
      <el-row>
        <el-col :span="24" style="text-align: right;">
          <el-button type="primary" @click="addData.isOpen = true">添加菜单</el-button>
        </el-col>
      </el-row>
    </el-card>
    <!-- body -->
    <el-card>
        <el-table
          :data="menuList"
          style="width: 100%"
          border
          stripe
          row-key="id"
          :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        >
          <el-table-column type="index" label="ID" width="50">
          </el-table-column>
          <el-table-column prop="title" label="菜单名称"> </el-table-column>
          <el-table-column prop="queType" label="菜单类型" width="80">
            <template v-slot="scoped">
              <span>{{ scoped.row.menuType === 1? "菜单" : "权限" }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="para" label="权限标识"> </el-table-column>
          <el-table-column prop="path" label="前端路径"> </el-table-column>
          <el-table-column prop="icon" label="前端图标"> </el-table-column>
          <el-table-column prop="sort" label="排序" width="80"> </el-table-column>
          <el-table-column label="操作" width="240">
            <template v-slot="scoped">
              <el-button v-if="scoped.row.menuType == 1" type="primary" size="small" @click="openAddMenu(scoped.row)">添加子级</el-button>
              <el-button type="warning" size="small" @click="openEditMenu(scoped.row.id)">编辑</el-button>
              <el-button type="danger" size="small" @click="deleteMenuHandle(scoped.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    <add-menu :addData="addData" @click="openAddMenu" @successHandle="successHandle"></add-menu>
    <edit-menu :editData="editData" @successHandle="successHandle"></edit-menu>
  </div>
</template>

<script>
import { getMenuList, deleteMenuById } from '@/api/system'
import addMenu from './dialog/addMenu.vue'
import editMenu from './dialog/editMenu.vue'

export default {
  components: {
    addMenu,
    editMenu
  },
  data () {
    return {
      total: 0,
      menuList: [],
      addData: {
        isOpen: false,
        parentId: '',
        title: ''
      },
      editData: {
        isOpen: false,
        id: ''
      }
    }
  },
  created () {
    this.getMenuList()
  },
  methods: {
    // 获取菜单列表
    getMenuList () {
      getMenuList().then((res) => {
        if (res.code === 200) {
          this.menuList = res.data
        }
      })
    },
    // 打开添加菜单
    openAddMenu (parent) {
      this.addData.isOpen = true
      this.addData.parentId = parent ? parent.id : ''
      this.addData.title = parent ? parent.title : ''
    },
    // 打开编辑菜单
    openEditMenu (id) {
      this.editData = {
        isOpen: true,
        id
      }
    },
    // 监听添加编辑成功
    successHandle () {
      this.getMenuList()
    },
    // 删除菜单
    deleteMenuHandle (id) {
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteMenuById(id).then(res => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.getMenuList()
        })
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: '已取消删除'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
