
import { exportExcel, importExcel } from '@/service/excelHttp.js'
const host = process.env.VUE_APP_API_URL

/* 导出excel */
const exportExcelHttp = {
    // 房间用户导入模板
    downloadRoomUser: (params) => {
    return exportExcel(host, '/orga/templateDownload/downloadRoomUser', 'post', params, '房间用户导入模板')
  },
  downloadBuildApartment: (params) => {
    return exportExcel(host, '/orga/templateDownload/downloadBuildApartment', 'post', params, '楼房导入模板')
  }
}

/* 导入excel */

const importExcelHttp = {
  // 批量导入房间用户
  importLockKeyUser: (params) => {
        return importExcel(host, '/lock/lockUser/importLockKeyUser', 'post', params)
  },
    // 批量导入楼栋房间用户
  importBuildApartment: (params) => {
      return importExcel(host, '/orga/orgaBuild/importBuildApartment', 'post', params)
  },
    // 批量导入wifi设备
    importWifiDevice: (params) => {
      return importExcel(host, '/wifi/device/importWifiDevice', 'post', params)
  }
}

export { exportExcelHttp, importExcelHttp }
