import { login, getUserInfo } from '@/api/login'
import { Message } from 'element-ui'
import router from '@/router/index'

const state = {
  token: window.localStorage.getItem('token'),
  roles: [],
  menukeys: [],
  userInfo: {}
}

const mutations = {
  /* 添加token */
  addToken (state, token) {
    state.token = token
  },
  /* 添加角色 */
  addRole (state, userRole) {
    state.roles = userRole
  },
  /* 添加权限 */
  addMenukeys (state, menukeys) {
    state.menukeys = menukeys
  },
  /* 添加个人信息 */
  addUserInfo (state, userInfo) {
    state.userInfo = userInfo
  },
  /* 移除token */
  removeToken (state) {
    window.localStorage.clear()
    state.roles = []
    location.reload()
  }
}

const actions = {
  /* 用户登录 */
  login ({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      login(userInfo).then(res => {
        commit('addToken', res.data)
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  /* 获取用户个人信息 */
  getUserInfo ({ commit }) {
    return new Promise((resolve, reject) => {
      getUserInfo().then(res => {
        const roles = res.data.roleNameList
        const menukeys = res.data.menukeys
        if (roles.length === 0) {
          window.localStorage.clear()
          router.push('/login')
          Message.error({ message: '请分配角色' })
          return false
        }
        commit('addRole', roles)
        commit('addMenukeys', menukeys)
        commit('addUserInfo', res.data)
        resolve(roles)
      }).catch(err => {
        reject(err)
      })
    })
  },
  /* 退出登录 */
  logout ({ commit }) {
    commit('removeToken')
  }
}

const getters = {
  /* 获取token */
  getToken (state) {
    return state.token
  },
  /* 获取角色 */
  getRoles (state) {
    return state.roles
  },
   /* 获取角色权限标识 */
   getMenukeys (state) {
    return state.menukeys
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
