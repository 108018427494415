<template>
  <div class="page-container">
    <!-- header -->
    <el-card>
      <el-row class="top_row">
        <el-col :span="2" >
            <el-switch
              v-model="queryInfo.isNoLock"
              inactive-text="暂未绑锁房间"
              @change="getOrgaApartmentPage">
            </el-switch>
        </el-col>
        <el-col :span="4" style="text-align: center" v-if="userInfo.userType === 1">
          <el-select clearable v-model="queryInfo.orgaId" placeholder="请选择组织"  @clear="clearOrga" @change="getbuildList">
            <el-option
              v-for="item in orgaInfoList"
              :key="item.id"
              :label="item.orgaName"
              :value="item.id">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4" style="text-align: center" >
            <el-select clearable v-model="queryInfo.buildId" placeholder="请选择楼栋"  @clear="getOrgaApartmentPage" @change="getOrgaApartmentPage">
              <el-option
                v-for="item in buildList"
                :key="item.id"
                :label="item.buildName"
                :value="item.id">
              </el-option>
            </el-select>
        </el-col>
        <el-col :span="4">
          <el-input
            placeholder="请输入关键字搜索"
            class="input-with-select"
            clearable
            v-model="queryInfo.keyword"
            @clear="getOrgaApartmentPage"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getOrgaApartmentPage"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="2" style="text-align: right">
          <el-button type="success" @click="batchSenndKey"
            >批量发送锁匙</el-button
          >
        </el-col>
        <el-col :span="2" style="text-align: center">
          <el-button type="primary" @click="createApartment"
            >添加房间</el-button
          >
        </el-col>
        <el-col :span="3">
          <el-button type="success"  @click="$http.exportExcelHttp.downloadRoomUser()">下载导入房间用户模板</el-button>
        </el-col>
        <el-col :span="3" style="text-align: center">
          <el-upload
            action=""
            :on-change="importHouse"
            :auto-upload="false"
            :show-file-list="false"
            class="uploadFile"
          >
            <el-button type="warning" >exe批量导入房间用户</el-button>
          </el-upload>
        </el-col>
      </el-row>
    </el-card>
    <!-- body -->
    <el-card>
      <vxe-table
        resizable
        ref="buildTable"
        align="center"
        :max-height="$wHight - 280"
        :data="orgaApartmentList"
        :loading="loading">
        <vxe-column type="checkbox" width="60"></vxe-column>
          <vxe-column type="seq" width="50" title="序号">
          </vxe-column>
         <vxe-column field="roomNum" title="楼层房间号"></vxe-column>
         <vxe-column field="orgaName" title="组织名称"></vxe-column>
         <vxe-column field="featureCode"  width="150" title="锁标识"></vxe-column>
         <vxe-column field="remarks" title="备注"></vxe-column>
         <vxe-column field="createTime" title="创建时间"></vxe-column>
         <vxe-column title="操作"  width="400">
            <template #default="{ row }">
              <el-button
              v-if="row.featureCode"
              type="success"
              size="mini"
              @click="senndKey(row)"
              >发送锁匙</el-button
            >
                <el-button
              type="warning"
              size="mini"
              @click="createApartment(row.id)"
              >编辑</el-button
            >
            <el-button
              type="danger"
              size="mini"
              @click="deleteUserHandle(row.id)"
              >删除</el-button
            >
            </template>
         </vxe-column>
        </vxe-table>
    </el-card>
    <base-pagination
      :total="total"
      :currentPage="queryInfo.pageNum"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></base-pagination>
    <add-orga-apartment :addData="addData" @successHandle="successHandle"></add-orga-apartment>
    <edit-orga-apartment :editData="editData" @successHandle="successHandle"></edit-orga-apartment>
    <send-key-dialog :editData="sendKeyData" @successHandle="successHandle2"></send-key-dialog>
  </div>
</template>

<script>
import { getOrgaApartmentPage, delOrgaApartment, getOrgaInfoListAll, getAllOrgaBuildByOrgaId } from '@/api/orga'
import addOrgaApartment from './dialog/addOrgaApartment.vue'
import editOrgaApartment from './dialog/editOrgaApartment.vue'
import sendKeyDialog from './dialog/sendKeyDialog.vue'
import { mapState } from 'vuex'

export default {
  components: {
    addOrgaApartment,
    editOrgaApartment,
    sendKeyDialog
  },
  computed: {
    ...mapState('login', {
      userInfo: (state) => state.userInfo
    })
  },
  data () {
    return {
      loading: false,
      sendKeyData: {
        isOpen: false,
        lockIdList: []
      },
      addData: {
        isOpen: false
      },
      editData: {
        isOpen: false,
        id: ''
      },
      queryInfo: {
        orgaId: '',
        buildId: '',
        pageNum: 1,
        pageSize: 10,
        keyword: '',
        isNoLock: false
      },
      orgaInfoList: [],
      orgaApartmentList: [],
      buildList: [],
      total: 0
    }
  },
  created () {
    if (this.userInfo.userType === 1) {
      this.getOrgaInfoListAllFn()
    } else {
      this.queryInfo.orgaId = this.userInfo.orgaId
      this.getbuildList()
    }
    this.getOrgaApartmentPage()
  },
  methods: {
    // 导入房间用户
    importHouse (file) {
      this.loading = true
      const data = new FormData()
      data.append('file', file.raw)
      this.$http.importExcelHttp.importLockKeyUser(data).then((res) => {
        this.loading = false
        if (res.data.success) {
          this.$message({
            type: 'success',
            message: res.data.msg || '导入成功'
          })
          this.getOrgaApartmentPage()
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg || '导入失败，请重新导入'
          })
        }
      })
    },
    // 批量发送锁匙
    batchSenndKey () {
      const records = this.$refs.buildTable.getCheckboxRecords()
      if (records.length === 0) {
          return this.$message({
              type: 'info',
              message: '请选择需要发送锁匙的房间'
            })
      }
      const idList = []
     records.forEach(item => {
        idList.push(item.lockId)
     })
     console.log(idList)
      this.sendKeyData.isOpen = true
      this.sendKeyData.lockIdList = idList
    },
    // 打开发送锁匙
    senndKey (row) {
      this.sendKeyData.isOpen = true
      this.sendKeyData.lockIdList = [row.lockId]
    },
    // 清空组织时
    clearOrga () {
      this.queryInfo.buildId = ''
      this.buildList = []
      this.queryInfo.pageNum = 1
      this.getOrgaApartmentPage()
    },
    // 获取楼栋房间列表
    getOrgaApartmentPage () {
      this.loading = true
      getOrgaApartmentPage(this.queryInfo).then((res) => {
        this.orgaApartmentList = res.data.list
        this.total = res.data.total
        this.loading = false
      })
    },
    // 获取所有组织
    getOrgaInfoListAllFn () {
      getOrgaInfoListAll().then(res => {
        console.log('获取到的组织', res.data)
        this.orgaInfoList = res.data
      })
    },
    // 根据组织获取楼栋树
    getbuildList () {
      this.queryInfo.buildId = ''
      if (!this.queryInfo.orgaId) { return }
      getAllOrgaBuildByOrgaId(this.queryInfo.orgaId).then(res => {
        this.buildList = res.data
      })
      this.getOrgaApartmentPage()
    },
    // 添加与编辑用户
    createApartment (id) {
      if (id.length) {
        this.editData.isOpen = true
        this.editData.id = id
      } else {
        this.addData.isOpen = true
      }
    },
    // 改变分页条目
    handleSizeChange (size) {
      this.queryInfo.pageNum = 1
      this.queryInfo.pageSize = size
      this.getOrgaApartmentPage()
    },
    // 改变页数
    handleCurrentChange (page) {
      this.queryInfo.pageNum = page
      this.getOrgaApartmentPage()
    },
    // 删除房间
    deleteUserHandle (id) {
      this.$confirm('此操作将永久删除该楼栋, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delOrgaApartment(id).then((res) => {
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            this.getOrgaApartmentPage()
          })
        })
        .catch(() => {
          this.$message({
            type: 'warning',
            message: '已取消删除'
          })
        })
    },
    // 成功发送后
    successHandle2 () {
      this.getOrgaApartmentPage()
    },
    // 监听成功添加与编辑
    successHandle () {
      this.$refs.buildTable.clearCheckboxRow()
      this.getOrgaApartmentPage()
    }
  }
}
</script>

<style lang="scss" scoped>
  .top_row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
</style>
