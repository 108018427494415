<template>
  <div class="version-container">
    <!-- header -->
    <el-card>
      <el-row class="header-search-bar">
        <el-col :span="6">
          <el-input
            placeholder="请输入版本号"
            class="input-with-select"
            clearable
            v-model="queryInfo.keyword"
            @clear="getAppVersionList('search')"
            @keyup.enter.native="getAppVersionList('search')"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getAppVersionList('search')"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="2" style="text-align: center">
          <el-button type="primary" @click="createAppVersion('create')">
            新增版本
          </el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col class="header-button" :span="24">
        </el-col>
      </el-row>
    </el-card>
    <!-- body -->
    <el-card>
      <el-table :data="appVersionList" style="width: 100%" border stripe>
        <el-table-column type="index" label="ID" width="50" >
        </el-table-column>
        <el-table-column prop="appName" label="应用名称"  width="120"></el-table-column>
        <el-table-column label="类型" width="78">
          <template v-slot="scoped">
            <span>{{ scoped.row.type === 0 ? 'Android' : 'IOS' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="version" label="版本" width="80">
          <template v-slot="scoped">
            <el-tag v-if="scoped.row.status" type="success">{{ scoped.row.version }}</el-tag>
            <el-tag v-else type="warning">{{ scoped.row.version }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="note" label="更新日志" width="300"></el-table-column>
        <el-table-column prop="remarks" label="备注" width="200"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="160">
        </el-table-column>
        <el-table-column prop="url" label="下载路径" min-width="330">
        </el-table-column>
        <el-table-column label="状态" fixed="right" width="140">
          <template v-slot="scoped">
            <el-switch
              v-model="scoped.row.status"
              @change="switchAppStatus($event, scoped.row)"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="启用"
              inactive-text="禁用"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150" fixed="right">
          <template v-slot="scoped">
            <el-button
              type="warning"
              size="mini"
              @click="createAppVersion('edit', scoped.row)"
            >编辑
            </el-button
            >
            <el-button
              type="danger"
              size="mini"
              @click="deleteAppVersionHandle(scoped.row.id)"
            >删除
            </el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <base-pagination
      :total="total"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></base-pagination>
    <dialog-version :addData="addData" @successHandle="successHandle"></dialog-version>
  </div>
</template>

<script>
import dialogVersion from './dialog/dialogVersion.vue'
import { getVersionList, deleteVersion, addVersion } from '@/api/version'

export default {
  components: {
    dialogVersion
  },
  data () {
    return {
      addData: {
        id: '',
        title: '',
        type: '',
        isOpen: false
      },
      queryInfo: {
        pageNum: 1,
        pageSize: 10,
        keyword: '',
        grade: null,
        collegeId: null,
        majorId: null,
        campusId: null
      },
      appVersionList: [],
      total: 0
    }
  },
  created () {
    this.getAppVersionList()
  },
  methods: {
    // 头部下拉事件
    selectSearchHandle (data) {
      this.queryInfo.grade = data.grade
      this.queryInfo.collegeId = data.collegeId
      this.queryInfo.majorId = data.majorId
      this.queryInfo.campusId = data.campusId
      this.getAppVersionList()
    },
    // 添加与编辑版本
    createAppVersion (type, item) {
      this.addData.isOpen = true
      this.addData.type = type
      if (type === 'create') {
        this.addData.id = ''
        this.addData.title = '添加应用版本'
      } else {
        this.addData.id = item.id
        this.addData.title = '编辑应用版本'
      }
    },
    // 改变分页条目
    handleSizeChange (size) {
      this.queryInfo.pageSize = size
      this.getAppVersionList()
    },
    // 改变页数
    handleCurrentChange (page) {
      this.queryInfo.pageNum = page
      this.getAppVersionList()
    },
    // 删除应用版本
    deleteAppVersionHandle (id) {
      this.$confirm('此操作将永久删除该版本, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteVersion(id).then((res) => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getAppVersionList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'warning',
            message: '已取消删除'
          })
        })
    },
    // 关闭绑定弹窗
    closeBindDialog () {
      this.bindData.isOpen = false
    },
    // 监听成功添加与编辑
    successHandle () {
      this.getAppVersionList()
    },
    // page
    getAppVersionList () {
      getVersionList(this.queryInfo).then((res) => {
        this.appVersionList = res.data.list
        this.total = res.data.total
      })
    },
    // 更新状态
    switchAppStatus (event, row) {
      addVersion(row).then(res => {
        this.getAppVersionList()
      }).catch(e => {
        this.getAppVersionList()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .version-container{
    /deep/.el-table__fixed-right {
    height: 100% !important;
    }
  }
</style>
