<template>
  <div class="addOrgaInfo-coantainer">
    <base-dialog
      :model="editManagerData.isOpen"
      title="编辑管理员"
      @close="handleClose"
    >
      <template #body>
        <el-form ref="managerForm" :model="formData" :rules="rules" label-width="150px">
          <el-form-item label="管理员账号" label-width="120px" prop="username">
            <el-input  v-model.trim="formData.username" ></el-input>
          </el-form-item>
          <el-form-item label="管理员真实姓名" label-width="120px" prop="realName">
            <el-input  v-model.trim="formData.realName" ></el-input>
          </el-form-item>
          <el-form-item label="管理员手机号" label-width="120px" prop="phone">
            <el-input  v-model.trim="formData.phone" ></el-input>
          </el-form-item>
          <el-form-item label="管理楼栋" label-width="120px" prop="buildIdList" v-if="formData.parentId !== '0'">
             <el-select
                v-model="formData.buildIdList"
                multiple
                placeholder="请选择绑定楼栋">
                <el-option
                  v-for="item in towerList"
                  :key="item.id"
                  :label="item.buildName"
                  :value="item.id">
                </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="备注" label-width="120px" prop="remarks">
            <el-input v-model.trim="formData.remarks"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="saveOrgaManagerFn">确 定</el-button>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import { saveOrgaManager, getManagerUserId, getAllOrgaBuildByOrgaId } from '@/api/orga'

export default {
  props: {
    editManagerData: {
      type: Object
    }
  },
  data () {
    return {
      towerList: [],
      orgaId: '',
      formData: {
        id: '',
        parentId: '',
        buildIdList: [],
        username: '',
        realName: '',
        remarks: '',
        phone: ''
      },
      orgaInfoTreeList: [],
      rules: {
        username: [
          { required: true, message: '管理员账号', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '手机号码不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    'editManagerData.isOpen': function () {
      this.getOrgaTower()
      this.getManagerUserById()
    }
  },
  methods: {
    // 根据id获取管理员信息
    getManagerUserById () {
      getManagerUserId(this.editManagerData.id).then(res => {
       Object.keys(this.formData).forEach((item) => {
          this.formData[item] = res.data[item]
        })
      })
    },
    /* 添加/编辑管理员 */
    saveOrgaManagerFn () {
      this.$refs.managerForm.validate((valid) => {
        console.log('valid', valid)
        if (valid) {
           const data = JSON.parse(JSON.stringify(this.formData))
          data.orgaId = this.editManagerData.orgaId
          saveOrgaManager(data).then(res => {
            this.$refs.managerForm.resetFields()
            this.$message({
              message: '编辑成功',
              type: 'success'
            })
            this.$emit('successHandle')
            this.handleClose()
          })
        }
      })
    },
   // 获取组织楼栋
    getOrgaTower () {
      getAllOrgaBuildByOrgaId(this.editManagerData.orgaId).then((res) => {
        this.towerList = res.data
      })
    },
    /* 关闭弹窗 */
    handleClose () {
      this.editManagerData.isOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
