import { render, staticRenderFns } from "./dialogVersion.vue?vue&type=template&id=64f79d65&scoped=true&"
import script from "./dialogVersion.vue?vue&type=script&lang=js&"
export * from "./dialogVersion.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64f79d65",
  null
  
)

export default component.exports