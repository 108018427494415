<template>
  <div class="addOrgaBuild-coantainer">
    <base-dialog
      :model="detailData.isOpen"
      title="申请详情"
      @close="handleClose"
    >
      <template #body>
        <el-row>
          <el-col :span="12">
            <div>申请人电话：{{detailData.detail.phone}}</div>
          </el-col>
          <el-col :span="12">
            <div>申请组织名称：{{detailData.detail.orgaName}}</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div>申请地址：{{detailData.detail.address}}</div>
          </el-col>
        </el-row>
         <el-row>
            <el-col :span="24">
              <div>审核资料：</div>
            </el-col>
            <el-row>
                <el-col :span="8" class="imgList">
                  <img style="width: 100%;height: 100%;" :src="$fileUrl+detailData.detail.idCardBack" alt="">
                </el-col>
                <el-col :span="8" class="imgList">
                  <img style="width: 100%;height: 100%;" :src="$fileUrl + detailData.detail.idCardFront" alt="">
                </el-col>
                <el-col :span="8" class="imgList" v-if="detailData.detail.scenePictureList">
                  <img style="width: 100%;height: 100%;" :src="$fileUrl + detailData.detail.scenePictureList[0]" alt="">
                </el-col>
            </el-row>
        </el-row>
      </template>
    </base-dialog>
  </div>
</template>

<script>

export default {
  props: {
    detailData: {
      type: Object
    }
  },
  data () {
    return {
    }
  },
  methods: {
    /* 关闭弹窗 */
    handleClose () {
      this.detailData.isOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>
.addOrgaBuild-coantainer {
  .el-row {
      margin-bottom: 20px;
  }
  .imgList{
    padding: 10px;
    img {
      width: 150px;
      height: 150px;
    }
  }
}
</style>
