<template>
  <div class="page-container">
    <!-- header -->
    <el-card>
      <el-row class="search_line">
        <el-col :span="6">
          <el-input
            placeholder="请输入房间名称"
            class="input-with-select"
            clearable
            v-model="queryInfo.lockPlace"
            @clear="pageOrgaLockKeyFn"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="pageOrgaLockKeyFn"
            ></el-button>
          </el-input>
        </el-col>
         <el-col :span="1"></el-col>
         <el-col :span="6">
          <el-input
            placeholder="请输入真实姓名"
            class="input-with-select"
            clearable
            v-model="queryInfo.keyword"
            @clear="pageOrgaLockKeyFn"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="pageOrgaLockKeyFn"
            ></el-button>
          </el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" style="text-align: right">
        </el-col>
      </el-row>
    </el-card>
    <!-- body -->
    <el-card>
     <vxe-table
        resizable
        ref="xTable"
        align="center"
        :data="keyList"
        :loading="loading"
      >
      <vxe-column type="checkbox" width="60"></vxe-column>
        <vxe-column type="seq" width="50" title="序号">
        </vxe-column>
        <vxe-column field="lockPlace" title="房间名称"></vxe-column>
        <vxe-column field="lockOwner" title="所属组织(锁)"></vxe-column>
        <vxe-column field="unlockWayDetail" title="开锁方式"></vxe-column>
        <vxe-column field="realName" title="真实姓名"></vxe-column>
        <vxe-column field="createTime" title="开锁时间"> </vxe-column>
      </vxe-table>
    </el-card>
    <base-pagination
      :total="total"
      :currentPage="queryInfo.pageNum"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></base-pagination>
  </div>
</template>

<script>
import { unlockLogPage } from '@/api/lock'
import { mapState } from 'vuex'
export default {
  computed: {
...mapState('login', ['userInfo'])
},
  data () {
    return {
      detailData: {
        isOpen: false,
        detail: ''
      },
      queryInfo: {
        pageNum: 1,
        pageSize: 10,
        lockPlace: '',
        keyword: ''
      },
      keyList: [],
      total: 0,
      loading: false
    }
  },
  created () {
    this.pageOrgaLockKeyFn()
  },
  methods: {
    // 获取钥匙列表
    pageOrgaLockKeyFn () {
      this.loading = true
      unlockLogPage(this.queryInfo).then((res) => {
        this.loading = false
        this.keyList = res.data.list
        this.total = res.data.total
      })
    },
    // 改变分页条目
    handleSizeChange (size) {
      this.queryInfo.pageNum = 1
      this.queryInfo.pageSize = size
      this.pageOrgaLockKeyFn()
    },
    // 改变页数
    handleCurrentChange (page) {
      this.queryInfo.pageNum = page
      this.pageOrgaLockKeyFn()
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  .search_line {
    display: flex;
    align-items: center;
  }
}

.row_render {
  padding: 20px 40px;
}
</style>
