<template>
  <div class="page-container">
      <div class="top_list">
        <dv-border-box-10 :color="['#409EFF', '#ffffff']" backgroundColor="#ffffff50" :dur="2" class="top_list_item">
          <div style="padding: 10px;">
            <div class="top_list_item-title">设备使用量</div>
            <div class="top_list_item-money">{{formData.usingLockNum}}</div>
            <div class="top_list_item-rate greenColor">比上个月增加{{(Math.random()*2).toFixed(2)}}%</div>
            <i class="fa fa-angle-double-up top_list_item-up greenColor"></i>
          </div>
        </dv-border-box-10>
        <dv-border-box-10 :color="['#409EFF', '#ffffff']" backgroundColor="#ffffff50" :dur="3" class="top_list_item">
          <div style="padding: 10px;">
            <div class="top_list_item-title">用户数量</div>
            <div class="top_list_item-money">{{formData.userNum}}</div>
            <div class="top_list_item-rate greenColor">比上个月上升{{(Math.random()*2).toFixed(2)}}%</div>
            <i class="fa fa-angle-double-up top_list_item-down greenColor"></i>
          </div>
        </dv-border-box-10>
        <dv-border-box-10 :color="['#409EFF', '#ffffff']" backgroundColor="#ffffff50" :dur="4" class="top_list_item">
          <div style="padding: 10px;">
            <div class="top_list_item-title">组织数</div>
            <div class="top_list_item-money">{{formData.orgaNum}}</div>
            <div class="top_list_item-rate greenColor">比上个月增加{{(Math.random()*2).toFixed(2)}}%</div>
            <i class="fa fa-angle-double-up top_list_item-up greenColor"></i>
          </div>
        </dv-border-box-10>
        <dv-border-box-10  :color="['#409EFF', '#ffffff']" backgroundColor="#ffffff50" :dur="5" class="top_list_item">
          <div style="padding: 10px;">
            <div class="top_list_item-title">锁数量</div>
            <div class="top_list_item-money">{{formData.lockNum}}</div>
            <div class="top_list_item-rate greenColor">比上个月增加{{(Math.random()*2).toFixed(2)}}%</div>
            <i class="fa fa-angle-double-up top_list_item-up greenColor"></i>
          </div>
        </dv-border-box-10>
      </div>
      <div class="content__body">
        <dv-border-box-11 :color="['#409EFF', '#409EFF']" backgroundColor="#ffffff" class="content__body_left" title="用户增长趋势表">
          <chart-bar v-if="showE" :xData="monthList" :yData="userNumList"></chart-bar>
      </dv-border-box-11>
        <div class="content__body_right">
          <dv-border-box-3 :color="['#409EFF', '#409EFF']"  title="用户对比" class="content__body_right-item">
          <div style="display: flex;align-items: center;justify-content: space-around;">
            <!-- <div class="content__body_right-item_rTitle" style="flex: 1;">

            </div> -->
            <div class="content__body_right-item_comparison" style="flex: 1;">
              <div class="dataItem">
                  <div class="dataItem_num">{{formData.yuserNum}}</div>
                  <div class="dataItem_hint">昨天</div>
              </div>
              <div class="dataItemCenter greenColor">
                  <div class="dataItem_num">{{formData.tuserNum - formData.yuserNum}}</div>
                  <i class="fa-long-arrow-up fa dataItem_icon" ></i>
              </div>
              <div class="dataItem">
                  <div class="dataItem_num">{{formData.tuserNum}}</div>
                  <div class="dataItem_hint">今天</div>
              </div>
            </div>
          </div>
        </dv-border-box-3>
        <dv-border-box-3 :color="['#409EFF', '#409EFF']" title="门锁使用量" class="content__body_right-item">
          <div>
            <!-- <div class="content__body_right-item_rTitle">门锁使用量</div> -->
            <div class="content__body_right-item_comparison">
              <div class="dataItem">
                  <div class="dataItem_num">{{formData.yunlockLogNum}}</div>
                  <div class="dataItem_hint">昨天</div>
              </div>
              <div class="dataItemCenter greenColor">
                  <div class="dataItem_num">{{formData.growUnlockLogNum}}</div>
                  <i class="fa-long-arrow-up fa dataItem_icon" ></i>
              </div>
              <div class="dataItem">
                  <div class="dataItem_num">{{formData.tunlockLogNum}}</div>
                  <div class="dataItem_hint">今天</div>
              </div>
            </div>
          </div>
        </dv-border-box-3>
        </div>
      </div>
  </div>
</template>

<script>
import { homePageData, getUserNumGroupData } from '@/api/lock'
import chartBar from '@/components/echarts/chartBar.vue'
export default {
  components: {
    chartBar
  },
  data () {
    return {
      showE: false,
      monthList: [],
      userNumList: [],
      formData: {
        growUnlockLogNum: 0,
        growUserNum: 0,
        lockNum: 0,
        orgaNum: 0,
        tunlockLogNum: 0,
        tuserNum: 0,
        userNum: 0,
        usingLockNum: 0,
        yunlockLogNum: 0,
        yuserNum: 0
      },
      dialog: {
        model: false
      },
      appendDialog: {
        model: false
      },
      fullDialog: {
        model: false
      }
    }
  },
  mounted () {
   this.homePageDataFn()
   this.getUserNumGroupDataFn()
  },
  methods: {
    // 获取echar数据表数据
    getUserNumGroupDataFn () {
      getUserNumGroupData().then(res => {
        console.log('获取到的数据表', res.data)
        this.userNumList = res.data.userNumList
        this.monthList = res.data.monthList
        this.showE = true
      })
    },
    // 获取首页的 数据信息
    homePageDataFn () {
      homePageData().then(res => {
        console.log('我获取到的首页数据', res.data)
        Object.keys(this.formData).forEach((item) => {
          this.formData[item] = res.data[item]
        })
      })
    },
    closeDialog () {
      this.dialog.model = false
    },
    appendClose () {
      this.appendDialog.model = false
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  background: #fdfdfd;
  height: 100%;
  .greenColor{
    color: #77E550;
  }
  .redColor {
    color: #ef0000;
  }
  .top_list{
  padding: 20px 0px;
  &_item {
    display: inline-block;
    position: relative;
    margin: 5px 15px;
    border: 1px solid #0A1847;
    box-shadow: 0px 0px 8px 2px #4390FF;
    // border-radius: 12px;
    color: #202020;
    width: 365px;
    &-title{
      font-size: 24px;
      margin-bottom: 15px;
      margin: 15px 0px 15px 15px;
    }
    &-money{
      margin: 15px 0px 15px 15px;
      font-size: 40px;
      font-weight: 700;
      letter-spacing: 0.06em;
    }
    &-rate{
      color: #77E550;
      margin: 15px 0px 15px 15px;
    }
    &-up{
      position: absolute;
      bottom: 50px;
      right: 50px;
      font-size: 100px;
    }
    &-down{
      position: absolute;
      bottom: 50px;
      right: 50px;
      font-size: 100px;
    }
  }
}
.content__body {
  height: 600px;
  display: flex;
  justify-content: space-between;
  padding: 0px 15px;
  &_left{
    padding: 20px;
    width: 43%;
    // border: 1px solid #0A1847;
    // box-shadow: 0px 0px 8px 2px #4390FF;
    border-radius: 12px;
  }
  &_right{
    width: 53%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /deep/ .border-box-content {
      padding: 0px 28px;
      box-sizing: border-box;
    }
    &-item{
      color: #202020;
      padding:50px 0px;
      // border: 1px solid #0A1847;
      // box-shadow: 0px 0px 8px 2px #4390FF;
      border-radius: 12px;
      height: 45%;
      &_rTitle{
        font-size: 24px;
        letter-spacing: 0.06em;
      }
      &_comparison{
        display: flex;
        justify-content: space-around;
        padding: 20px 0px;
        .dataItem{
          display: flex;
          flex-direction: column;
          align-items: center;
          line-height: 90px;
          font-size: 48px;
          &_num {
          }
          &_hint{
            font-size: 48px;
          }
        }
        .dataItemCenter{
          font-weight: bold;
          display: flex;
          flex-direction: column;
          align-items: center;
          line-height: 90px;
          font-size: 48px;
          .dataItem_icon{
            font-weight: 900;
          }
        }
      }
    }
  }
}
}

</style>
