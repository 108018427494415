<template>
  <div class="page-container">
    <!-- header -->
    <el-card>
      <el-row>
        <el-col :span="6">
          <el-input
            placeholder="请输入关键字搜索"
            class="input-with-select"
            clearable
            v-model="queryInfo.keyword"
            @clear="getOrgaInfoPage"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getOrgaInfoPage"
            ></el-button>
          </el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" style="text-align: right">
          <el-button type="primary" @click="createUser"
            >添加组织</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <!-- body -->
    <el-card>
     <vxe-table
        resizable
        ref="xTable"
        align="center"
        :data="orgaInfoList"
        :loading="loading"
      >
        <vxe-column type="expand" width="50" title="序号">
          <template #content="{ row }" >
            <div class="row_render">
                <vxe-table
                    resizable
                    ref="xTable2"
                    align="center"
                    :data="row.managerList"
                  >
                   <vxe-column field="username" title="管理员账号"></vxe-column>
                    <vxe-column field="realName" title="管理员名称"></vxe-column>
                    <vxe-column field="createTime" title="创建时间"></vxe-column>
                    <vxe-column title="操作" >
                      <template #default="{ row }">
                        <el-button
                          type="warning"
                          size="mini"
                           @click="editManagerFn(row)"
                          >编辑</el-button
                        >
                        <!-- <el-button
                          type="danger"
                          size="mini"
                          >删除</el-button
                        > -->
                      </template>
                  </vxe-column>
                 </vxe-table>
              </div>
            </template>
        </vxe-column>
        <vxe-column field="orgaName" title="组织名称"></vxe-column>
        <vxe-column field="remarks" title="备注"></vxe-column>
        <vxe-column field="createTime" title="创建时间"></vxe-column>
         <vxe-column title="操作" >
            <template #default="{ row }">
               <el-button
                  type="success"
                  size="mini"
                  @click="addManagerFn(row.id)"
                  >添加管理员</el-button
                >
              <el-button
                type="warning"
                size="mini"
                @click="createUser(row.id)"
                >编辑</el-button
              >
              <el-button
                type="danger"
                size="mini"
                @click="deleteUserHandle(row.id)"
                >删除</el-button
              >
            </template>
         </vxe-column>
      </vxe-table>
    </el-card>
    <base-pagination
      :total="total"
      :currentPage="queryInfo.pageNum"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></base-pagination>
    <add-orga-info :addData="addData" @successHandle="successHandle"></add-orga-info>
    <edit-orga-info :editData="editData" @successHandle="successHandle"></edit-orga-info>
    <add-orga-manager-root :addManagerData="addManagerData" @successHandle="successHandle"></add-orga-manager-root>
    <edit-orga-manager-root :editManagerData="editManagerData" @successHandle="successHandle"></edit-orga-manager-root>
  </div>
</template>

<script>
import { getOrgaInfoPage, orgaInfoDel } from '@/api/orga'
import addOrgaInfo from './dialog/addOrgaInfo.vue'
import editOrgaInfo from './dialog/editOrgaInfo.vue'
import addOrgaManagerRoot from './dialog/addOrgaManagerRoot'
import editOrgaManagerRoot from './dialog/editOrgaManagerRoot'

export default {
  components: {
    addOrgaInfo,
    editOrgaInfo,
    addOrgaManagerRoot,
    editOrgaManagerRoot
  },
  data () {
    return {
      addManagerData: {
        isOpen: false,
        orgaId: ''
      },
      editManagerData: {
        isOpen: false,
        id: '',
        orgaId: ''
      },
      addData: {
        isOpen: false
      },
      editData: {
        isOpen: false,
        id: ''
      },
      queryInfo: {
        pageNum: 1,
        pageSize: 10,
        keyword: ''
      },
      orgaInfoList: [],
      total: 0,
      loading: false
    }
  },
  created () {
    this.getOrgaInfoPage()
  },
  methods: {
    // 获取用户列表
    getOrgaInfoPage () {
      this.loading = true
      getOrgaInfoPage(this.queryInfo).then((res) => {
        this.orgaInfoList = res.data.list
        this.total = res.data.total
        this.loading = false
      })
    },
    // 添加管理员
    addManagerFn (id) {
      this.addManagerData.isOpen = true
      this.addManagerData.orgaId = id
    },
    // 编辑管理员
    editManagerFn (row) {
      this.editManagerData.isOpen = true
      this.editManagerData.id = row.userId
      this.editManagerData.orgaId = row.orgaId
    },
    // 添加与编辑用户
    createUser (id) {
      if (id.length) {
        this.editData.isOpen = true
        this.editData.id = id
      } else {
        this.addData.isOpen = true
      }
    },
    // 改变分页条目
    handleSizeChange (size) {
      this.queryInfo.pageNum = 1
      this.queryInfo.pageSize = size
      this.getOrgaInfoPage()
    },
    // 改变页数
    handleCurrentChange (page) {
      this.queryInfo.pageNum = page
      this.getOrgaInfoPage()
    },
    // 删除组织
    deleteUserHandle (id) {
      this.$confirm('此操作将永久删除该组织, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then((res) => {
          orgaInfoDel(id).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            this.getOrgaInfoPage()
          })
        })
        .catch((res) => {
          console.log('res22---', res)
          this.$message({
            type: 'warning',
            message: '已取消删除'
          })
        })
    },
    // 监听成功添加与编辑
    successHandle () {
      this.getOrgaInfoPage()
    }
  }
}
</script>

<style lang="scss" scoped>
.row_render {
  padding: 20px 40px;
}
</style>
