import request from '@/service/request'

/* 分页查询广告 */
const adInfopage = (params) => {
  return request.post('/sys/adInfo/page', params)
}
// 设置广告为首页轮播
const setHomeAd = (params) => {
  return request.post('/sys/adInfo/setHome/' + params.id, params)
}

// 取消广告设置为首页轮播
const cancelHomeAd = (params) => {
  return request.post('/sys/adInfo/cancelHome/' + params.id, params)
}

// 保存广告
const adInfoSave = (params) => {
  return request.post('/sys/adInfo/save', params)
}

// 删除广告
const adInfoDel = (params) => {
  return request.post('/sys/adInfo/del/' + params.id, params)
}

// 根据id获取广告
const getByIdAd = (id) => {
  return request.post('/sys/adInfo/getById/' + id)
}

// 获取广告边框列表
const adBodyTemplatePage = (params) => {
  return request.post('/sys/template/page', params)
}

// 新增/编辑广告边框主题
const adBodyTemplateSave = (params) => {
  return request.post('/sys/template/save', params)
}
// 根据id获取广告模板
const templateGetById = (id) => {
  return request.post('/sys/template/getById/' + id)
}

// 根据id删除广告模板
const delTemplateById = (id) => {
  return request.post('/sys/template/del/' + id)
}

export {
  adInfopage,
  setHomeAd,
  cancelHomeAd,
  adInfoSave,
  adInfoDel,
  getByIdAd,
  adBodyTemplatePage,
  adBodyTemplateSave,
  templateGetById,
  delTemplateById
}
