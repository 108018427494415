<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogData.isOpen"
    width="30%"
    :before-close="handleClose"
    append-to-body
    :close-on-click-modal="false"
    @open="$emit('open')"
    @close="$emit('close')"
  >
    <el-upload
      class="avatar-uploader"
      :action="action"
      :show-file-list="false"
      :on-success="handleVideoSuccess"
      :before-upload="beforeVideoUpload"
      drag
      :headers="headers"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
    </el-upload>
  </el-dialog>
</template>

<script>
export default {
  props: {
    dialogData: {
      type: Object
    }
  },
  data () {
    return {
      headers: {
        Authorization: window.localStorage.getItem('token')
      },
      action: `${process.env.VUE_APP_API_URL}/sys/minio/upload/image`,
      title: '上传图片'
    }
  },
  methods: {
    /* 上传成功 */
    handleVideoSuccess (res, file) {
      this.title = '上传成功'
      this.$emit('success', res)
    },
    /* 上传控制 */
    beforeVideoUpload (file) {
      console.log(file)
      const isImage = file.type === 'image/png' || file.type === 'image/jpeg'
      const fileSize = file.size / 1024 / 1024 < 50
      if (!isImage) {
        this.$message.error('上传图片只能是只能是jpeg/png格式!')
      }
      if (!fileSize) {
        this.$message.error('上传图片不能超过 50MB!')
      }
      this.title = '上传中，请不要关闭窗口'
      return isImage && fileSize
    },
    /* 关闭弹窗 */
    handleClose () {
      this.$confirm('确认关闭？')
        .then(() => {
          this.title = '上传图片'
          this.$emit('close')
        })
        .catch((_) => {})
    }
  }
}
</script>

<style lang='scss' scoped>
/deep/ .el-dialog {
  .el-dialog__body {
    text-align: center;
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }

    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }

    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  }
}
</style>
