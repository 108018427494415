<template>
  <div class="lockpassWord-coantainer">
    <base-dialog
      :model="lockPassWordData.isOpen"
      title="门锁长期密码列表"
      width="60%"
      @close="handleClose"
    >
      <template #body>
        <vxe-table
        resizable
        ref="lockTable"
        align="center"
        :max-height="$wHight - 580"
        :data="formData"
        :loading="loading"
      >
      <vxe-column field="createTime" title="创建时间"  width="220"> </vxe-column>
      <vxe-column field="remarks" title="门锁备注" width="220"></vxe-column>
      <vxe-column field="pw" title="长期密码" width="220"></vxe-column>
      </vxe-table>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import { listLockZkPw } from '@/api/lock'

export default {
  props: {
    lockPassWordData: {
      type: Object
    }
  },
  data () {
    return {
      total: 0,
      queryInfo: {
        lockId: ''
      },
      loading: false,
      formData: []
    }
  },
  watch: {
    'lockPassWordData.isOpen': function (isOpen) {
      if (isOpen) {
         console.log('id:', this.lockPassWordData.lockId)
        this.queryInfo.lockId = this.lockPassWordData.lockId
        this.getLockPwdList()
      }
    }
  },
  methods: {
    // 根据id获取门锁开门记录
    getLockPwdList () {
      this.loading = true
      listLockZkPw({ lockId: this.queryInfo.lockId }).then(res => {
        this.loading = false
        this.formData = res.data
      })
    },
    /* 关闭弹窗 */
    handleClose () {
      this.lockPassWordData.isOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
