<template>
  <div class="page-container">
    <!-- header -->
    <el-card>
      <el-row>
        <el-col :span="6">
          <el-input
            placeholder="请输入关键字搜索"
            class="input-with-select"
            clearable
            v-model="queryInfo.keyword"
            @clear="orgaManagerPageList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="orgaManagerPageList"
            ></el-button>
          </el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" style="text-align: right">
          <el-button type="primary" @click="createUser"
            >添加管理员</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <!-- body -->
    <el-card>
     <vxe-table
        resizable
        ref="xTable"
        align="center"
        :data="orgaInfoList"
        :loading="loading"
      >
        <vxe-column type="seq" width="50" title="序号">
        </vxe-column>
        <vxe-column field="username" title="管理员账号"></vxe-column>
        <vxe-column field="realName" title="管理员名称"></vxe-column>
        <vxe-column field="phone" title="管理员手机号"></vxe-column>
        <vxe-column field="remarks" title="备注"></vxe-column>
        <vxe-column field="createTime" title="创建时间"></vxe-column>
         <vxe-column title="操作" >
            <template #default="{ row }">
              <el-button
                type="warning"
                size="mini"
                @click="createUser(row.userId)"
                >编辑</el-button>
              <el-button
                type="danger"
                size="mini"
                @click="deleteUserHandle(row.id)"
                >删除</el-button>
            </template>
         </vxe-column>
      </vxe-table>
    </el-card>
    <base-pagination
      :total="total"
      :currentPage="queryInfo.pageNum"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></base-pagination>
    <add-orga-manager :addManagerData="addManagerData" @successHandle="successHandle"></add-orga-Manager>
    <edit-orga-manager :editManagerData="editManagerData" @successHandle="successHandle"></edit-orga-manager>
  </div>
</template>

<script>
import { deleteUserById, orgaManagerPageList } from '@/api/orga'
import { mapState } from 'vuex'
import addOrgaManager from './dialog/addOrgaManager.vue'
import editOrgaManager from './dialog/editOrgaManager.vue'

export default {
  components: {
    addOrgaManager,
    editOrgaManager
  },
  computed: {
...mapState('login', ['userInfo'])
},
  data () {
    return {
      addManagerData: {
        isOpen: false,
        orgaId: ''
      },
      editManagerData: {
        isOpen: false,
        id: '',
        orgaId: ''
      },
      queryInfo: {
        pageNum: 1,
        pageSize: 10,
        keyword: ''
      },
      orgaInfoList: [],
      total: 0,
      loading: false
    }
  },
  created () {
    this.orgaManagerPageList()
  },
  methods: {
    // 获取用户列表
    orgaManagerPageList () {
      this.loading = true
     this.queryInfo.orgaId = this.userInfo.orgaId
      orgaManagerPageList(this.queryInfo).then((res) => {
        this.orgaInfoList = res.data.list
        this.total = res.data.total
        this.loading = false
      })
    },
    // 添加与编辑用户
    createUser (id) {
      if (id.length) {
        this.editManagerData.isOpen = true
        this.editManagerData.id = id
        this.editManagerData.orgaId = this.userInfo.orgaId
      } else {
        this.addManagerData.isOpen = true
        this.addManagerData.orgaId = this.userInfo.orgaId
      }
    },
    // 改变分页条目
    handleSizeChange (size) {
      this.queryInfo.pageNum = 1
      this.queryInfo.pageSize = size
      this.orgaManagerPageList()
    },
    // 改变页数
    handleCurrentChange (page) {
      this.queryInfo.pageNum = page
      this.orgaManagerPageList()
    },
    // 删除组织
    deleteUserHandle (id) {
      this.$confirm('此操作将永久删除该组织, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteUserById(id).then((res) => {
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            this.orgaManagerPageList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'warning',
            message: '已取消删除'
          })
        })
    },
    // 监听成功添加与编辑
    successHandle () {
      this.orgaManagerPageList()
    }
  }
}
</script>

<style lang="scss" scoped>
.row_render {
  padding: 20px 40px;
}
</style>
