<template>
  <div class="editOrgaBuild-coantainer">
    <base-dialog
      :model="editUserData.isOpen"
      title="绑定用户"
      @close="handleClose"
    >
      <template #body>
         <el-form ref="lockForm" :model="formData" label-width="100px">
          <el-form-item label="用户手机号" prop="phone">
            <el-input v-model="formData.phone" placeholder="输入绑定的用户手机"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="allocationOrganization">确 定</el-button>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import { bindLocksToOneUser } from '@/api/lock'

export default {
  props: {
    editUserData: {
      type: Object
    }
  },
  data () {
    return {
      formData: {
        lockIdList: [],
        phone: ''
      }
    }
  },
  watch: {
    'editUserData.isOpen': function (isOpen) {
      if (isOpen) {
         console.log('id:', this.editUserData.lockIdList)
        this.formData.phone = this.editUserData.phone
        this.formData.lockIdList = this.editUserData.lockIdList
      }
    }
  },
  methods: {
    /* 成功分配组织 */
    allocationOrganization () {
      const myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
  // 校验个人电话
      if (!myreg.test(this.formData.phone)) {
        return this.$message({
                message: '用户手机错误',
                type: 'error'
                })
      }
      this.$confirm('是否将锁分配给该用户 ?', '操作提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs.lockForm.validate((valid) => {
            console.log('valid', valid)
            if (valid) {
              console.log('data:', this.formData)
              bindLocksToOneUser(this.formData).then(() => {
                  this.$message({
                  message: '分配成功',
                  type: 'success'
                  })
                  this.$emit('successHandle')
                  this.handleClose()
                })
              }
          })
        })
        .catch(() => {
          this.$message({
            type: 'warning',
            message: '已取消'
          })
        })
    },
    /* 关闭弹窗 */
    handleClose () {
      this.editUserData.isOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
