<template>
  <div class="setRole-dialog">
    <base-dialog
      :model="setData.isOpen"
      @close="closeDialogHandle"
      title="分配角色"
    >
      <template #body>
        <el-form ref="form" label-width="120px">
          <el-form-item label="筛选角色类型">
            <el-select v-model="queryInfo.roleType" clearable placeholder="请选择" @change="filterRoleName">
              <el-option
                v-for="item in roleTypeList"
                :key="item.id"
                :label="item.label"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="角色">
            <el-select v-model="userRoleList" multiple :multiple-limit="1" placeholder="请选择角色">
              <span v-for="(item, index) in roleList" :key="index">
                <el-option
                  :label="item.roleName"
                  :value="item.id"
                  :key="index"
                ></el-option>
              </span>
            </el-select>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <el-button @click="setData.isOpen = false">取 消</el-button>
        <el-button type="primary" @click="setUserRole">确 定</el-button>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import { getRoleListById, setUserRole, getRoleList } from '@/api/system'
export default {
  props: {
    setData: {
      type: Object
    }
  },
  data () {
    return {
      roleTypeList: [
        {
          id: 1,
          label: '平台管理员'
        },
        {
          id: 2,
          label: '组织机构管理员'
        }
      ],
      formData: {
        userId: '',
        roleIdList: []
      },
      queryInfo: {
        roleType: '',
        pageNum: 1,
        pageSize: 1000,
        keyword: ''
      },
      roleList: [], // 全部角色列表
      userRoleList: [] // 用户角色列表
    }
  },
  watch: {
    'setData.isOpen': function (isOpen) {
      if (isOpen) {
        this.getRoleListFn()
      }
    }
  },
  methods: {
    // 筛选角色类型
    filterRoleName () {
      this.userRoleList = []
      console.log('选中了', this.userRoleList)
      getRoleList(this.queryInfo).then((res) => {
        this.roleList = res.data.list
      })
    },
    // 根据用户id获取角色列表
    getRoleListById () {
      getRoleListById(this.setData.id).then((res) => {
        this.userRoleList = res.data
      })
    },
    // 获取全部角色列表
    getRoleListFn () {
      getRoleList(this.queryInfo).then((res) => {
        this.roleList = res.data.list
        this.getRoleListById()
      })
    },
    // 分配角色
    setUserRole () {
      this.formData = {
        userId: this.setData.id,
        roleIdList: this.userRoleList
      }
      setUserRole(this.formData).then((res) => {
        this.$refs.form.resetFields()
        this.$message({
          message: '分配成功',
          type: 'success'
        })
        this.$emit('successHandle')
        this.closeDialogHandle()
      })
    },
    // 关闭弹窗
    closeDialogHandle () {
      this.setData.isOpen = false
    }
  }
}
</script>
